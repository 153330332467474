import React, { useEffect, useState } from "react";
import springapi from "../util/springapi";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Switch, FormControlLabel } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles"
import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

const customBlue = "#4444ff";
const theme = createTheme({
  palette: {
    primary: {
      main: customBlue 
    }
  }
});

const Prescription = (props) => {
  const [originalExerciseList, setOriginalExerciseList] = useState([]);
  const [exerciseList, setExerciseList] = useState([]);
  const [prescriptionSub, setPrescriptionSub] = useState([]);
  // const [prescription, setPrescription] = useState([{ weeks: 1, days: [0, 0, 0, 0, 0, 0, 0] }]);
  const [subDisabled, setSubDisabled] = useState(false);
  const [exerciseDrag, setExerciseDrag] = useState(false);
  const [filter, setFilter] = useState("");
  const [weeks, setWeeks] = useState(1);
  const [editing, setEditing] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");
  const [symptoms, setSymptoms] = useState("");

  // useEffect(() => {
  //   springapi.request("GET", "/dtx/get/exercise").then((res) => {
  //     if(res.response) {
  //       res.response.forEach((v) => {
  //         v["id"] = v["exerciseId"];
  //         v["showDescription"] = false;
  //         v["isChecked"] = false;
  //         const { maxCount, maxSet } = v.levels[parseInt(v.levels.length / 2)];
  //         v["extraSettings"] = {
  //           count: maxCount,
  //           set: maxSet,
  //           increaseCount: 0,
  //           increaseSet: 0
  //         };
  //         delete v["exerciseId"];
  //       });
  //       setOriginalExerciseList(res.response);
  //       setExerciseList(_.cloneDeep(res.response));
  //     }
  //   });
  // }, []);

  // function handleOnDragEnd(result) {
  //   if(result.destination === null) {
  //     setExerciseDrag(false);
  //     return;
  //   }
  //   if(result.source.droppableId === "exerciseList" && result.destination.droppableId === "prescriptionSub") {
  //     var id = 0;
  //     exerciseList.forEach((exercise, i) => {
  //       if(exercise.id === parseInt(result.draggableId)) {
  //         id = i;
  //         return;
  //       }
  //     });
  //     prescriptionSub.splice(result.destination.index, 0, exerciseList.splice(id, 1)[0]);
  //     if(prescriptionSub.length >= 3) {
  //       setSubDisabled(true);
  //     }
  //     else {
  //       setSubDisabled(false);
  //     }
  //   }
  //   else if(result.source.droppableId === "prescriptionSub" && result.destination.droppableId === "prescriptionSub") {
  //     prescriptionSub.splice(result.destination.index, 0, prescriptionSub.splice(result.source.index, 1)[0]);
  //     if(prescriptionSub.length >= 3) {
  //       setSubDisabled(true);
  //     }
  //     else {
  //       setSubDisabled(false);
  //     }
  //   }
  //   setExerciseDrag(false);
  // }

  // function handleOnDragStart(result) {
  //   if(result.source.droppableId === "prescriptionSub") {
  //     setSubDisabled(false);
  //   }
  //   else if(result.source.droppableId === "exerciseList") {
  //     setExerciseDrag(true);
  //   }
  // }

  // function removeExercise(exercise, index) {
  //   var count;
  //   exercise.showDescription = false;
  //   exercise.isChecked = false;
  //   const { maxCount, maxSet } = exercise.levels[parseInt(exercise.levels.length / 2)];
  //   exercise.extraSettings = {
  //     count: maxCount,
  //     set: maxSet,
  //     increaseCount: 0,
  //     increaseSet: 0
  //   };
  //   for(count = 0; count < exerciseList.length; count++) {
  //     if(exerciseList[count].id > exercise.id) {
  //       break;
  //     }
  //   }
  //   exerciseList.splice(count, 0, prescriptionSub.splice(index, 1)[0]);
  //   setPrescriptionSub(prescriptionSub.slice());
  //   setSubDisabled(false);
  // }

  // function prescriptExercises() {
  //   if(editing === null) {
  //     prescription.push({
  //       days: weeks,
  //       exercise: prescriptionSub
  //     });
  //   }
  //   else {
  //     prescription[editing] = {
  //       days: weeks,
  //       exercise: prescriptionSub
  //     }
  //     setEditing(null);
  //   }
  //   setWeeks(1);
  //   setPrescriptionSub([]);
  //   setSubDisabled(false);
  //   setExerciseList(_.cloneDeep(originalExerciseList));
  //   setPrescription(prescription.slice());
  // }

  // function editPrescriptionSub(sub, i) {
  //   setEditing(i);
  //   setWeeks(sub.days);
  //   setPrescriptionSub(_.cloneDeep(sub.exercise));
  //   sub.exercise.forEach((v) => {
  //     exerciseList.forEach((exercise, index) => {
  //       if(v.id === exercise.id) {
  //         exerciseList.splice(index, 1);
  //         return;
  //       }
  //     })
  //   });
  // }

  // function cancelEditing() {
  //   setWeeks(1);
  //   setPrescriptionSub([]);
  //   setSubDisabled(false);
  //   setExerciseList(_.cloneDeep(originalExerciseList));
  //   setEditing(null);
  // }

  // function removePrescriptionSub(i) {
  //   prescription.splice(i, 1);
  //   setPrescription(prescription.slice());
  // }

  async function addPrescription() {
    setIsAdding(true);
    // const sub = [];
    // prescription.forEach((s) => {
    //   sub.push({
    //     weeks: s.weeks,
    //     days: [0, 0, 1, 0, 0, 0, 0]
    //   });
    // });
    const body = {
      patientId: props.patientId,
      name,
      purpose,
      symptoms
    };
    const res = await springapi.request("POST", "/dtx/add/prescription", body, { Authorization: `Bearer ${localStorage.getItem("dtxSessionId")}` });
    if(res && res.response) {
      alert(`처방되었습니다\n처방코드: ${res.response}`);
      // setPrescription([{ weeks: 1, days: [0, 0, 0, 0, 0, 0, 0] }]);
      setName("");
      setPurpose("");
      setSymptoms("");
    }
    else {
      alert("처방 중에 오류가 발생했습니다");
    }
    setIsAdding(false);
    props.setUpdate(true);
  }

  // const Exercise = ({exercise, i}) => {
  //   const [showDescription, setShowDescription] = useState(exercise.showDescription);
  //   function handleOnClick() {
  //     exercise.showDescription = !showDescription;
  //     setShowDescription(exercise.showDescription);
  //   }
  //   return (
  //     <Draggable draggableId={`${exercise.id}`} index={i}>
  //       {(provided) => {
  //         return (
  //           <li className="listItem" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
  //             {!showDescription &&
  //               <div style={{ display: "flex" }}>
  //                 <div style={{ width: "100%" }}>
  //                   <div style={{ marginBottom: 4 }}>{exercise.nameKor}</div>
  //                   <div style={{ display: "flex" }}>
  //                     <div style={{ width: "40%" }}>{`종류: ${exercise.category}`}</div>
  //                     <div style={{ width: "60%" }}>{`운동 부위: ${exercise.part}`}</div>
  //                   </div>
  //                 </div>
  //                 <HelpIcon style={{ float: "right", margin: "auto", color: customBlue, cursor: "pointer" }} onClick={handleOnClick}/>
  //               </div>
  //             }
  //             {showDescription &&
  //               <div style={{ display: "flex" }}>
  //                 <div style={{ width: "100%", textAlign: "justify", paddingRight: 10 }}>{exercise.description}</div>
  //                 <CloseIcon style={{ float: "right", margin: "auto", cursor: "pointer" }} onClick={handleOnClick}/>
  //               </div>
  //             }
  //           </li>
  //         )
  //       }}
  //     </Draggable>
  //   )
  // }

  // const PrescriptedExercise = ({exercise, i}) => {
  //   const [isChecked, setIsChecked] = useState(exercise.isChecked);
  //   const [extraSettings, setExtraSettings] = useState(exercise.extraSettings);

  //   function check() {
  //     exercise.isChecked = !isChecked;
  //     setIsChecked(exercise.isChecked);
  //   }

  //   function slide(event, key) {
  //     exercise.extraSettings[key] = parseInt(event.target.value);
  //     setExtraSettings(Object.assign({}, exercise.extraSettings));
  //   }

  //   const settingsCategory = {
  //     count: {text: "횟수", unit: "회", min: 1, max: 20},
  //     set: {text: "세트 수", unit: "세트", min: 1, max: 10},
  //     // duration: {text: "수행 시간", unit: "초", min: 1, max: 30},
  //     increaseCount: {text: "횟수 증가폭", unit: "회", min: 0, max: 10},
  //     increaseSet: {text: "세트 수 증가폭", unit: "세트", min: 0, max: 5},
  //     // increaseDuration: {text: "수행 시간 증가폭", unit: "초", min: 0, max: 15}
  //   };

  //   function SettingsComponent(key) {
      
  //     return (
  //       <>
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           <div style={{ flexGrow: 1 }}>{settingsCategory[key].text}:</div>
  //           <div>({settingsCategory[key].min}~{settingsCategory[key].max})</div>
  //           <input type="number" min={settingsCategory[key].min} max={settingsCategory[key].max} step={1} value={extraSettings[key]} onChange={(event) => slide(event, key)}
  //             disabled={!exercise.isEnduring && (key === "duration" || key === "increaseDuration")} style={{ width: 40, marginLeft: 10, marginRight: 5 }} onBlur={() => {
  //               if(exercise.extraSettings[key] > settingsCategory[key].max) {
  //                 exercise.extraSettings[key] = settingsCategory[key].max;
  //               }
  //               else if(exercise.extraSettings[key] < settingsCategory[key].min) {
  //                 exercise.extraSettings[key] = settingsCategory[key].min;
  //               }
  //               setExtraSettings(Object.assign({}, exercise.extraSettings));
  //             }}/>
  //           <div>{settingsCategory[key].unit}</div>
  //         </div>
  //         {/* <input type="range" min={settingsCategory[key].min} max={settingsCategory[key].max} step={1} style={{ paddingTop: 10, paddingBottom: 10 }}
  //           value={extraSettings[key]} onChange={(event) => slide(event, key)} disabled={!exercise.isEnduring && (key === "duration" || key === "increaseDuration")}/> */}
  //       </>
  //     )
  //   }

  //   return (
  //     <Draggable draggableId={`${exercise.id}`} index={i}>
  //       {(provided) => {
  //         return(
  //           <li className="listItem" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
  //             <div style={{ display: "flex" }}>
  //               <div style={{ width: "100%" }}>{exercise.nameKor}</div>
  //               <FormControlLabel control={<Switch size="small" color="primary" checked={isChecked} onChange={check}/>} label={<div style={{ marginTop: 3 }}>상세 설정</div>} style={{ width: 150, marginBottom: -3, marginTop: -3 }}/>
  //               <CloseIcon style={{ float: "right", cursor: "pointer" }} onClick={() => removeExercise(exercise, i)}/>
  //             </div>
  //             {isChecked && <div style={{ display: "table", width: "100%", tableLayout: "fixed", marginTop: 10, borderSpacing: 5 }}>
  //               <div style={{ display: "table-row" }}>
  //                 <div style={{ display: "table-cell", paddingRight: 6 }}>
  //                   {SettingsComponent("count")}
  //                 </div>
  //                 <div style={{ display: "table-cell", paddingLeft: 6 }}>
  //                   {SettingsComponent("set")}
  //                 </div>
  //                 {/* <div style={{ display: "table-cell", paddingLeft: 8 }}>
  //                   {SettingsSlider("duration")}
  //                 </div> */}
  //               </div>
  //               <div style={{ display: "table-row", paddingTop: 10 }}>
  //                 <div style={{ display: "table-cell", paddingRight: 6 }}>
  //                   {SettingsComponent("increaseCount")}
  //                 </div>
  //                 <div style={{ display: "table-cell", paddingLeft: 6 }}>
  //                   {SettingsComponent("increaseSet")}
  //                 </div>
  //                 {/* <div style={{ display: "table-cell", paddingLeft: 8 }}>
  //                   {SettingsSlider("increaseDuration")}
  //                 </div> */}
  //               </div>
  //             </div>}
  //           </li>
  //         )
  //       }}
  //     </Draggable>
  //   )
  // }

  // const PrescriptionInput = () => {
  //   return (
  //   );
  // }

  const weekList = ["일", "월", "화", "수", "목", "금", "토"];

  function LimbSelectComponent({ limbId, setLimbId }) {
    return (
      <div style={{ display: "flex", flexDirection: "column", borderRadius: 5, border: "1px solid black", width: 48, height: 60, userSelect: "none", overflow: "hidden" }}>
        <div className={limbId === 0 ? "item-selected" : "item-unselected"} style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: "1 0 0", borderBottom: "1px solid black" }}
          onClick={() => {
            if(limbId !== 0) {
              setLimbId(0);
            }
          }}
        >
          상지
        </div>
        <div className={limbId === 1 ? "item-selected" : "item-unselected"} style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: "1 0 0" }}
          onClick={() => {
            if(limbId !== 1) {
              setLimbId(1);
            }
          }}
        >
          하지
        </div>
      </div>
    );
  }

  const SubList = () => {
    const [date, setDate] = useState(0);
    const [fullDate, setFullDate] = useState(new Date());

    useEffect(() => {
      const clock = setInterval(() => {
        setDate(new Date().getDate());
      }, 500);
      return () => clearInterval(clock);
    }, []);

    useEffect(() => {
      setFullDate(new Date());
    }, [date]);

    function addDays(days) {
      const result = new Date(fullDate);
      result.setDate(result.getDate() + days);
      return result.toLocaleDateString();
    }

    return (
      <ul className="prescription">
        <div style={{ padding: 10 }}>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1, marginRight: 5 }}>
              <div style={{ width: 70 }}>처방 이름</div>
              <input style={{ width: "calc(100% - 70px)" }} value={name} onChange={(e) => setName(e.target.value)}/></div>
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1, marginLeft: 5 }}>
            <div style={{ width: 70 }}>치료 목적</div>
              <input style={{ width: "calc(100% - 70px)" }} value={purpose} onChange={(e) => setPurpose(e.target.value)}/></div>
          </div>
          <div style={{ display: "flex", alignItems: "center", flexGrow: 1, marginTop: 5 }}>
            <div style={{ width: 70 }}>진단 증상</div>
            <input style={{ width: "calc(100% - 70px)" }} value={symptoms} onChange={(e) => setSymptoms(e.target.value)}/></div>
        </div>
        {/* {prescription.reduce((newArray, sub, i) => {
          return [...newArray, i === 0 ? {...sub, offset: 0 } : {...sub, offset: newArray.at(-1).offset + newArray.at(-1).weeks * 7}];
        }, []).map((s, i) => {
          return (
            <li className="listItem" key={`sub${i}`}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: 180, gap: 10 }}>
                    <div style={{ display: "flex" }}>
                      {addDays(s.offset)}<span style={{ marginLeft: 10, marginRight: 10 }}>~</span>{addDays(s.offset + s.weeks * 7 - 1)}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      처방 기간: 
                      <input value={s.weeks} style={{ width: 28, marginLeft: 10, marginRight: 5, paddingRight: 5, textAlign: "right" }} maxLength={2}
                        onChange={(event) => {
                          let weeks = Number(event.target.value.replace(/\D/g, ""));
                          setPrescription((p) => {
                            p[i].weeks = weeks;
                            return p.slice();
                          });
                        }}
                      />
                      주
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0", justifyContent: "center", gap: 20 }}>
                    {s.days.map((v, j) => {
                      return (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                          <b>{weekList[j]}</b>
                          <LimbSelectComponent key={j} limbId={v} setLimbId={(newValue) => {
                            setPrescription((p) => {
                              p[i].days[j] = newValue;
                              return p.slice();
                            })
                          }}/>
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 40 }}>
                    <CloseIcon style={{ margin: "auto", width: 24, height: 24, float: "right", cursor: "pointer", color: "red", display: prescription.length === 1 ? "none": undefined }} onClick={() => removePrescriptionSub(i)}/>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
        <li className="listItem" style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
          onClick={() => {
            setPrescription((p) => {
              p.push({ weeks: 1, days: [0, 0, 0, 0, 0, 0, 0] });
              return p.slice();
            })
          }}
        >
          <h2>+</h2>
        </li> */}
        {/* <li>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                
                <Input value={weeks} style={{ width: 38, textAlign: "right" }} maxLength={2} onChange={(event) => {
                  const days = Number(event.target.value.replace(/\D/g, ""));
                  setWeeks(days ? days : "");
                }}/>
                <div style={{ display: "flex", marginRight: 10, height: "100%", alignItems: "center" }}>주</div>
              </div>
            </div>
          </div>
        </li> */}
        {/* {prescription.reduce((newArray, sub, i) => {
          return [...newArray, i === 0 ? {...sub, offset: 0 } : {...sub, offset: newArray.at(-1).offset + newArray.at(-1).days}];
        }, [])
          .map((sub, i) => (
            <li className="listItem" key={`sub${i}`}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", marginBottom: 4 }}>
                    <div style={{ marginRight: 12 }}><b>{i + 1}차</b></div>
                    {addDays(sub.offset)}<span style={{ marginLeft: 10, marginRight: 10 }}>~</span>{addDays(sub.offset + sub.days - 1)}
                  </div>
                  {sub.exercise.map((exercise, i) => <div key={i} style={{ display: "flex" }}>{exercise.nameKor}</div>)}
                </div>
                {editing === null &&
                  <>
                    <EditIcon style={{ margin: "auto", marginRight: 5, float: "right", cursor: "pointer" }} onClick={() => editPrescriptionSub(sub, i)}/>
                    <DeleteIcon style={{ margin: "auto", float: "right", cursor: "pointer", color: "red" }} onClick={() => removePrescriptionSub(i)}/>
                  </>
                }
                {i === editing && 
                  <>
                    <CloseIcon style={{ margin: "auto", float: "right", cursor: "pointer" }} onClick={cancelEditing}/>
                  </>
                }
              </div>
            </li>
          ))
        } */}
      </ul>
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ padding: "10px 15px 15px 15px", borderRadius: 5, backgroundColor: "white", border: "1px solid #CED4DA" }}>
          {/* <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}> */}
            {/* <div style={{ display: "flex", flexFlow: "column", height: "33%" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", textAlign: "center", margin: "auto 0" }}><b>운동 목록</b></div>
                <div style={{ flexGrow: 1 }}>
                  <InputGroup style={{ width: 200, float: "right" }}>
                    <Input
                      value={filter}
                      placeholder="운동 검색"
                      onChange={(event) => setFilter(event.target.value)}
                      style={{ boxShadow: "none" }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        style={{ border: "1px solid", borderColor: customBlue, backgroundColor: customBlue, height: "auto", padding: 0 }}
                        onClick={() => setFilter("")}
                        disabled={filter === ""}
                      >
                        <CloseIcon style={{ marginTop: -4, width: 32 }}/>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <Droppable droppableId="exerciseList" isDropDisabled={true}>
                {(provided) => {
                  // provided.placeholder.props = { ...provided.placeholder.props, offsetHeight: 2 };
                  return (
                    <ul className="exerciseList" {...provided.droppableProps} ref={provided.innerRef}>
                      {exerciseList.filter((exercise) => 
                        exercise.nameKor.replace(/ /g, "").toLowerCase().includes(filter.replace(/ /g, "").toLowerCase()) ||
                        exercise.category.replace(/ /g, "").includes(filter.replace(/ /g, "")) ||
                        exercise.part.replace(/ /g, "").includes(filter.replace(/ /g, ""))
                      )
                        .map((exercise, i) => <Exercise key={exercise.id} exercise={exercise} i={i}/>)
                      }
                      {provided.placeholder}
                  </ul>
                  )
                }}
              </Droppable>
            </div> */}
            {/* <div style={{ display: "flex", flexFlow: "column", height: "50%" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ display: "flex", textAlign: "center", margin: "auto 0" }}><b>처방할 운동</b></div>
                <div style={{ flexGrow: 100 }}/>
                  <Input value={weeks} style={{ width: 38, textAlign: "right" }} maxLength={2} onChange={(event) => {
                    const days = Number(event.target.value.replace(/\D/g, ""));
                    setWeeks(days ? days : "");
                  }}/>
                  <div style={{ display: "flex", marginRight: 10, height: "100%", alignItems: "center" }}>주</div>
                  <Button style={{ borderColor: customBlue, backgroundColor: customBlue }} disabled={prescriptionSub.length === 0 || weeks === ""} onClick={prescriptExercises}>운동 처방</Button>
                
              </div>
              <Droppable droppableId="prescriptionSub" isDropDisabled={subDisabled}>
                {(provided) => (
                  <ul className="prescriptionSub" {...provided.droppableProps} ref={provided.innerRef}
                    style={{ overflowY: prescriptionSub.length !== 0 ? "auto" : "hidden", backgroundColor: exerciseDrag && !subDisabled ? "skyblue" : "white" }}>
                    {prescriptionSub.length === 0 && <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
                      <span><b>운동 목록</b>에서 운동을 3개까지 끌어오세요</span>
                    </div>}
                    {prescriptionSub.length !== 0 && prescriptionSub.map((exercise, i) => <PrescriptedExercise key={exercise.id} exercise={exercise} i={i}/>)}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div> */}
            <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ display: "flex", textAlign: "center", margin: "auto 0" }}><b>처방</b></div>
                <div style={{ flexGrow: 100 }}>
                  <Button style={{ borderColor: customBlue, backgroundColor: customBlue, float: "right" }}
                    disabled={name === "" || purpose === "" || symptoms === "" || editing !== null || isAdding}
                    onClick={async() => await addPrescription()}>처방</Button>
                </div>
              </div>
              {SubList()}
            </div>
          {/* </DragDropContext> */}
      </div>
    </MuiThemeProvider>
  );
}

export default Prescription;