import React, { useEffect, useRef, useState } from "react"
import springapi from "../util/springapi";
import User from "../v2/User";
import axios from "axios";
import RightIcon from "@material-ui/icons/ArrowRight";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import DashIcon from "@material-ui/icons/Remove";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Analysis.css";
import _ from "lodash";
import GraphComponent from "./GraphComponent";
import { CSVLink } from "react-csv";
import { ContextMenuContext } from "./ContextMenuContext";
import ModuleData from "./ModuleData";
import { ProcessingList } from "./util/ScriptList";
import ParameterComponent from "./util/ParameterComponent";
import Decimal from "decimal.js";
/* eslint-disable-next-line */
import ProcessWorker from "worker-loader!./util/ProcessWorker.js";

const THRESHOLD = 123;
// 단위변환(3.3V * 1000mV/V * 1000μV/mV) / 증폭률(1000배) / V로 표현(1000000μV/V)
const CONV_ADC2V = 3300 / 1000000;

export default function AnalysisPage() {
  const [groupId, setGroupId] = useState(0);
  const [token, setToken] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [render, setRender] = useState([]);
  const [moduleDataContext, setModuleDataContext] = useState([]);

  const [dataList, setDataList] = useState([[], [], [], []]);
  const [selection, setSelection] = useState([]);
  const [upperGraph, setUpperGraph] = useState(null);
  const [lowerGraph, setLowerGraph] = useState(null);
  const [graphDataMap, setGraphDataMap] = useState({
    upper: [],
    lower: []
  });
  const [contextMenuPosition, setContextMenuPosition] = useState({ left: 0, top: 0 });
  const [contextMenuContent, setContextMenuContent] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [csvFiles, setCsvFiles] = useState([]);
  const [isAnalyzing, setIsAnalyzing] = useState([]);

  useEffect(() => {
    async function pageLoad() {
      const groupId = await User.getId();
      if(groupId === null) {
        window.location = "/";
        return;
      }
      const token = localStorage.getItem("sessionId");
      setGroupId(groupId);
      setToken(token);
      const exerciseId = parseInt(localStorage.getItem("exerciseId"));
      if(exerciseId) {
        localStorage.removeItem("exerciseId");
        const res = await springapi.request("POST", "/v2/log/get/groupUserEmg", { groupId, token, exerciseId });
        if(res.response) {
          for(let i = 0; i < res.response.length; i++) {
            const file = res.response[i];
            const emgString = (await axios.get(file.url)).data;
            const emg = emgString.split(/,|\r?\n|\r/).map((v) => ((new Decimal(v)).minus(THRESHOLD)).div(255).times(CONV_ADC2V).toNumber());
            setDataList((list) => {
              list[file.moduleNum - 1].push({ id: _.uniqueId(), moduleNumber: file.moduleNum, name: "EMG_" + file.dateTime + "_" + file.moduleNum, x: _.range(0, emg.length).map((v) => v / 1000), y: emg, xUnit: "s", yUnit: "V", samplingRate: 1000, expand: true, parent: null, children: [], depth: 0 });
              return [...list];
            });
          }
        }
      }
      setIsLoadingPage(false);
    }
    pageLoad();
  }, []);

  useEffect(() => {
    if(csvFiles.length > 0) {
      setCsvFiles([]);
    }
  }, [csvFiles]);

  useEffect(() => {
    if(selection.length > 0) {
      setContextMenuContent(
        <>
          <div className="context-menu-item" onClick={downloadCsv}>Download CSV</div>
          <div className="context-menu-item" onClick={deleteData}>Delete Data</div>
        </>
      );
    }
  }, [moduleDataContext]);

  useEffect(() => {
    if(upperGraph) {
      document.getElementById(upperGraph.id).scrollIntoView();
    }
  }, [upperGraph]);

  function rerender() {
    setRender([]);
  }

  function openData(data) {
    let find = graphDataMap.upper.find((v) => v.id === data.id);
    if(find) {
      setUpperGraph(find);
    }
    else {
      find = graphDataMap.lower.find((v) => v.id === data.id);
      if(find) {
        setLowerGraph(find);
      }
      else {
        graphDataMap.upper.push(data);
        setUpperGraph(data);
      }
    }
  }

  // console.log(graphDataMap);

  function handleDragStart(result) {
    // console.log(result);
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  function reorderMap({ map, source, destination }) {
    const current = [...map[source.droppableId]];
    const next = [...map[destination.droppableId]];
    const target = current[source.index];
  
    // moving to same list
    if(source.droppableId === destination.droppableId) {
      const reordered = reorder(current, source.index, destination.index);
      const result = {
        ...map,
        [source.droppableId]: reordered
      };
      console.log(map.lower === result.lower);
      console.log(result);
      return {
        map: result
      };
    }
  
    // moving to different list
  
    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);
  
    const result = {
      ...map,
      [source.droppableId]: current,
      [destination.droppableId]: next
    };

    const [setSourceData, setDestinationData] = source.droppableId === "upper" ? [setUpperGraph, setLowerGraph] : [setLowerGraph, setUpperGraph];

    if(current.length > source.index) {
      setSourceData(current[source.index]);
    }
    else if(current.length === 0) {
      setSourceData(null);
    }
    else {
      setSourceData(current[current.length - 1]);
    }
    setDestinationData(target);
  
    return {
      map: result
    };
  };

  function handleDragEnd(result) {
    console.log(result);
    if(result.destination) {
      const source = result.source;
      const destination = result.destination;

      if(source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }
      const data = reorderMap({ map: graphDataMap, source, destination });
      console.log(data.map.lower === graphDataMap.lower);
      setGraphDataMap(data.map);
    }
  }

  function CSVDownload(props) {
    const btnRef = useRef(null);
    useEffect(() => {
      if(btnRef.current) {
        btnRef.current.click();
      }
    }, [btnRef]);
    return (
      <CSVLink {...props} enclosingCharacter="" target="_blank"><span ref={btnRef}/></CSVLink>
    );
  }

  function downloadCsv() {
    const files = [];
    for(let i = 0; i < selection.length; i++) {
      const file = selection[i];
      const data = [];
      for(let j = 0; j < file.x.length; j++) {
        data.push([file.x[j], file.y[j]]);
      }
      files.push(<CSVDownload key={i} filename={file.name} headers={[file.xUnit, file.yUnit]} data={data}/>);
    }
    setCsvFiles([...files]);
  }

  function deleteChildren(data) {
    let child;
    for(let i = 0; i < data.children.length; i++) {
      child = data.children[i];
      deleteChildren(child);
      if(upperGraph === child) {
        setUpperGraph(null);
      }
      else if(lowerGraph === child) {
        setLowerGraph(null);
      }
      Object.values(graphDataMap).forEach((dataList) => {
        if(dataList.includes(child)) {
          dataList.splice(dataList.indexOf(child), 1);
        }
      });
    }
  }

  function deleteData() {
    let data, parent;
    for(let i = 0; i < selection.length; i++) {
      data = selection[i];
      parent = data.parent;
      if(parent) {
        parent.children.splice(parent.children.findIndex((v) => v.id === data.id), 1);
      }
      else {
        const list = dataList[data.moduleNumber - 1];
        list.splice(list.findIndex((v) => v.id === data.id), 1);
      }
      deleteChildren(data);
      if(upperGraph === data) {
        setUpperGraph(null);
      }
      else if(lowerGraph === data) {
        setLowerGraph(null);
      }
      Object.values(graphDataMap).forEach((dataList) => {
        if(dataList.includes(data)) {
          dataList.splice(dataList.indexOf(data), 1);
        }
      });
    }
    rerender();
  }

  function ContextMenu() {
    return (
      <div className="unselectable" style={{ position: "absolute", ...contextMenuPosition, width: 200, backgroundColor: "#eeeeee", boxShadow: "2px 2px 2px lightgray", padding: 1, zIndex: 100 }}
        // onClick={(e) => e.stopPropagation()}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {contextMenuContent}
      </div>
    );
  }

  function showModuleDataContext() {
    setModuleDataContext([]);
  }

  function Modal() {
    return (
      <div className="analysis-modal-container">
        <div className="analysis-modal-main">
          {modalContent}
        </div>
        <div className="analysis-modal-background" onClick={() => {
          if(isAnalyzing.length === 0) {
            setModalContent(null);
          }
        }}/>
      </div>
    );
  }
  
  function ProcessingComponent({ data }) {
    const [processingIndex, setProcessingIndex] = useState(null);
    const processing = processingIndex === null ? null : ProcessingList[processingIndex];
    const [parameters, setParameters] = useState([]);
    const [worker, setWorker] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);

    // useEffect(() => {
    //   if(processing !== null) {
    //     setParameters(processing.parameters.map((parameter) => parameter.initial));
    //   }
    // }, [processingIndex]);

    useEffect(() => {
      if(worker) {
        worker.onmessage = (message) => {
          const { type, value } = message.data;
          switch(type) {
            case "progress":
              setProgress(value);
              break;
            case "result":
              value.forEach((v) => {
                v.id = _.uniqueId();
                v.moduleNumber = data.moduleNumber;
                v.expand = true;
                v.parent = data;
                v.children = [];
                v.depth = data.depth + 1;
                data.children.push(v);
              });
              isAnalyzing.length = 0;
              setModalContent(null);
              break;
            case "error":
              console.log(value);
              break;
            default:
              break;
          }
        };
        worker.postMessage({ processFunction: processing.processFunction, data, parameters });
      }
    }, [worker]);

    function addData() {
      setIsProcessing(true);
      isAnalyzing.push(1);
      setWorker(new ProcessWorker());
      // const child = process(data, parameters);
      // data.children.push(child);
      // child.parent = data;
      // setModalContent(null);
    }

    if(isProcessing) {
      return (
        <div style={{ width: "fit-content", height: "fit-content", padding: 20 }}>
          <div style={{ display: "flex", alignItems:"center", width: 300 }}>
            <div style={{ width: 70 }}><b>Progress</b></div>
            {/* {progress} */}
            <div style={{ width: 230, height: 5, backgroundColor: "lightgreen" }}>
              <div style={{ width: progress * 100 + "%", height: "100%", backgroundColor: "limegreen" }}></div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
            {progress === 1 ?
              <button style={{ width: 70 }} onClick={() => {
                setModalContent(null);
              }}>Confirm</button>
            :
              <button style={{ width: 70 }} onClick={() => {
                worker.terminate();
                setProgress(0);
                isAnalyzing.length = 0;
                setIsProcessing(false);
              }}>Cancel</button>
            }
          </div>
        </div>
      );
    }

    // function Parameters({ processing }) {
    //   return (
    //     <div>
          
    //     </div>
    //   )
    // }

    return (
      <div style={{ display: "flex", width: "fit-content", height: "fit-content" }}>
        <div style={{ display: "flex", flexDirection: "column", width: 250, height: 400, borderRight: "1px solid black", padding: 20 }}>
          <div><b>Processing List</b></div>
          <div style={{ border: "1px solid black", flex: "1 0 0", marginTop: 5, overflowY: "auto"  }}>
            {/* {_.range(10).map((v, i) => <div className="processing-select" key={i} style={{ height: 30 }}></div>)} */}
            {ProcessingList.map((v, i) => {
              return (
                <div className="processing-select" key={i} style={{ backgroundColor: processingIndex === i ? "#77ccff" : undefined }}
                  onClick={(e) => {
                    if(processingIndex !== i) {
                      setProcessingIndex(i);
                      setParameters(ProcessingList[i].parameters.map((parameter) => parameter.initial));
                    }
                  }}>
                  {v.name}
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: 400, height: 400, padding: 20 }}>
          {processing !== null && 
            <>
              <h4><b>{processing.name}</b></h4>
              <div style={{ marginTop: 20, flex: "1 0 0" }}>
                {processing.parameters.map((parameter, i) => {
                  return (
                    <ParameterComponent key={i} parameter={parameter} dataList={dataList} value={parameters[i]}
                      setValue={(value) => setParameters((parameters) => {
                        parameters.splice(i, 1, value);
                        return [...parameters];
                      })}
                      visible={(parameter.visible === undefined) || (typeof parameter.visible[1] === "object" ? parameter.visible[1].includes(parameters[parameter.visible[0]]) : (parameters[parameter.visible[0]] === parameter.visible[1]))}
                    />
                  );
                })}
              </div>
              <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                <button style={{ width: 70 }} disabled={processing.checkDisabled && processing.checkDisabled(data, parameters)} onClick={addData}>Process</button>
                {/* <button style={{ marginLeft: 5, width: 70 }} onClick={() => setModalContent(null)}>Cancel</button> */}
              </div>
            </>
          }
        </div>
      </div>
    );
  }

  function openProcessingComponent(data) {
    setModalContent(<ProcessingComponent data={data} processing={ProcessingList[0]}/>);
  }

  // function ModuleData({ moduleDataList, i }) {
  //   const inputFile = useRef(null);

  //   return (
  //     <div key={i} className="unselectable" style={{ height: `${100 / dataList.length}%`, paddingTop: 5 }}>
  //       <div style={{ display: "flex" }}>
  //         <b>Module {i + 1}</b>
  //         <div style={{ marginLeft: 10 }} onClick={(e) => inputFile.current.click()}>+</div>
  //         <input type="file" accept=".csv" ref={inputFile} style={{ display: "none" }} onChange={(e) => console.log(e)}/>
  //       </div>
  //       <div style={{ width: "100%", height: "calc(100% - 21px)", padding: 5, border: "1px solid", overflow: "auto" }}>
  //         {moduleDataList.map((data, j) => {
  //           return (
  //             <TreeComponent key={j} data={data}/>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
  // }

  if(isLoadingPage) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <ContextMenuContext.Provider value={{ position: contextMenuPosition, setPosition: setContextMenuPosition, content: contextMenuContent, setContent: setContextMenuContent }}>
      <div style={{ display: "flex", width: "100%", height: "100vh", overflow: "hidden" }} onClick={(e) => setContextMenuContent(null)} onContextMenu={(e) => {
        e.preventDefault();
        setContextMenuContent(null);
      }}>
        {contextMenuContent && <ContextMenu/>}
        {csvFiles}
        {modalContent && <Modal/>}
        <div style={{ width: 300, borderRight: "2px solid black", flexShrink: 0 }} onClick={(e) => setSelection([])}>
          <div style={{ height: "100%", padding: "0px 5px 5px 5px" }}>
            {dataList.map((moduleDataList, i) => {
              return (
                <ModuleData key={i} moduleDataList={moduleDataList} moduleNumber={i + 1} selection={selection} setSelection={setSelection} showModuleDataContext={showModuleDataContext} openData={openData} rerender={rerender}/>
              );
            })}
            {/* <button onClick={(e) => {
              // let temp;
              // temp = dataList[0][0];
              // while(temp.children.length > 0) {
              //   temp = temp.children[0];
              // }
              // temp.children.push({ id: _.uniqueId(), moduleNumber: temp.moduleNumber, name: temp.name, x: temp.x.slice(), y: temp.y.slice(), xUnit: temp.xUnit, yUnit: temp.yUnit, expand: true, parent: temp, children: [] });
              // rerender();
              setModalContent(<ProcessingComponent data={dataList[0][0]} processing={ProcessingList[0]}/>);
            }}>asdf</button> */}
          </div>
        </div>
        <div style={{ width: "calc(100% - 300px)" }}>
          <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div style={{ height: "50%", borderBottom: "1px solid black" }}>
              <GraphComponent type="upper" tabList={graphDataMap.upper} data={upperGraph} setData={setUpperGraph} openProcessingComponent={openProcessingComponent}/>
            </div>
            <div style={{ height: "50%", borderTop: "1px solid black" }}>
              <GraphComponent type="lower" tabList={graphDataMap.lower} data={lowerGraph} setData={setLowerGraph} openProcessingComponent={openProcessingComponent}/>
            </div>
          </DragDropContext>
        </div>
      </div>
    </ContextMenuContext.Provider>
  );
}