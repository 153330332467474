import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import restapi from "../util/restapi";

export default class PostTreatment extends React.Component {
  state = {
    loading: false,
    modal: false,
    content: ""
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  doPost = async () => {
    try {
      return await restapi.postTreatment(
        this.props.user_id,
        this.state.content.trimRight()
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  handleChange = event => {
    this.setState({ content: event.target.value });
  };

  handleOk = async () => {
    this.setState({ loading: true }, async () => {
      await this.doPost();
      this.setState({ loading: false }, this.toggle);
    });
  };

  render = () => {
    return (
      <>
        <Button onClick={this.toggle}>Post</Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          onClosed={this.props.onPosted}
        >
          <ModalHeader toggle={this.toggle}>New Treatment</ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              placeholder="Write something"
              rows={5}
              value={this.state.content}
              onChange={this.handleChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.handleOk}
              disabled={this.state.content.trimRight().length === 0}
            >
              Ok
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };
}
