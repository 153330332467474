import React from "react";
import { CardTitle, CardText, Badge } from "reactstrap";
import MidCard from "./MidCard";
import restapi from "../util/restapi";
import ContactsIcon from "@material-ui/icons/Contacts";
import Lic from "./License";

export default class UserDetail extends React.Component {
  state = {
    loading: false
  };

  fetch = () => {
    if (!this.props.user_id) return;

    this.setState({ loading: true }, async () => {
      let user;

      try {
        const result = await restapi.getUserDetail(this.props.user_id);

        if (result && result.result) user = result.detail;
      } catch (error) {
        console.error(error);
      }

      this.setState({ loading: false, data: user });
    });
  };

  getLicComponent = license => {
    const { model, type } = license;

    let clrModel, clrType;
    switch (model) {
      case "exoRehab":
        clrModel = "primary";
        break;
      default:
        clrModel = "dark";
        break;
    }
    switch (type) {
      case "lite":
        clrType = "secondary";
        break;
      case "standard":
        clrType = "success";
        break;
      case "pro":
        clrType = "info";
        break;
      default:
        clrType = "dark";
        break;
    }

    return (
      <>
        <Badge color={clrModel}>{model}</Badge>{" "}
        <Badge color={clrType}>{type}</Badge>
      </>
    );
  };

  getGroupComponent = group => {
    let clr;

    switch (group) {
      case "master":
        clr = "primary";
        break;
      case "admin":
        clr = "danger";
        break;
      default:
        clr = "dark";
        break;
    }

    return (
      <Badge key={group} color={clr}>
        {group}
      </Badge>
    );
  };

  componentDidUpdate = (props, state) => {
    this.props.user_id !== props.user_id && this.fetch();
  };

  componentDidMount = () => {
    this.fetch();
  };

  render = () => {
    const { data } = this.state;
    let content = null;

    if (data) {
      let birth;

      if (data.birthdate) {
        const dt = new Date(data.birthdate);
        birth = `${dt.getFullYear()}-${(dt.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${dt
          .getDate()
          .toString()
          .padStart(2, "0")}`;
      }
      content = (
        <>
          {data.email && (
            <>
              <CardTitle>Email</CardTitle>
              <CardText>{data.email}</CardText>
            </>
          )}
          {data.family_name && (
            <>
              <CardTitle>Family name</CardTitle>
              <CardText>{data.family_name}</CardText>
            </>
          )}
          {data.given_name && (
            <>
              <CardTitle>Given name</CardTitle>
              <CardText>{data.given_name}</CardText>
            </>
          )}
          {data.middle_name && (
            <>
              <CardTitle>Middle name</CardTitle>
              <CardTitle>{data.middle_name}</CardTitle>
            </>
          )}
          {data.gender && (
            <>
              <CardTitle>Gender</CardTitle>
              <CardText>{data.gender}</CardText>
            </>
          )}
          {birth && (
            <>
              <CardTitle>Birthdate</CardTitle>
              <CardText>{birth}</CardText>
            </>
          )}
          {data.symptoms && (
            <>
              <CardTitle>Symptoms</CardTitle>
              <CardText>{data.symptoms}</CardText>
            </>
          )}
          {data.part && (
            <>
              <CardTitle>Part</CardTitle>
              <CardText>{data.part}</CardText>
            </>
          )}
          <CardTitle>License</CardTitle>
          <CardText>{this.getLicComponent(data.license)}</CardText>
          {data.group.length > 0 && (
            <>
              <CardTitle>Group</CardTitle>
              <CardText>
                {data.group.map(item => this.getGroupComponent(item))}
              </CardText>
            </>
          )}
        </>
      );
    }

    return (
      <MidCard
        icon={<ContactsIcon fontSize="large" color="primary" />}
        title="User info"
        loading={this.state.loading}
      >
        {content}
        <Lic user_id={this.props.user_id} register />
      </MidCard>
    );
  };
}
