import React from "react";
import {
  CardText,
  Progress,
  Alert,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge,
  CardDeck
} from "reactstrap";
import springapi from "../util/springapi";

const mapFesType = new Map();
mapFesType.set("fes_simple_a", "Simple - Strengthening");
mapFesType.set("fes_simple_b", "Simple - Fatigue Relief");
mapFesType.set("fes_diamond_bi", "Advanced - Preset 1");
mapFesType.set("fes_diamond_mono", "Advanced - Preset 2");
mapFesType.set("fes_triangle_bi", "Advanced - Preset 3");
mapFesType.set("fes_triangle_mono", "Advanced - Preset 4");
mapFesType.set("fes_custom", "Custom");
mapFesType.set("none", "Custom");

export default class V2Fes extends React.Component {
  state = {
    loading: false,
    data: []
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.date !== this.props.date) &&
      this.fetch();
  };

  componentDidMount = () => {
    this.props.user_id && this.fetch();
  };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      // const dt = this.props.date;
      // const body = {
      //   user_id: this.props.user_id,
      //   behavior: "fes",
      //   term: "od",
      //   year: dt.getFullYear(),
      //   month: dt.getMonth() + 1,
      //   date: dt.getDate(),
      //   time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      // };
      let data = [];

      try {
        const res = await springapi.getEs(this.props.user_id, this.props.date);
        if (res && res.response) data = res.response;
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false, data });
    });
  };

  render = () => {
    const { data } = this.state;

    return (
      <>
        <ListGroupItem>
          <Row>
            <Col xs="4">Type</Col>
            <Col xs="2">Date</Col>
            <Col xs="2">Start</Col>
            <Col xs="2">End</Col>
            <Col xs="2">Duration</Col>
          </Row>
        </ListGroupItem>
        <ListGroup className="feslist">
          {this.props.user_id == null && (
            <Alert color="warning">select user</Alert>
          )}

          {data.map(d => {
            const startTime = new Date(d.dateTime.substring(0, 4), d.dateTime.substring(4, 6) - 1, d.dateTime.substring(6, 8), d.dateTime.substring(8, 10), d.dateTime.substring(10, 12), d.dateTime.substring(12, 14));
            const endTime = new Date(startTime.getTime());
            endTime.setSeconds(endTime.getSeconds() + d.progressTime);
            const date = startTime.toLocaleDateString();
            // const date = startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate();
            const start = startTime.toLocaleTimeString("en-US", {hour12: false});
            const end = endTime.toLocaleTimeString("en-US", {hour12: false});
            const time = parseInt(d.progressTime / 60) + "분 " + ("0" + d.progressTime % 60).slice(-2) + "초";

            return (
              <ListGroupItem
                key={d.id}
                tag="button"
                data-key={d.id}
                action
              >
                <Row>
                  <Col xs="4">{d.mode}</Col>
                  <Col xs="2">{date}</Col>
                  <Col xs="2">{start}</Col>
                  <Col xs="2">{end}</Col>
                  <Col xs="2">{time}</Col>
                </Row>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </>
    );
  };
}
