import Fetch from "./fetch";
import config from "../config.json";

const getTreatment = async user_id => {
  return await Fetch.get(
    `${config.baseUrl}/${config.stage}/treatment/${user_id}`
  );
};

const updateTreatment = async (id, content) => {
  return await Fetch.patch(
    `${config.baseUrl}/${config.stage}/treatment/${id}`,
    { content }
  );
};

const postTreatment = async (user_to, content) => {
  return await Fetch.post(`${config.baseUrl}/${config.stage}/treatment`, {
    user_to,
    content
  });
};

const deleteTreatment = async id => {
  return await Fetch.del(`${config.baseUrl}/${config.stage}/treatment/${id}`);
};

const getLicense = async () => {
  return await Fetch.get(`${config.baseUrl}/${config.stage}/license`);
};

const postLicense = async (code, user_id) => {
  const uid = user_id ? `/${user_id}` : "";
  return await Fetch.post(
    `${config.baseUrl}/${config.stage}/license/${code}${uid}`,
    null,
    true
  );
};

const patchCalendar = async body => {
  return await Fetch.patch(`${config.baseUrl}/${config.stage}/calendar`, body);
};

const getUserList = async parent_id => {
  return await Fetch.get(
    `${config.baseUrl}/${config.stage}/user${
      parent_id ? `/group/${parent_id}` : ""
    }`
  );
};

const getGroupType = async () => {
  return await Fetch.get(
    `${config.baseUrl}/${config.stage}/group/type`
  );
}

const getGroupSet = async () => {
  return await Fetch.get(
    `${config.baseUrl}/${config.stage}/group/set`
  );
}

const patchTrend = async body => {
  return await Fetch.patch(`${config.baseUrl}/${config.stage}/trend`, body);
};

const getRmsData = async behavior_id => {
  return await Fetch.get(
    `${config.baseUrl}/${config.stage}/rms/${behavior_id}`
  );
};

const getNormData = async behavior_id => {
  return await Fetch.get(
    `${config.baseUrl}/${config.stage}/norm/${behavior_id}`
  );
};

const getRawData = async behavior_id => {
  return await Fetch.get(
    `${config.baseUrl}/${config.stage}/record/raw/${behavior_id}`
  );
};

const getUserDetail = async user_id => {
  return await Fetch.get(`${config.baseUrl}/${config.stage}/user/${user_id}`);
};

const putUserInfo = async user => {
  return await Fetch.put(`${config.baseUrl}/${config.stage}/auth/`, user);
};

export default {
  getTreatment,
  updateTreatment,
  postTreatment,
  deleteTreatment,
  getLicense,
  postLicense,
  getUserList,
  getGroupType,
  getGroupSet,
  patchCalendar,
  patchTrend,
  getRmsData,
  getNormData,
  getRawData,
  getUserDetail,
  putUserInfo
};
