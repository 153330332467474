import _ from "lodash";
import RightIcon from "@material-ui/icons/ArrowRight";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import DashIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState } from "react";

export default function ParameterComponent({ parameter, dataList, value, setValue, visible }) {
  const { name, type, precision, min, max, unit, options } = parameter;
  const [expandMap, setExpandMap] = useState({});

  useEffect(() => {
    if(type === "data") {
      const map = {};
      function getExpand(data) {
        map[data.id] = data.expand;
        data.children.forEach((v) => getExpand(v));
      }
      dataList.forEach((moduleDataList) => {
        moduleDataList.forEach((v) => getExpand(v));
      });
      setExpandMap(map);
    }
  }, [dataList]);

  function TreeComponent({ data }) {
    const expand = expandMap[data.id];
    
    return (
      <div style={{ width: "100%", zIndex: 5 }}
        onClick={(e) => {
          e.stopPropagation();
          setValue(data);
        }}
      >
        <div style={{ display: "flex", backgroundColor: value === data ? "#77ccff" : undefined }}>
          {_.range(data.depth).map((v) => <div key={v} style={{ width: 10, flexShrink: 0, borderRight: "1px solid lightgray" }}/>)}
          <div style={{ display: "flex", flexShrink: 0, justifyContent: "center", alignItems: "center", width: 20, zIndex: 10 }} onClick={(e) => {
            if(data.children.length > 0) {
              setExpandMap({ ...expandMap, [data.id]: !expand });
            }
          }}>
            {data.children.length > 0 ?
              expand ?
                <DownIcon/>
              :
                <RightIcon/>
            :
              <DashIcon style={{ width: 16 }}/>
            }
          </div>
          <div className="unselectable" style={{ paddingRight: 10, }}>{data.name}</div>
        </div>
        {expand && data.children.length > 0 &&
          <div>
            {data.children.map((v, i) => {
              return (
                <TreeComponent key={i} data={v}/>
              );
            })}
          </div>
        }
      </div>
    );
  }

  let inputComponent = null;
  switch(type) {
    case "number":
      inputComponent = (
        <div style={{ display: "flex", width: 150, alignItems: "center" }}>
          <div style={{ flex: "1 0 0" }}>
            <input type="text" style={{ width: "100%", border: "1px solid", borderRadius: 5, paddingLeft: 5 }} value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => {
                if(isNaN(value)) {
                  setValue(0);
                }
                else {
                  if(precision !== undefined) {
                    if(value > 0) {
                      value = _.floor(value, -precision);
                    }
                    else {
                      value = _.ceil(value, -precision);
                    }
                  }
                  if(min !== undefined && value < min) {
                    value = min;
                  }
                  if(max !== undefined && value > max) {
                    value = max;
                  }
                  setValue(parseFloat(value));
                }
              }}
            />
          </div>
          {unit && (
            <div style={{ paddingLeft: 5 }}>{unit}</div>
          )}
        </div>
      );
      break;
    case "checkbox":
      inputComponent = (
        <input type="checkbox" checked={value} onChange={(e) => setValue(e.target.checked)}/>
      );
      break;
    case "select":
      inputComponent = (
        <select style={{ width: 150 }} value={value} onChange={(e) => setValue(e.target.value)}>
          {options.map(({text, value: v}, i) => {
            return (
              <option key={i} value={v}>{text}</option>
            );
          })}
        </select>
      );
      break;
    case "data":
      inputComponent = (
        <div style={{ width: "100%", height: "100%", flex: "1 0 0" }}>
          <div style={{ width: "100%", height: 150, border: "1px solid", overflow: "auto" }}>
            <div style={{ width: "fit-content", minWidth: 358 }}>
              {dataList.map((moduleDataList, i) => (
                <React.Fragment key={i}>
                  {moduleDataList.map((data, j) => (
                      <TreeComponent key={j} data={data}/>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }
  return (
    <div style={{ display: visible ? (type === "data" ? "block" : "flex") : "none", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center", width: 200, height: 30 }}><b>{name}</b></div>
      {inputComponent}
    </div>
  );
}