import React, { Component } from "react";
import { Container, Button } from "reactstrap";

export default class SignUpGateway extends Component {
  state = {
    isNorma: true
  };

  handleNormal = () => {
    this.props.onNormal && this.props.onNormal();
  };

  handleMaster = () => {
    this.props.onMaster && this.props.onMaster();
  };

  render = () => {
    return (
      <>
        <Container style={{ justifyContent: "space-between", display: "flex" }}>
          <Button color="success" onClick={this.handleNormal}>
            일반 회원 가입
          </Button>
          <Button color="info" onClick={this.handleMaster}>
            기관 회원 가입
          </Button>
        </Container>
      </>
    );
  };
}
