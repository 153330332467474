import React, { useEffect, useState } from "react";
import Card from "./V2Card";
import IconExercise from "@material-ui/icons/FitnessCenterRounded";
import IconMq from "@material-ui/icons/AirlineSeatLegroomExtraRounded";
import IconCalendar from "@material-ui/icons/Event";
// import path from "path";

import MqOverview from "./MqOverview";
import translation from "../language/translation";
import { useSettings } from "./context/settings";
import springapi from "../util/springapi";

const Overview = (props) => {
    const [mqData, setMqData] = useState({
        strength: [],
        endurance: []
    });
    const [isLoadingMq, setIsLoadingMq] = useState(false);
    const {language} = useSettings();
    const [l10n, setL10n] = useState({});

    useEffect(() => {
        const date = new Date();
        const dateString = "" + date.getFullYear() + (date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + (date.getDate() >= 10 ? date.getDate() : ("0" + date.getDate()));
        
        setIsLoadingMq(true);
        getMqData(props.userId, dateString)
            .then(() => {
                setIsLoadingMq(false);
            });
        
    }, [props.userId]);

    async function getMqData(id, date) {
        const strength = [], endurance = [];
        const mq = await springapi.request("GET", `/v2/log/get/dataByDays/mq?id=${id}&date=${date}&days=30`);
        if(mq.response) {
            const data = mq.response;
            const dateList = [];
            data.forEach((d) => {
                if(d.strength && d.validity) {
                    if(dateList.includes(d.date)) {
                        strength.find((e) => e.date == d.date).values.push(d.strength);
                        endurance.find((e) => e.date == d.date).values.push(d.endurance);
                    }
                    else {
                        dateList.push(d.date);
                        strength.push({ date: d.date, values: [d.strength] });
                        endurance.push({ date: d.date, values: [d.endurance] });
                    }
                }
            });
        }
        setMqData({ strength, endurance });
    }

    useEffect(() => {
        setL10n(translation.translate("overview", language));
    }, [language]);

    return (
        <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                <IconCalendar style={{ marginRight: 5 }}/> <h4>{new Date().toLocaleDateString()}</h4>
            </div>
            <div style={{ flex: "1 1 auto" }}>
                <Card title={l10n.exercise} icon={<IconExercise style={{ marginRight: 5 }}/>} style={{ height: "50%" }}>

                </Card>
                <Card title={l10n.mq} icon={<IconMq style={{ marginRight: 5 }}/>} style={{ height: "50%" }} loading={isLoadingMq}>
                    <div style={{ height: "100%" }}>
                        <div style={{ height: "48%" }}>
                            <MqOverview type="strength" data={mqData.strength}/>
                        </div>
                        <hr style={{ height: "4%", margin: 0 }}/>
                        <div style={{ height: "48%" }}>
                            <MqOverview type="endurance" data={mqData.endurance}/>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Overview;