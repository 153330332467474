import React, { Component } from "react";
import Calendar from "react-calendar";
import MidCard from "../component/MidCard";
import restapi from "../util/restapi";
import IconRom from "@material-ui/icons/AirlineSeatLegroomExtraRounded";
import IconRehab from "@material-ui/icons/AccessibilityNewRounded";
import IconEmg from "@material-ui/icons/FitnessCenterRounded";
import IconFes from "@material-ui/icons/FlashOnRounded";
import Chip from "@material-ui/core/Chip";
import EventIcon from "@material-ui/icons/Event";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { TrendLastRomSingle } from "../trend";

export default class ExoCalendar extends Component {
  state = {
    loading: false,
    date: this.test(),
    data: null,
    modal: false,
    opened: false
  };
  test(prevState){
    prevState && console.log(this.state.date);
    return new Date();
  }

  componentDidMount() {
    this.props.user_id && this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {
    const dtPrev = prevState.date;
    const dt = this.state.date;
    this.props.user_id &&
      (this.props.user_id !== prevProps.user_id ||
        dtPrev.getFullYear() !== dt.getFullYear() ||
        dtPrev.getMonth() !== dt.getMonth()) &&
      this.fetch();
  }

  fetch = () => {
    this.setState({ loading: true, data: null }, async () => {
      const dt = this.state.date;
      // console.log(dt);
      const body = {
        user_id: this.props.user_id,
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      let data = null;
      try {
        const res = await restapi.patchCalendar(body);

        if (res && res.result) {
          res.detail.forEach(item => {
            const dt = new Date(item.dt);
            item.dt = dt.toLocaleDateString();
          });

          const dts = res.detail.reduce(
            (acc, item) => acc.set(item.dt, new Set()),
            new Map()
          );

          while (res.detail.length > 0) {
            const row = res.detail.shift();
            dts.set(row.dt, dts.get(row.dt).add(row.type));
          }
          data = dts;
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false, data });
    });
  };

  dayTile = ({ date, view }) => {
    if (view !== "month" || !this.state.data) return null;

    const [clrOn, clrOff] = ["primary", "disabled"];
    const find = this.state.data.get(date.toLocaleDateString());
    const clrRom = find && find.has("m_rom") ? clrOn : clrOff;
    const clrEmg =
      find && (find.has("m_emg_thigh") || find.has("m_emg_shin"))
        ? clrOn
        : clrOff;
    const clrFes = find && find.has("fes") ? clrOn : clrOff;
    const clrRehab = find && find.has("rehab") ? clrOn : clrOff;

    return (
      <div>
        <IconRom key="rom" color={clrRom} fontSize="small" />
        <IconEmg key="emg" color={clrEmg} fontSize="small" />
        <IconFes key="fes" color={clrFes} fontSize="small" />
        <IconRehab key="rehab" color={clrRehab} fontSize="small" />
      </div>
    );
  };

  disableTile = () => {
    return this.state.loading;
  };

  onClickDay = value => {
    this.setState({ date: value });
    this.props.onDateChange && this.props.onDateChange(value);
  };

  onActiveDateChange = ({ activeStartDate, view }) => {
    view === "month" && this.setState({ date: activeStartDate });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleOpen = () => this.setState({ opened: !this.state.opened });

  render() {
    const chipDate = [
      { icon: <IconRom />, text: "Rom measurement" },
      { icon: <IconEmg />, text: "Emg measurement" },
      { icon: <IconFes />, text: "Fes" },
      { icon: <IconRehab />, text: "Rehabilitation" }
    ];
    return (
      <MidCard
        icon={<EventIcon fontSize="large" color="primary" />}
        title="Calendar"
        loading={this.state.loading}
        style= {{ flex: "0 0 40%", marginRight: 0 }}
        center
      >
        <div className="calendar">
          <Calendar
            key={new Date().getTime()}
            value={this.state.date}
            tileContent={this.dayTile}
            tileDisabled={this.disableTile}
            formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})}
            onClickDay={this.onClickDay}
            onActiveStartDateChange={this.onActiveDateChange}
            calendarType="US"
          />
        </div>
        <p />
        <div className="chipgroup">
          {chipDate.map((item, i) => (
            <Chip
              key={i}
              icon={item.icon}
              label={item.text}
              variant="outlined"
              style={{ margin: 0.5 }}
            />
          ))}
        </div>
        <div style={{ marginTop: 15 }}>
          <Button onClick={this.toggle}>Rom detail last day</Button>
          <Modal
            className="modal-full"
            isOpen={this.state.modal}
            toggle={this.toggle}
            onClosed={this.toggleOpen}
            onOpened={this.toggleOpen}
            centered
          >
            <ModalHeader toggle={this.toggle}>Rom detail last day</ModalHeader>
            <ModalBody>
              <TrendLastRomSingle user_id={this.props.user_id} />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </MidCard>
    );
  }
}
