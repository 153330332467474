import React from "react";
import MidCard from "../component/MidCard";
import {
  CardText,
  Progress,
  Alert,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge
} from "reactstrap";
import restapi from "../util/restapi";
import util from "../util/util";
import IconFes from "@material-ui/icons/FlashOnRounded";

const mapFesType = new Map();
mapFesType.set("fes_simple_a", "Simple - Strengthening");
mapFesType.set("fes_simple_b", "Simple - Fatigue Relief");
mapFesType.set("fes_diamond_bi", "Advanced - Preset 1");
mapFesType.set("fes_diamond_mono", "Advanced - Preset 2");
mapFesType.set("fes_triangle_bi", "Advanced - Preset 3");
mapFesType.set("fes_triangle_mono", "Advanced - Preset 4");
mapFesType.set("fes_custom", "Custom");
mapFesType.set("none", "Custom");

export default class TrenFesOneDay extends React.Component {
  state = {
    loading: false,
    data: []
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.date !== this.props.date) &&
      this.fetch();
  };

  componentDidMount = () => {
    this.props.user_id && this.fetch();
  };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const dt = this.props.date;
      const body = {
        user_id: this.props.user_id,
        behavior: "fes",
        term: "od",
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        date: dt.getDate(),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      let data = [];

      try {
        const res = await restapi.patchTrend(body);

        if (res && res.result) data = res.detail;
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false, data });
    });
  };

  render = () => {
    const { data } = this.state;

    return (
      <MidCard
        icon={<IconFes fontSize="large" color="primary" />}
        title="FES"
        loading={this.state.loading}
      >
        <ListGroup>
          <ListGroupItem>
            <Row>
              <Col xs="4">Type</Col>
              <Col xs="2">Start</Col>
              <Col xs="2">End</Col>
              <Col xs="2">Period</Col>
              <Col xs="2">Part</Col>
            </Row>
          </ListGroupItem>

          {data.map(item => {
            const dtSt = new Date(item.time_st);
            const dtEd = new Date(item.time_ed);

            return (
              <ListGroupItem
                key={item.behavior_id}
                tag="button"
                data-key={item.behavior_id}
                action
              >
                <Row>
                  <Col xs="4">{mapFesType.get(item.type_sub)}</Col>
                  <Col xs="2">{dtSt.toLocaleTimeString()}</Col>
                  <Col xs="2">{dtEd.toLocaleTimeString()}</Col>
                  <Col xs="2">
                    {util.convDt2SimpleTime(Math.abs(dtSt - dtEd))}
                  </Col>
                  <Col xs="2">
                    <Badge
                      key="thigh"
                      color={
                        item.channel.includes("thigh") ? "primary" : "secondary"
                      }
                    >
                      Thigh
                    </Badge>{" "}
                    <Badge
                      key="shin"
                      color={
                        item.channel.includes("shin") ? "primary" : "secondary"
                      }
                    >
                      Shin
                    </Badge>
                  </Col>
                </Row>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </MidCard>
    );
  };
}
