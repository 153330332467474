import Plot from "react-plotly.js";
import Plotly from "plotly.js-basic-dist-min";
import React, { useEffect, useState } from "react";

const PlotComponent = React.memo(function PlotComponent({ data }) {
  const { x, y, id } = data;
  // const [range, setRange] = useState([x[0], x[x.length - 1]]);
  // const [sliderRange, setSliderRange] = useState([x[0], x[x.length - 1]]);

  // useEffect(() => {
  //   const data = [
  //     {
  //       x,
  //       y,
  //       line: {
  //         color: "limegreen",
  //         width: 1
  //       }
  //     }
  //   ];
  //   const layout = {
  //     margin: {
  //       t: 10,
  //       r: 10,
  //       b: 10,
  //       l: 60
  //     },
  //     plot_bgcolor: "black",
  //     // paper_bgcolor: "black",
  //     xaxis: {
  //       gridcolor: "#222222",
  //       mirror: true,
  //       showline: true,
  //       rangeslider: {
  //       }
  //     },
  //     yaxis: {
  //       // color: "white",
  //       gridcolor: "#222222",
  //       mirror: true,
  //       showline: true,
  //       fixedrange: true
  //     }
  //   };
  //   const config = { responsive: true, autosizable: true, displayModeBar: false };
  //   Plotly.newPlot("plot" + id, data, layout, config);
  // }, []);
  
  // return <div id={"plot" + id}/>

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Plot
        divId={"plot" + id}
        data={[
          {
            x,
            y,
            line: {
              color: "limegreen",
              width: 1
            }
          }
        ]}
        layout={{
          hovermode: "x",
          margin: {
            t: 10,
            r: 10,
            b: 10,
            l: 80
          },
          plot_bgcolor: "black",
          // paper_bgcolor: "black",
          xaxis: {
            gridcolor: "#222222",
            mirror: true,
            showline: true,
            rangeslider: {},
            showspikes: true,
            spikemode: "toaxis+across",
            spikedash: "solid",
            spikesnap: "cursor",
            spikethickness: 1,
            spikecolor: "white"
          },
          yaxis: {
            // color: "white",
            // title: `[${data.yUnit}]`,
            gridcolor: "#222222",
            mirror: true,
            showline: true,
            fixedrange: true,
            exponentformat: "e",
            // tickformat: "2e",
            // hoverformat: "e"
          },
          annotations: [
            {
              showarrow: false,
              xref: "paper",
              yref: "paper",
              y: 0,
              yshift: -12,
              yanchor: "top",
              text: `[${data.xUnit}]`
            },
            {
              showarrow: false,
              textangle: 270,
              xref: "paper",
              yref: "paper",
              x: 0,
              xshift: -55,
              xanchor: "right",
              text: `[${data.yUnit}]`
            }
          ]
        }}
        style={{ width: "100%", height: "100%" }}
        useResizeHandler={true}
        config={{ responsive: true, autosizable: true, displayModeBar: false }}
        // onClick={(e) => console.log(e)}
        // onRelayout={(event) => {
        //   console.log(event);
        //   if(event["xaxis.range[0]"]) {
        //     console.log(event["xaxis.range[0]"], event["xaxis.range[1]"]);
        //     // setRange([event["xaxis.range[0]"], event["xaxis.range[1]"]])
        //   }
        //   else if(event["xaxis.range"]) {
        //     console.log(event["xaxis.range"][0], event["xaxis.range"][1]);
        //     // if(event["xaxis.range"][0] !== event["xaxis.range"][1]) {
        //       // setRange([event["xaxis.range"][0], event["xaxis.range"][1]]);
        //     // }
        //     // if(event["xaxis.range"][0] < x[0] || event["xaxis.range"][0] > x[x.length - 1]) {
        //     //   Plotly.Plots.resize("plot" + id);
        //     //   Plotly.relayout("plot" + id)
        //     // }
        //     // else {
              
        //     // }
        //   }
        // }}
      />
    </div>
  );
});

export default PlotComponent;