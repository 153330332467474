import React from "react";
import { SignUp } from "aws-amplify-react";
import { CardTitle, Button, CardFooter, CardHeader } from "reactstrap";
import { InputGroup, InputGroupText, InputGroupAddon, Input } from "reactstrap";
import { Form, FormGroup, Label, Alert, Container } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import MidCard from "../component/MidCard";
import TopSpacer from "./../component/TopSpacer";
import Master from "./SignUpMaster";
import Normal from "./SignUpNormal";
import Gateway from "./SignUpGateway";
import Terms from "./SignUpTerms";
import MobileStepper from "@material-ui/core/MobileStepper";

import config from "../config.json";
import util from "../util/util";

const steps = [];
const maxStep = steps.length;

export default class CustomSignUp extends SignUp {
  state = {
    activeStep: 0
  };

  componentDidUpdate = () => {
    // this.state.activeStep && this.setState({ activeStep: 0 });
    if(this.props.expand === "exoFit") {
      if(this.state.activeStep) {
        this.setState({ activeStep: 0 }, () => super.changeState("signIn"));
      }
      else {
        super.changeState("signIn");
      }
    }
  }
  
  handleReject = () => {
    this.setState({ activeStep: 0 });
  };

  constructor(props) {
    super(props);
    this._validAutoStates = ["signUp"];
    this.errorMessage = this.handleError;
    steps.push(
      <Gateway
        onNormal={() => this.setState({ activeStep: 1 })}
        onMaster={() => this.setState({ activeStep: 2 })}
      />,
      <Terms
        title="일반 회원 가입"
        term="기관 회원 가입 약관, 기관 회원 개인 정보 수집 및 이용, 기관 회원 개인 정보 처리방침에 모두 동의합니다."
        onAccept={() => this.setState({ activeStep: 3 })}
        onReject={this.handleReject}
        url="https://www.exosystems.io/pricvacy-and-policy"
      />,
      <Terms
        title="기관 회원 가입"
        term="기관 회원 가입 약관, 기관 회원 개인 정보 수집 및 이용, 기관 회원 개인 정보 처리방침에 모두 동의합니다."
        onAccept={() => this.setState({ activeStep: 4 })}
        onReject={this.handleReject}
        url="https://www.exosystems.io/pricvacy-and-policy"
      />,
      <Normal title="일반 회원 가입" />,
      <Master title="기관 회원 가입" />
    );
  }

  showComponent(theme) {
    return (
      <MidCard style={{ border: "none" }}>
        <CardTitle>
          <h3>회원 가입</h3>
        </CardTitle>
        <TopSpacer height="5vh" />
        {steps[this.state.activeStep]}
        <MobileStepper
          steps={maxStep}
          position="static"
          activeStep={this.state.activeStep}
        />
        <CardTitle style={{ justifyContent: "center", display: "flex" }}>
          <Button
            color="link"
            onClick={() => {
              this.setState({ activeStep: 0 }, () =>
                super.changeState("signIn")
              );
            }}
          >
            {"<"} Login
          </Button>
        </CardTitle>
      </MidCard>
    );
  }
}
