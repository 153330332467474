import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from "@material-ui/icons/Close";

const Tab = React.memo(function Tab({ index, type, tabData, provided, isDragging, data, setData, removeTab }) {
  // const [tabList, setTabList] = states[type].list;
  // const [data, setData] = states[type].data;

  return (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      <div id={tabData.id} style={{ display: "flex", alignItems: "center", width: 200, height: 32, padding: 5,
        backgroundColor: data && tabData.id === data.id ? "white" : "#eeeeee", border: "1px solid black", margin: "-1px 0 0 -1px",
        borderBottom: (data && tabData.id === data.id && !isDragging) ? "1px solid white" : "1px solid black", cursor: "pointer"
      }}
        onMouseDown={(e) => setData(tabData)}
        >
        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{tabData.name}</div>
        <div style={{ flexGrow: 1 }}/>
        <CloseIcon style={{ padding: "2px 0 1px 0", cursor: "pointer" }} onMouseDown={(e) => e.stopPropagation()} onClick={(e) => {
          e.stopPropagation();
          removeTab(index);
        }}/>
      </div>
    </div>
  );
});

function Tabs({ type, tabList, data, setData }) {
  const [render, setRender] = useState([]);
  function removeTab(index) {
    tabList.splice(index, 1);
    if(tabList.length > index) {
      setData(tabList[index]);
    }
    else if(tabList.length === 0) {
      setData(null);
    }
    else {
      setData(tabList[tabList.length - 1]);
    }
    setRender([]);
  }

  return tabList.map((tabData, index) => (
    <Draggable key={tabData.id} draggableId={tabData.id} index={index}>
      {(dragProvided, dragSnapshot) => (
        <Tab index={index} type={type} tabData={tabData} provided={dragProvided} isDragging={dragSnapshot.isDragging} data={data} setData={setData} removeTab={removeTab}/>
      )}
    </Draggable>
  ))
}

export default Tabs;