function translate(file, language) {
    const json = require(`./${file}.json`);
    if(json[language]) {
        const keyList = Object.keys(json["en"]);
        keyList.map((key) => {
            if(!json[language][key]) {
                json[language][key] = json["en"][key];
            }
        });
        return json[language];
    }
    return json["en"];
}

function getLanguage() {
    const language = localStorage.getItem("language");
    if(language) {
        return language;
    }
    localStorage.setItem("language", "en");
    return "en";
}

function setLanguage(language) {
    localStorage.setItem("language", language);
}

export default { translate, getLanguage, setLanguage };