import { useEffect } from "react";
import { useState } from "react";
import {
  Button
} from "reactstrap";
import springapi from "../util/springapi";

export default function MemoComponent({ data }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(data.memo ?? "");

  useEffect(() => {
    if(isEditing === true) {
      setEditText(data.memo ?? "");
    }
  }, [isEditing]);

  function onTextChange(e) {
    setEditText(e.target.value);
  }

  async function saveText() {
    try {
      const response = await springapi.request("POST", "/v2/log/update/exerciseMemo", { exerciseId: data.id, memo: editText });
      data.memo = editText;
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setIsEditing(false);
  }

  return (
    <div style={{ padding: "10px 30px 10px 30px" }}>
      {isEditing ?
        <>
          <div style={{ display: "flex", alignItems: "center", marginLeft: 10, marginRight: 10 }}>
            <div><b>Memo</b></div>
            <div style={{ flexGrow: 1 }}/>
            <Button color="primary" style={{ height: 25, padding: "2px 5px 2px 5px", boxShadow: "none", marginRight: 10 }} disabled={editText.length > 10000} onClick={saveText}>Save</Button>
            <Button color="white" style={{ height: 25, padding: "2px 5px 2px 5px", boxShadow: "none", borderColor: "black" }} onClick={() => setIsEditing(false)}>Cancel</Button>
          </div>
        <textarea style={{ width: "calc(100% - 20px) ", height: 100, resize: "none", marginTop: 5, marginLeft: 10, marginRight: 10 }} onChange={onTextChange} value={editText}/>
        </>
      :
        <>
          <div style={{ display: "flex", alignItems: "center", marginLeft: 10, marginRight: 10 }}>
            <div><b>Memo</b></div>
            <div style={{ flexGrow: 1 }}/>
            <Button color="primary" style={{ height: 25, padding: "2px 5px 2px 5px", boxShadow: "none" }} onClick={() => setIsEditing(true)}>Edit</Button>
          </div>
          <div style={{ marginTop: 5, marginLeft: 10, marginRight: 10, whiteSpace: "pre-wrap" }}>
            {data.memo}
          </div>
        </>
      }
    </div>
  );
}