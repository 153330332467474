import * as d3 from "d3";
import d3tip from "d3-tip";

const simpleGraph = (nameSvg, data) => {
  const svg = d3.select(nameSvg);

  svg.selectAll("*").remove();

  if (!data || data.length === 0) return;

  let { width, height } = svg.node().getBoundingClientRect();
  const [offsetWidth, offsetHeight] = [30, 30];

  height -= offsetHeight * 2;
  width -= offsetWidth * 2;

  const xScale = d3
    .scaleLinear()
    .domain([0, data.length - 1])
    .range([0, width]);
  const yScale = d3
    .scaleLinear()
    .domain([d3.min(data), d3.max(data)])
    .range([height, 0]);
  const chart = svg
    .append("g")
    .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);

  chart
    .append("g")
    .attr("class", "grid")
    .call(d3.axisLeft(yScale).ticks(5));
  chart
    .append("g")
    .attr("class", "grid")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(xScale));

  const line = d3
    .line()
    .x((d, i) => xScale(i))
    .y(d => yScale(d));

  chart
    .append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", "blue")
    .attr("stroke-width", 0.5)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("class", "line")
    .attr("d", line);
};

const barGraph = (nameSvg, data) => {
  const svg = d3.select(nameSvg);

  svg.selectAll("*").remove();

  if (!data || data.length === 0) return;

  let { width, height } = svg.node().getBoundingClientRect();
  const [offsetWidth, offsetHeight] = [30, 30];

  height -= offsetHeight * 2;
  width -= offsetWidth * 2;

  const xScale = d3
    .scaleBand()
    .domain(data.map((d, i) => i))
    .range([0, width])
    .padding(0.1);
    
  const yScale = d3
    .scaleLinear()
    .domain([parseInt(d3.min(data) / 10) * 10 - 10 < 0 ? 0 : parseInt(d3.min(data) / 10) * 10 - 10, d3.max(data)])
    .range([height, 0]);
  const graph = svg
    .append("g")
    .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);

  graph
    .append("g")
    .attr("class", "grid")
    .call(d3.axisLeft(yScale).ticks(5));
  graph
    .append("g")
    .attr("class", "grid")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(xScale));

  const tip = d3tip()
    .attr("class", "d3-tip")
    .html(d => {
      if (d === 0) return null;
      return `<strong>${d}</strong>`;
    });

  graph.call(tip);
  graph
    .append("g")
    .selectAll("rect")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("height", d =>
      d === 0 ? 0 : height - yScale(d)
    )
    .attr("width", xScale.bandwidth() / 2)
    .attr("x", (d, i) => xScale(i) + xScale.bandwidth() / 4)
    .attr("y", d => yScale(d))
    .exit();

  graph
    .append("g")
    .selectAll("rect")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "barfill")
    .attr("height", d => (d === 0 ? 0 : height))
    .attr("width", xScale.bandwidth())
    .attr("x", (d, i) => xScale(i))
    .attr("y", 0)
    .on("mouseover", tip.show)
    .on("mouseout", tip.hide)
    .exit();

  const xLine = d3
    .scaleBand()
    .domain(data.map((d, i) => i))
    .range([xScale.bandwidth() / 2, width + xScale.bandwidth() / 2])
    .padding(0.1);
    
  const line = d3
    .line()
    .x((d, i) => xLine(i))
    .y(d => yScale(d));

  graph
    .append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", "blue")
    .attr("stroke-width", 0.5)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("class", "line")
    .attr("d", line);
  
};

const barGraphWithNegative = (nameSvg, data) => {
  const svg = d3.select(nameSvg);

  svg.selectAll("*").remove();

  if (!data || data.length === 0) return;
  let { width, height } = svg.node().getBoundingClientRect();
  const [offsetWidth, offsetHeight] = [30, 20];

  height -= offsetHeight * 2;
  width -= offsetWidth * 2;

// var y0 = Math.max(Math.abs(d3.min(data)), Math.abs(d3.max(data)));

var y = d3.scaleLinear()
    .domain([d3.min(data) > 0 ? 0 : d3.min(data) , d3.max(data) < 0 ? 0 : d3.max(data)])
    .range([height,0]);

var x = d3.scaleBand()
    .domain(d3.range(data.length))
    .rangeRound([0, width]);
    
var yAxis = d3.axisLeft(y).tickFormat(d => {
  if(d > 0) {
    return "+" + d;
  }
  return d;
})
.tickSizeOuter(0);
// console.log(yAxis.scale().ticks(yAxis.ticks()[0]))

// svg
//   .attr("width", width + offsetWidth * 2)
//   .attr("height", height + offsetHeight * 2)
const graph = svg
  .append("g")
  .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);

graph.selectAll(".bar")
    .data(data)
    .enter().append("rect")
    .attr("class", function(d) { return d < 0 ? "bar negative" : "bar positive"; })
    .attr("y", function(d) { return y(Math.max(0, d)); })
    .attr("x", function(d, i) { return x(i) + x.bandwidth() / 4; })
    .attr("height", function(d) { return Math.abs(y(d) - y(0)); })
    .attr("width", x.bandwidth() / 2);

graph.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0, -0.5)")
    .call(yAxis.ticks(5));

graph.append("g")
    .attr("class", "y axis")
    .append("line")
    .attr("y1", y(0))
    .attr("y2", y(0))
    .attr("x1", 0)
    .attr("x2", width)

const tip = d3tip()
  .attr("class", "d3-tip")
  .html(d => {
    if (d === 0) return null;
    return `<strong>${d}</strong>`;
  });

graph.call(tip);

graph
    .append("g")
    .selectAll("rect")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "barfill")
    .attr("height", d => (d === 0 ? 0 : height))
    .attr("width", x.bandwidth())
    .attr("x", (d, i) => x(i))
    .attr("y", 0)
    .on("mouseover", tip.show)
    .on("mouseout", tip.hide)
    .exit();
    // .attr("fill", "none")
    // .attr("stroke", "#000")
    // .attr("shape-rendering", "crispEdges");

  // let { width, height } = svg.node().getBoundingClientRect();
  // const [offsetWidth, offsetHeight] = [30, 20];

  // height -= offsetHeight * 2 + 50;
  // width -= offsetWidth * 2;

  // // if(d3.min(data) < 0) {
  // //   height *= Math.abs(d3.max(data)) / (Math.abs(d3.max(data)) + Math.abs(d3.min(data)))
  // // }

  // const xScale = d3
  //   .scaleBand()
  //   .domain(data.map((d, i) => i))
  //   .range([0, width])
  //   .padding(0.1);
  // const yScale = d3
  //   .scaleLinear()
  //   .domain([0, d3.max(data)])
  //   .range([0, height]);
  // const yAxisScale = d3
  //   .scaleLinear()
  //   .domain([d3.min(data), d3.max(data) < 0 ? 0 : d3.max(data)])
  //   .range([height - yScale(d3.min(data)), 0]);
  // const graph = svg
  //   .append("g")
  //   .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);

  // graph
  //   .append("g")
  //   .attr("class", "grid")
  //   .call(d3.axisLeft(yAxisScale).ticks(5));
  // graph
  //   .append("g")
  //   .attr("class", "grid")
  //   .attr("transform", `translate(0, ${height})`)
  //   .call(d3.axisBottom(xScale));

  // const tip = d3tip()
  //   .attr("class", "d3-tip")
  //   .html(d => {
  //     if (d === 0) return null;
  //     return `<strong>${d}</strong>`;
  //   });

  // graph.call(tip);
  // graph
  //   .append("g")
  //   .selectAll("rect")
  //   .data(data)
  //   .enter()
  //   .append("rect")
  //   .attr("class", "bar")
  //   .attr("height", d =>
  //     Math.abs(yScale(d))
  //   )
  //   .attr("width", xScale.bandwidth() / 2)
  //   .attr("x", (d, i) => xScale(i) + xScale.bandwidth() / 4)
  //   .attr("y", d => height - Math.max(0, yScale(d)))
  //   .exit();

  // graph
  //   .append("g")
  //   .selectAll("rect")
  //   .data(data)
  //   .enter()
  //   .append("rect")
  //   .attr("class", "barfill")
  //   .attr("height", d => (d === 0 ? 0 : height))
  //   .attr("width", xScale.bandwidth())
  //   .attr("x", (d, i) => xScale(i))
  //   .attr("y", 0)
  //   .on("mouseover", tip.show)
  //   .on("mouseout", tip.hide)
  //   .exit();

  // const xLine = d3
  //   .scaleBand()
  //   .domain(data.map((d, i) => i))
  //   .range([xScale.bandwidth() / 2, width + xScale.bandwidth() / 2])
  //   .padding(0.1);
    
  // const line = d3
  //   .line()
  //   .x((d, i) => xLine(i))
  //   .y(d => height - yScale(d));

  // graph
  //   .append("path")
  //   .datum(data)
  //   .attr("fill", "none")
  //   .attr("stroke", "blue")
  //   .attr("stroke-width", 0.5)
  //   .attr("stroke-linejoin", "round")
  //   .attr("stroke-linecap", "round")
  //   .attr("class", "line")
  //   .attr("d", line);
  
};

const balanceGraph = (nameSvg, data, x) => {
  const svg = d3.select(nameSvg);

  svg.selectAll("*").remove();
  // d3.select("div.d3-tip").remove();

  if (!data || data.length === 0) return;

  let { width, height } = svg.node().getBoundingClientRect();
  const [offsetWidth, offsetHeight] = [30, 30];

  height -= offsetHeight * 2;
  width -= offsetWidth * 2;

  const xScale = d3
    .scaleBand()
    .domain([0,1])
    .range([0, width])
    .padding(0.1);
  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(data)])
    .range([height, 0]);
  
  const graph = svg
    .append("g")
    .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);

  graph
    .append("g")
    .attr("class", "grid")
    .call(d3.axisLeft(yScale).ticks(5));
  graph
    .append("g")
    .attr("class", "grid")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(d3
      .scaleBand()
      .domain(x)
      .range([0, width])
      .padding(0.1)));

  const tip = d3tip()
    .attr("class", "d3-tip")
    .html(d => {
      if (d === 0) return null;
      return `<strong>${d}</strong>`;
    });

  graph.call(tip);
  graph
    .append("g")
    .selectAll("rect")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("height", d =>
      d === 0 ? 0 : height - yScale(d)
    )
    .attr("width", xScale.bandwidth())
    .attr("x", (d, i) => xScale(i))
    .attr("y", d => yScale(d))
    .exit();

  graph
    .append("g")
    .selectAll("rect")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "barfill")
    .attr("height", d => (d === 0 ? 0 : height))
    .attr("width", xScale.bandwidth())
    .attr("x", (d, i) => xScale(i))
    .attr("y", 0)
    .on("mouseover", tip.show)
    .on("mouseout", tip.hide)
    .exit();
};

// function DivergingBarChart(nameSvg, data, {
//   x = d => d, // given d in data, returns the (quantitative) x-value
//   y = (d, i) => i, // given d in data, returns the (ordinal) y-value
//   title, // given d in data, returns the title text
//   marginTop = 30, // top margin, in pixels
//   marginRight = 40, // right margin, in pixels
//   marginBottom = 10, // bottom margin, in pixels
//   marginLeft = 40, // left margin, in pixels
//   width = 640, // outer width of chart, in pixels
//   height, // the outer height of the chart, in pixels
//   xType = d3.scaleLinear, // type of x-scale
//   xDomain, // [xmin, xmax]
//   xRange = [marginLeft, width - marginRight], // [left, right]
//   xFormat, // a format specifier string for the x-axis
//   xLabel, // a label for the x-axis
//   yPadding = 0.1, // amount of y-range to reserve to separate bars
//   yDomain, // an array of (ordinal) y-values
//   yRange, // [top, bottom]
//   colors = d3.schemePiYG[3] // [negative, …, positive] colors
// } = {}) {
//   // Compute values.
//   const X = d3.map(data, x);
//   const Y = d3.map(data, y);

//   // Compute default domains, and unique the y-domain.
//   if (xDomain === undefined) xDomain = d3.extent(X);
//   if (yDomain === undefined) yDomain = Y;
//   yDomain = new d3.InternSet(yDomain);

//   // Omit any data not present in the y-domain.
//   // Lookup the x-value for a given y-value.
//   const I = d3.range(X.length).filter(i => yDomain.has(Y[i]));
//   const YX = d3.rollup(I, ([i]) => X[i], i => Y[i]);

//   // Compute the default height.
//   if (height === undefined) height = Math.ceil((yDomain.size + yPadding) * 25) + marginTop + marginBottom;
//   if (yRange === undefined) yRange = [marginTop, height - marginBottom];

//   // Construct scales, axes, and formats.
//   const xScale = xType(xDomain, xRange);
//   const yScale = d3.scaleBand(yDomain, yRange).padding(yPadding);
//   const xAxis = d3.axisTop(xScale).ticks(width / 80, xFormat);
//   const yAxis = d3.axisLeft(yScale).tickSize(0).tickPadding(6);
//   const format = xScale.tickFormat(100, xFormat);

//   // Compute titles.
//   if (title === undefined) {
//     title = i => `${Y[i]}\n${format(X[i])}`;
//   } else if (title !== null) {
//     const O = d3.map(data, d => d);
//     const T = title;
//     title = i => T(O[i], i, data);
//   }
//   const svg = d3.select(nameSvg);
//   svg
//       .attr("width", width)
//       .attr("height", height)
//       .attr("viewBox", [0, 0, width, height])
//       .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

//   svg.append("g")
//       .attr("transform", `translate(0,${marginTop})`)
//       .call(xAxis)
//       .call(g => g.select(".domain").remove())
//       .call(g => g.selectAll(".tick line").clone()
//           .attr("y2", height - marginTop - marginBottom)
//           .attr("stroke-opacity", 0.1))
//       .call(g => g.append("text")
//           .attr("x", xScale(0))
//           .attr("y", -22)
//           .attr("fill", "currentColor")
//           .attr("text-anchor", "center")
//           .text(xLabel));

//   const bar = svg.append("g")
//     .selectAll("rect")
//     .data(I)
//     .join("rect")
//       .attr("fill", i => colors[X[i] > 0 ? colors.length - 1 : 0])
//       .attr("x", i => Math.min(xScale(0), xScale(X[i])))
//       .attr("y", i => yScale(Y[i]))
//       .attr("width", i => Math.abs(xScale(X[i]) - xScale(0)))
//       .attr("height", yScale.bandwidth());

//   if (title) bar.append("title")
//       .text(title);

//   svg.append("g")
//       .attr("text-anchor", "end")
//       .attr("font-family", "sans-serif")
//       .attr("font-size", 10)
//     .selectAll("text")
//     .data(I)
//     .join("text")
//       .attr("text-anchor", i => X[i] < 0 ? "end" : "start")
//       .attr("x", i => xScale(X[i]) + Math.sign(X[i] - 0) * 4)
//       .attr("y", i => yScale(Y[i]) + yScale.bandwidth() / 2)
//       .attr("dy", "0.35em")
//       .text(i => format(X[i]));

//   svg.append("g")
//       .attr("transform", `translate(${xScale(0)},0)`)
//       .call(yAxis)
//       .call(g => g.selectAll(".tick text")
//         .filter(y => YX.get(y) < 0)
//           .attr("text-anchor", "start")
//           .attr("x", 6));

//   return svg.node();
// }

export default { simpleGraph, barGraph, balanceGraph, barGraphWithNegative };