import axios from "axios";
import config from "../config.json";

const headers = {"API-Key": `${config.apiKey}`};
const mode = process.env.NODE_ENV;
const server = mode === "development" ? "http://localhost:5000" : "https://api.exorehab.com";

const loginByPhone = async (body) => {
    // let response;
    // await axios.post(`/v2/user/loginByPhone`, body, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("POST", `/v2/user/loginByPhone`, body);
}

const loginByPhoneGet = async (phone, password) => {
    // let response;
    // await axios.get(`/v2/user/loginByPhone?phone=${phone}&password=${password}`, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("GET", `/v2/user/loginByPhone?phone=${phone}&password=${password}`);
}

const getKakaoUserId = async (redirectUri, code) => {
    const response = await request("GET", `/v2/user/getKakaoUser?redirect_uri=${redirectUri}&code=${code}`);
    // await axios.get(`/v2/user/getKakaoUser?redirect_uri=${redirectUri}&code=${code}`, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return response.body.id;
}

const loginBySNS = async (body) => {
    const response = await request("POST", `/v2/user/loginBySNS`, body);
    // await axios.post(`/v2/user/loginBySNS`, body, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return response.body.exists;
}

const fatigue = async(body) => {
    // let response;
    // await axios.post(`/test/fatigue`, body, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("POST", `/test/fatigue`, body);
}

const rms = async(body) => {
    // let response;
    // await axios.post(`/test/rms`, body, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    // return (await axios.post(`${server}/test/rms`, body, headers)).data;
    return await request("POST", `/test/rms`, body);
}

const norm = async(body) => {
    // let response;
    // await axios.post(`/test/norm`, body, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("POST", `/test/norm`, body);
}

const getOtherBehavior = async(behaviorId) => {
    // let response;
    // await axios.get(`/api/log/getOtherBehavior?behaviorId=${behaviorId}`)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("GET", `/api/log/getOtherBehavior?behaviorId=${behaviorId}`);
}

const getExercise = async(id, date) => {
    // let response;
    // await axios.get(`/v2/log/get/data/exercise?mode=range&id=${id}&from=${date}&to=${date}`, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("GET", `/v2/log/get/data/exercise?mode=range&id=${id}&from=${date}&to=${date}`);
}

const getEs = async(id, date) => {
    // let response;
    // await axios.get(`/v2/log/get/data/es?mode=range&id=${id}&from=${date}&to=${date}`, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("GET", `/v2/log/get/data/es?mode=range&id=${id}&from=${date}&to=${date}`);
}

const getMq = async(id, date) => {
    // let response;
    // await axios.get(`/v2/log/get/data/mq?id=${id}&date=${date}`, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    return await request("GET", `/v2/log/get/data/mq?id=${id}&date=${date}`);
}

const getEmgList = async(id) => {
    let response;
    // await axios.get(`/v2/log/get/emgList?id=${id}`, headers)
    //     .then(({data}) => {response = data;})
    //     .catch(e => console.log(e));
    // await axios.get(`${config.springApiGateway}/${config.stage}/getEmgList?id=${id}`)
    //     .then(({data}) => {response = data})
    //     .catch(e => console.log(e));
    response = await request("GET", `/v2/log/get/emgList?id=${id}`, headers);
    return response;
}

const request = async (method, path, body, opts) => {
    let response;
    if(opts === undefined) {
        opts = { headers: { ...headers } }
    }
    else {
        opts = { headers: { ...headers, ...opts } };
    }
    // const opt = {
    //     method: "POST",
    //     headers: {"Content-Type": "application/json"}
    // };
    // body !== null && (opt.body = JSON.stringify({method, path, body}));
    // let req = new Request(`${config.springApiGateway}/${config.stage}/springAPI`, opt)
    // try {
    //     const res = await fetch(req);
    //     if (res.ok) {
    //         result = await res.json();
    //     }
    // }
    // catch{

    // }
    switch(method.toLowerCase()) {
        case "get":
            await axios.get(`${server}${path}`, opts)
                .then(({data}) => {response = data})
                .catch(e => console.log(e));
            break;
        case "post":
            await axios.post(`${server}${path}`, body, opts)
                .then(({data}) => {response = data})
                .catch(e => console.log(e));
            break;
        case "delete":
            await axios.delete(`${server}${path}`, opts)
                .then(({data}) => {response = data})
                .catch(e => console.log(e));
            break;
        default:
            break;
    }
    // await axios.post(`${config.springApiGateway}/${config.stage}/springAPI`, {method, path, body}, {headers: {"Content-Type": "application/json"}})
    //     .then(({data}) => {response = data.detail;})
    //     .catch(e => console.log(e));
    // console.log(response);
    return response;
};

export default {
    loginByPhone,
    loginByPhoneGet,
    getKakaoUserId,
    loginBySNS,
    fatigue,
    rms,
    norm,
    getOtherBehavior,
    getExercise,
    getMq,
    getEmgList,
    getEs,
    request
}