import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Spinner from "../uicomponent/Spinner";

export default props => {
  return (
    <Card body style={props.style}>
      {props.title && (
        <CardHeader>
          {props.icon} {props.title}{" "}
          {props.loading && <Spinner visible={props.loading} />}
        </CardHeader>
      )}
      <CardBody>{props.children}</CardBody>
    </Card>
  );
};
