import React from "react";
import { CardText, Button, Container, Label } from "reactstrap";
import * as d3 from "d3";
import d3tip from "d3-tip";

import MidCard from "../component/MidCard";
import restapi from "../util/restapi";

export default class TrendRehab extends React.Component {
  state = {
    loading: false,
    trend: []
  };
  term = "d";

  componentWillReceiveProps(nextProps) {
    if (["d", "w", "m"].includes(nextProps.term)) this.term = nextProps.term;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.type_sub !== this.props.type_sub ||
        prevProps.term !== this.props.term ||
        prevProps.offset !== this.props.offset)
    )
      this.fetch();
    else this.draw();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.props.user_id && this.fetch();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => this.draw();

  fetch = () => {
    this.setState({ loading: true }, async () => {
      let trend = [];
      const body = {
        user_id: this.props.user_id,
        behavior: "rehab",
        term: this.term,
        offset: this.props.offset,
        windowSize: this.props.windowSize
      };

      this.props.type_sub && (body.type_sub = this.props.type_sub);

      try {
        const res = await restapi.patchTrend(body);

        if (res && res.result) trend = res.detail;
      } catch (error) {
        console.log(error);
      }

      this.setState({ trend, loading: false });
    });
  };

  draw = () => {
    this.drawResult();
    this.drawTime();
  };

  drawResult = () => {
    const svg = d3.select("svg#trendResult");
    svg.selectAll("*").remove();

    if (this.props.user_id === null || this.state.loading === true) return;

    const [trend, term] = [this.state.trend, this.term];

    if (trend === null || !Array.isArray(trend)) return;

    let { width, height } = svg.node().getBoundingClientRect();
    const [offsetWidth, offsetHeight] = [30, 30];
    height -= offsetHeight * 2;
    width -= offsetWidth * 2;

    let fn;

    switch (term) {
      case "w":
        fn = d => `${d.yow}`;
        break;
      case "m":
        fn = d => `${d.y}/${d.m}`;
        break;
      
      default:
        fn = d => `${d.m}/${d.d}`;
        break;
    }

    const xScale = d3
      .scaleBand()
      .domain(trend.map(fn))
      .range([0, width])
      .padding(0.1);
    const yScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([height, 0]);
    const graph = svg
      .append("g")
      .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);
    const tip = d3tip()
      .attr("class", "d3-tip")
      // .offset([-10, 0])
      .html(d => (d.r === 0 ? null : `<strong>${d.r.toFixed(1)}</strong>`));

    graph.call(tip);
    graph
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));
    graph
      .append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale));
    graph
      .append("g")
      .selectAll("rect")
      .data(trend)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("height", d => height - yScale(d.r))
      .attr("width", xScale.bandwidth())
      .attr("x", d => xScale(fn(d)))
      .attr("y", d => yScale(d.r))
      .exit();
    graph
      .append("g")
      .selectAll("rect")
      .data(trend)
      .enter()
      .append("rect")
      .attr("class", "barfill")
      .attr("height", d => (d.r === 0 ? 0 : height))
      .attr("width", xScale.bandwidth())
      .attr("x", d => xScale(fn(d)))
      .attr("y", 0)
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide)
      .exit();
  };

  drawTime = () => {
    const svg = d3.select("svg#trendTime");
    svg.selectAll("*").remove();

    if (this.props.user_id === null || this.state.loading === true) return;

    const [trend, term] = [this.state.trend, this.term];

    if (trend === null || !Array.isArray(trend)) return;

    let { width, height } = svg.node().getBoundingClientRect();
    const [offsetWidth, offsetHeight] = [30, 30];
    height -= offsetHeight * 2;
    width -= offsetWidth * 2;

    let fn;

    switch (term) {
      case "w":
        fn = d => `${d.yow}`;
        break;
      case "m":
        fn = d => `${d.y}/${d.m}`;
        break;
      default:
        fn = d => `${d.m}/${d.d}`;
        break;
    }

    const xScale = d3
      .scaleBand()
      .domain(trend.map(fn))
      .range([0, width])
      .padding(0.1);
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(trend.map(item => item.play_time))])
      .range([height, 0]);
    const graph = svg
      .append("g")
      .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);
    const tip = d3tip()
      .attr("class", "d3-tip")
      .html(d => {
        if (d.play_time === 0) return null;
        const dt = new Date(d.play_time);
        return `<strong>${dt
          .getUTCHours()
          .toString()
          .padStart(2, "0")}:${dt
          .getUTCMinutes()
          .toString()
          .padStart(2, "0")}:${dt
          .getUTCSeconds()
          .toString()
          .padStart(2, "0")}</strong>`;
      });

    graph.call(tip);
    graph
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));
    graph
      .append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale));
    graph
      .append("g")
      .selectAll("rect")
      .data(trend)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("height", d =>
        d.play_time === 0 ? 0 : height - yScale(d.play_time)
      )
      .attr("width", xScale.bandwidth())
      .attr("x", d => xScale(fn(d)))
      .attr("y", d => yScale(d.play_time))
      .exit();
    graph
      .append("g")
      .selectAll("rect")
      .data(trend)
      .enter()
      .append("rect")
      .attr("class", "barfill")
      .attr("height", d => (d.play_time === 0 ? 0 : height))
      .attr("width", xScale.bandwidth())
      .attr("x", d => xScale(fn(d)))
      .attr("y", 0)
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide)
      .exit();
  };

  render() {
    let body = null;

    if (this.props.user_id === null) body = <Label>Select User</Label>;
    else {
      if (!this.state.loading) {
        body = (
          <>
            <Container>
              <Label>Success Rate</Label>
              <svg
                className="graph"
                id="trendResult"
                key={new Date().getTime()}
                height={this.height}
                width="100%"
              />
            </Container>
            <Container>
              <Label>Total Time</Label>
              <svg
                className="graph"
                id="trendTime"
                key={new Date().getTime()}
                height={this.height}
                width="100%"
              />
            </Container>
          </>
        );
      }
    }

    return <>{body}</>;
  }
}
