import React from "react";
import { CardText, Button } from "reactstrap";
import * as d3 from "d3";
import AirlineSeatLegroomExtraIcon from "@material-ui/icons/AirlineSeatLegroomExtra";
import MidCard from "../component/MidCard";
import restapi from "../util/restapi";

export default class TrendRom extends React.Component {
  romRange = { min: 0, max: 200 };
  term = "d";

  state = {
    loading: false,
    trend: []
  };

  componentWillReceiveProps(nextProps) {
    if (["d", "w", "m"].includes(nextProps.term)) this.term = nextProps.term;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.type_sub !== this.props.type_sub ||
        prevProps.term !== this.props.term ||
        prevProps.offset !== this.props.offset)
    )
      this.fetch();
    else this.draw();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.props.user_id && this.fetch();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => this.draw();

  fetch = () => {
    this.setState({ loading: true }, async () => {
      let trend = [];
      const body = {
        user_id: this.props.user_id,
        behavior: "rom",
        term: this.term,
        offset: this.props.offset,
        windowSize: this.props.windowSize
      };

      try {
        const res = await restapi.patchTrend(body);
        console.log(body, res);

        if (res && res.result) trend = res.detail;
      } catch (error) {
        console.log(error);
      }
      console.log(trend)
      this.setState({ trend, loading: false });
    });
  };

  draw = () => {
    const svg = d3.select("svg#trendRom");
    svg.selectAll("*").remove();

    if (this.props.user_id === null || this.state.loading === true) return;

    const [trend, term] = [this.state.trend, this.term];

    if (trend === null || !Array.isArray(trend)) return;

    let { width, height } = svg.node().getBoundingClientRect();
    const [offsetWidth, offsetHeight] = [25, 20];
    height -= offsetHeight * 2;
    width -= offsetWidth * 2;

    let fn;

    switch (term) {
      case "w":
        fn = d => `${d.yow}`;
        break;
      case "m":
        fn = d => `${d.y}/${d.m}`;
        break;
      case "s":
        fn = d => {
          const dt = new Date(d.time_st_utc);
          return `${dt.getMonth() + 1}/${dt.getDate()} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`;
        };
        break;
      default:
        fn = d => `${d.m}/${d.d}`;
        break;
    }

    const xScale = d3
      .scaleBand()
      .domain(trend.map(fn))
      .range([0, width])
      .padding(0.1);
    console.log(trend)
    const yScale = d3
      .scaleLinear()
      .domain([this.romRange.min, this.romRange.max])
      .range([height, 0]);
    const g = svg
      .append("g")
      .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);

    g.append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale).tickSize(-height));
    g.append("g")
      .attr("class", "grid")
      .call(
        d3
          .axisLeft(yScale)
          .ticks(5)
          .tickSize(-width)
      );

    g.selectAll("rect")
      .data(trend)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("height", d => height - yScale(d.max - d.min))
      .attr("width", xScale.bandwidth())
      .attr("x", d => xScale(fn(d)))
      .attr("y", d => yScale(d.max))
      .exit();

    const line = d3
      .line()
      .x(d => xScale(fn(d)) + xScale.bandwidth() / 2)
      .y(d => yScale(d.max - d.min));

    g.append("path")
      .datum(trend)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 0.5)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("class", "line")
      .attr("d", line);

    g.append("g")
      .selectAll("circle")
      .data(trend)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", d => xScale(fn(d)) + xScale.bandwidth() / 2)
      .attr("cy", d => yScale(d.max - d.min))
      .attr("r", 3)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 1)
      .exit();

    g.append("g")
      .selectAll("text")
      .data(trend)
      .enter()
      .append("text")
      .text(d => {
        const diff = d.max - d.min;

        if (diff > 0)
          return `${diff.toFixed(1)}${String.fromCharCode(
            parseInt("00B0", 16)
          )}`;
        else return null;
      })
      .attr("x", d => xScale(fn(d)) + xScale.bandwidth() / 2)
      .attr("y", d => yScale(d.max - d.min))
      .exit();
  };

  render() {
    let body = null;

    if (this.props.user_id === null) body = <CardText>select user</CardText>;
    else {
      if (!this.state.loading) {
        body = (
          <>
            <CardText>
              <svg
                className="graph"
                id="trendRom"
                key={new Date().getTime()}
                height={this.height}
                width="100%"
              />
            </CardText>
          </>
        );
      }
    }

    return (
      <MidCard
        icon={<AirlineSeatLegroomExtraIcon fontSize="large" color="primary" />}
        title="Trend ROM"
        loading={this.state.loading}
      >
        {body}
      </MidCard>
    );
  }
}
