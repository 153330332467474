import React, { Component } from "react";
import { CardTitle, Button } from "reactstrap";
import { InputGroup, InputGroupText, InputGroupAddon, Input } from "reactstrap";
import { Form, FormGroup, Label, Alert, Container } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import MidCard from "../component/MidCard";
import TopSpacer from "./../component/TopSpacer";

import config from "../config.json";
import util from "../util/util";

export default class SignUpMaster extends Component {
  state = {
    email: "",
    family_name: "",
    given_name: "",
    pw1: "",
    pw2: "",
    error: null,
    modal: false
  };

  handleError = error => {
    console.log(error);
    this.setState({ error });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      let msg = null;

      if (this.state.email && !util.validEmail(this.state.email))
        msg = "Email format is not correct";
      else if (this.state.pw1 || this.state.pw2) {
        if (!util.validPw(this.state.pw1)) msg = "Password is not correct";
        else if (this.state.pw1 !== this.state.pw2)
          msg = "Password is not correct";
      }

      this.setState({ error: msg });
    });
  };

  handleSignup = async () => {
    const user = Object.assign({}, this.state);

    user.master = true;
    user.birthdate = "2017-01-04";
    user.gender = "male";
    user.pw = user.pw1;
    delete user.pw1;
    delete user.pw2;
    delete user.error;
    delete user.modal;

    const reqHeader = new Headers({
      "Content-Type": "application/json"
    });
    const req = new Request(`${config.baseUrl}/${config.stage}/auth`, {
      method: "POST",
      headers: reqHeader,
      body: JSON.stringify(user)
    });

    try {
      const response = await fetch(req);

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        data.result && this.toggle();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render = () => {
    const { email, family_name, given_name, pw1, pw2 } = this.state;

    return (
      <>
        {this.props.title && <CardTitle>{this.props.title}</CardTitle>}
        <Form>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>email</InputGroupText>
            </InputGroupAddon>
            <Input
              value={email}
              name="email"
              onChange={this.handleChange}
              type="text"
              placeholder="email@example.com"
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>family_name</InputGroupText>
            </InputGroupAddon>
            <Input
              value={family_name}
              name="family_name"
              onChange={this.handleChange}
              type="text"
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>given_name</InputGroupText>
            </InputGroupAddon>
            <Input
              value={given_name}
              name="given_name"
              onChange={this.handleChange}
              type="text"
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Password</InputGroupText>
            </InputGroupAddon>
            <Input
              value={pw1}
              name="pw1"
              onChange={this.handleChange}
              type="password"
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Password Confirm</InputGroupText>
            </InputGroupAddon>
            <Input
              value={pw2}
              name="pw2"
              onChange={this.handleChange}
              type="password"
            />
          </InputGroup>
          {this.state.error && (
            <Alert color="warning" style={{ color: "red" }}>
              {this.state.error}
            </Alert>
          )}
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <Button onClick={this.handleSignup}>Join</Button>
          </div>
        </Form>
        <Modal isOpen={this.state.modal} toggle={this.toggle} centered>
          <ModalHeader toggle={this.toggle}>Success</ModalHeader>
          <ModalBody>
            Account verification email has been sent! Please check your mailbox.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };
}
