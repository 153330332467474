import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const MonthlyView = (props) => {
    const [range, setRange] = useState({
        start: null,
        end: null
    })

    useEffect(() => {
        const start = new Date();
        const end = new Date();
        start.setDate(end.getDate() - 29);
        setRange({ start: start.toISOString().slice(0, 10), end: end.toISOString().slice(0, 10) });
    }, []);

    return (
        <Plot
            data={[
                {
                    x: props.date,
                    y: props.data,
                    
                }
            ]}
            layout={{
                autosize: true,
                xaxis: {
                    range: [range.start, range.end],
                    fixedrange: true,
                    automargin: true
                },
                margin: {
                    t: 0,
                    b: 0
                },
                dragmode: false
            }}
            config={{ responsive: true, displayModeBar: false }} style={props.style}
        />
    );
};

export default MonthlyView;