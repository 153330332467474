import React, { useEffect, useState } from "react";
import {
    Button,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Input
} from "reactstrap";
import Spinner from "../uicomponent/Spinner";
import springapi from "../util/springapi";
import CloseIcon from "@material-ui/icons/Close";
import { Checkbox } from "@material-ui/core";

const customBlue = "#4444ff";

const Admin = ()  => {
    const [admin, setAdmin] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecking, setIsChecking] = useState(true);
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [sort, setSort] = useState("id");
    const [reverse, setReverse] = useState(false);
    const [gender, setGender] = useState("all");
    const [activated, setActivated] = useState("all");

    useEffect(() => {
        if(admin) {
            springapi.request("GET", "/admin/getUserList").then((res) => {
                if(res && res.response) {
                    setList(res.response);
                }
            });
        }
    }, [admin]);

    useEffect(() => {
        const id = localStorage.getItem("adminId");
        const sessionId = localStorage.getItem("adminSessionId");
        if(id && sessionId) {
            springapi.request("GET", `/admin/checkSession?id=${id}&sessionId=${sessionId}`).then((res) => {
                if(res && res.response) {
                    setAdmin(id);
                }
                else {
                    localStorage.removeItem("adminId");
                    localStorage.removeItem("adminSessionId");
                }
                setIsChecking(false);
            });
        }
        else {
            setIsChecking(false);
        }
    }, []);

    function adminSignIn() {
        let body = {
            id: document.getElementById("id").value,
            password: document.getElementById("password").value
        };
        if(body.id && body.password) {
            setIsLoading(true);
            springapi.request("POST", "/admin/login", body).then((res) => {
                if(res && res.response) {
                    localStorage.setItem("adminId", body.id);
                    localStorage.setItem("adminSessionId", res.response)
                    setAdmin(body.id);
                }
                setIsLoading(false);
            });
        }
        // (function () {
        //     var xmlHttp = new XMLHttpRequest();
        //     xmlHttp.open("HEAD", window.location.href.toString(), false);
        //     xmlHttp.send("");
        //     const start = xmlHttp.getResponseHeader("Date");
        //     let exactTime;
        //     while(true) {
        //         xmlHttp.open("HEAD", window.location.href.toString(), false);
        //         xmlHttp.send("");
        //         if(xmlHttp.getResponseHeader("Date") !== start) {
        //             exactTime = new Date(xmlHttp.getResponseHeader("Date"));
        //             break;
        //         }
        //     }
        //     console.log(exactTime);
        // })()
        
    }

    const User = ({ user }) => {
        // const [activated, setActivated] = useState(user.activated);
        const [isLoading, setIsLoading] = useState(false);

        function handleClick() {
            setIsLoading(true);
            springapi.request("GET", `/admin/setActivated?id=${user.id}&activated=${!user.activated}`).then((res) => {
                list.find((v) => v.id === user.id).activated = !user.activated;
                setList(list.slice());
                // setActivated(!activated);
                setIsLoading(false);
            })
        }

        return (
            <li style={{ padding: 20, border: "1px solid", margin: -1, backgroundColor: user.activated ? "white" : "#BBBBBB" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "10%", paddingLeft: 10 }}>{user.id}</div>
                    <div style={{ display: "flex", alignItems: "center", width: "45%", paddingLeft: 10 }}>{user.phone || user.email}</div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "15%" }}>{user.birthdate}</div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "15%" }}>{user.gender.charAt(0).toUpperCase()}</div>
                <Button style={{ width: "15%", backgroundColor: user.activated ? "gray" : customBlue }} onClick={handleClick} disabled={isLoading}>
                    {/* <Spinner visible={isLoading} style={{ float: "left" }}/> */}
                    {user.activated ? "비활성화" : "활성화"}
                </Button>
                </div>
            </li>
        );
    }

    function handleEnter(event) {
        event.key === "Enter" && adminSignIn();
    }

    function handleCheckbox() {
        setIsChecked(!isChecked);
    }

    function changeFilter(event) {
        setFilter(event.target.value);
    }

    function sortBy(category) {
        if(sort === category) {
            setReverse(!reverse);
        }
        else {
            setSort(category);
            setReverse(false);
        }
    }

    function filterByGender() {
        if(gender === "all") {
            setGender("M");
        }
        else if(gender === "M") {
            setGender("F");
        }
        else {
            setGender("all");
        }
    }

    function filterByActivated() {
        if(activated === "all") {
            setActivated(true);
        }
        else if(activated === true) {
            setActivated(false);
        }
        else {
            setActivated("all");
        }
    }

    if(isChecking) {
        return (
            <div/>
        );
    }
    if(admin) {
        return (
            <div style={{ height: "100%", overflowX: "auto" }}>
                <div style={{ height: "100%", minWidth: 1280 }}>
                    <div style={{ display: "flex", float: "right" }}>
                        <a href="/" style={{ padding: 5 }}>홈</a>
                        <a href="" style={{ padding: 5, cursor: "pointer" }} onClick={() => {
                            springapi.request("GET", `/admin/expireSession?id=${admin}&sessionId=${localStorage.getItem("adminSessionId")}`).then((res) => {
                                localStorage.removeItem("adminId");
                                localStorage.removeItem("adminSessionId");
                                setAdmin(null);
                            })
                        }}>로그아웃</a>
                    </div>
                    <div style={{ width: "60%", height: "100%", display: "flex", flexFlow: "column", transform: "translate(33%, 0)", paddingTop: 20 }}>
                        <div style={{ display: "flex" }}>
                            <InputGroup style={{ width: 250 }}>
                                <Input
                                    value={filter}
                                    placeholder="전화번호나 이메일로 검색"
                                    onChange={changeFilter}
                                    style={{ boxShadow: "none" }}
                                />
                                <InputGroupAddon addonType="append">
                                <Button
                                    style={{ border: "1px solid", borderColor: customBlue, backgroundColor: customBlue, height: "auto", padding: 0 }}
                                    onClick={() => setFilter("")}
                                    disabled={filter === ""}
                                >
                                    <CloseIcon style={{ marginTop: -4, width: 32 }}/>
                                </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            <div style={{ flexGrow: 1 }}/>
                            <Checkbox value={isChecked} onChange={handleCheckbox} style={{ color: customBlue, padding: 0 }}/>
                            <div style={{ display: "flex", alignItems: "center", paddingRight: 10 }}>통합앱 사용자</div>
                        </div>
                        <div style={{ display: "flex", padding: 20, paddingRight: 30 }}>
                            <div style={{ display: "flex", alignItems: "center", width: "10%", borderRight: "1px solid", paddingLeft: 10, cursor: "pointer" }} onClick={() => sortBy("id")}>
                                순번
                                <div style={{ width: 10, textAlign: "center" }}>{sort === "id" ? (reverse ? "↓" : "↑") : "-"}</div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "45%", borderRight: "1px solid", cursor: "pointer" }} onClick={() => sortBy("phoneOrEmail")}>
                                전화번호 / 이메일
                                <div style={{ width: 10, textAlign: "center" }}>{sort === "phoneOrEmail" ? (reverse ? "↓" : "↑") : "-"}</div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "15%", borderRight: "1px solid", paddingLeft: 10, cursor: "pointer" }} onClick={() => sortBy("birthdate")}>
                                생년월일
                                <div style={{ width: 10, textAlign: "center" }}>{sort === "birthdate" ? (reverse ? "↓" : "↑") : "-"}</div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "15%", borderRight: "1px solid", cursor: "pointer" }} onClick={filterByGender}>
                                {gender === "all" ? "성별" : (gender === "M" ? "남성" : "여성")}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "15%", cursor: "pointer" }} onClick={filterByActivated}>
                                {activated === "all" ? "활성화 여부" : (activated === true ? "활성화됨" : "비활성화됨")}
                            </div>
                        </div>
                        <ul style={{ width: "100%", height: "100%", overflowX: "hidden", overflowY: "scroll", border: "1px solid", borderRadius: 5 }}>
                            {list.filter((v) => (
                                (v.phone || v.email) &&
                                (v.phone || v.email).includes(filter) &&
                                !(isChecked && v.weight === undefined) &&
                                (gender === "all" || v.gender.charAt(0).toUpperCase() === gender) &&
                                (activated === "all" || v.activated === activated)
                            )).sort((a, b) => {
                                let aSub, bSub;
                                if(sort === "phoneOrEmail") {
                                    aSub = a.phone || a.email;
                                    bSub = b.phone || b.email;
                                }
                                else {
                                    aSub = a[sort];
                                    bSub = b[sort];
                                }
                                if(aSub > bSub) {
                                    return reverse ? -1 : 1;
                                }
                                if(aSub < bSub) {
                                    return reverse ? 1 : -1;
                                }
                                return 0;
                            }).map((v, i) => <User key={i} user={v}/>)}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div style={{ position: "absolute", top: "50%", left: "50%", border: "1px solid", borderRadius: 5, width: 400, height: 260, transform: "translate(-200px, -130px)", padding: 30 }}>
            <div style={{ display: "flex", justifyContent: "center" }}><h1>관리자 로그인</h1></div>
            <div style={{ margin: 30 }}>
                <InputGroup>
                <InputGroupAddon  addonType="prepend">
                    <InputGroupText style={{ width: "82px", justifyContent: "center" }}>아이디</InputGroupText>
                </InputGroupAddon>
                <Input
                    id="id"
                    disabled={isLoading}
                    onKeyPress={handleEnter}
                    type="text"
                    placeholder="아이디"
                />
                </InputGroup>
                <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ width: "82px", justifyContent: "center" }}>비밀번호</InputGroupText>
                </InputGroupAddon>
                <Input
                    id="password"
                    disabled={isLoading}
                    onKeyPress={handleEnter}
                    type="password"
                    placeholder="비밀번호"
                />
                </InputGroup>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button href="/" style={{ backgroundColor: "white", color: "black", marginRight: 10 }}>
                    취소
                </Button>
                <Button disabled={isLoading} onClick={adminSignIn}>
                    로그인
                    <Spinner visible={isLoading} />
                </Button>
            </div>
        </div>
    );
};

export default Admin;