import _ from "lodash";
import * as d3 from "d3";

function Rectification(data) {
    return data.map((d) => Math.abs(d));
}

const Smoothing = {
  Mean: function(data, windowSize) {
    const smoothedData = [];
    if(data.length <= windowSize) {
      smoothedData.push(_.mean(data));
    }
    else {
      let sum = _.sum(data.slice(0, windowSize));
      smoothedData.push(sum / windowSize);
      for(let i = windowSize; i < data.length; i++) {
        sum += data[i] - data[i - windowSize];
        smoothedData.push(sum / windowSize);
      }
    }
    return smoothedData;
  },
  // MeanAbsolute: function(data, windowSize) {
  //   const smoothedData = [];
  //   const count = parseInt(data.length / windowSize);
  //   let sum;
  //   for(let i = 0; i < count; i++) {
  //     sum = 0;
  //     for(let j = 0; j < windowSize; j++) {
  //       sum += Math.abs(data[i * windowSize + j]);
  //     }
  //     smoothedData.push(sum / windowSize);
  //   }
  //   const remain = data.length - count * windowSize;
  //   if(remain !== 0) {
  //     sum = 0;
  //     for(let j = 0; j < remain; j++) {
  //       sum += Math.abs(data[count * windowSize + j]);
  //     }
  //     smoothedData.push(sum / remain);
  //   }
  //   return smoothedData;
  // },
  MeanAbsolute: function(data, windowSize) {
    const smoothedData = [];
    if(data.length <= windowSize) {
      smoothedData.push(_.mean(data.map((v) => Math.abs(v))));
    }
    else {
      let sum = _.sum(data.slice(0, windowSize).map((v) => Math.abs(v)));
      smoothedData.push(sum / windowSize);
      for(let i = windowSize; i < data.length; i++) {
        sum += Math.abs(data[i]) - Math.abs(data[i - windowSize]);
        smoothedData.push(sum / windowSize);
      }
    }
    return smoothedData;
  },
  // RMS: function(data, windowSize) {
  //   const smoothedData = [];
  //   const count = parseInt(data.length / windowSize);
  //   let sum;
  //   for(let i = 0; i < count; i++) {
  //     sum = 0;
  //     for(let j = 0; j < windowSize; j++) {
  //       sum += Math.pow(data[i * windowSize + j], 2);
  //     }
  //     smoothedData.push(Math.sqrt(sum / windowSize));
  //   }
  //   const remain = data.length - count * windowSize;
  //   if(remain !== 0) {
  //     sum = 0;
  //     for(let j = 0; j < remain; j++) {
  //       sum += Math.pow(data[count * windowSize + j], 2);
  //     }
  //     smoothedData.push(Math.sqrt(sum / remain));
  //   }
  //   return smoothedData;
  // },
  RMS: function(data, windowSize) {
    const smoothedData = [];
    if(data.length <= windowSize) {
      smoothedData.push(Math.sqrt(_.mean(data.map((v) => Math.pow(v, 2)))));
    }
    else {
      let sum = _.sum(data.slice(0, windowSize).map((v) => Math.pow(v, 2)));
      smoothedData.push(Math.sqrt(sum / windowSize));
      for(let i = windowSize; i < data.length; i++) {
        sum += Math.pow(data[i], 2) - Math.pow(data[i - windowSize], 2);
        if(sum < 0) {
          sum = 0;
        }
        smoothedData.push(Math.sqrt(sum / windowSize));
      }
    }
    return smoothedData;
  }
}

const Normalization = {
  ManualValue: function(data, value) {
    return data.map((v) => v / value * 100);
  },
  MeanValue: function(data) {
    const mean = Math.abs(_.mean(data));
    return data.map((v) => v / mean * 100);
  },
  PeakValue: function(data, windowSize) {
    let value;
    if(data.length <= windowSize) {
      value = _.mean(data);
    }
    else {
      let sum = _.sum(data.slice(0, windowSize));
      let max = sum;
      let abs;
      for(let i = windowSize; i < data.length; i++) {
        sum += data[i] - data[i - windowSize];
        abs = Math.abs(sum);
        max = abs > max ? abs : max;
      }
      value = max / windowSize;
    }
    return data.map((v) => v / value * 100);
  }
}

const Filtering = {
  Median: function(data, windowSize) {
    const filteredData = [];
    for(let i = 0; i < data.length - windowSize + 1; i++) {
      filteredData.push(d3.median(data.slice(i, i + windowSize)));
    }
    return filteredData;
  }
}

function Integral(data) {
  const processedData = [];
  let sum = 0;
  data.forEach((v) => {
    sum += v;
    processedData.push(sum / 1000);
  });
  return processedData;
}

function Derivative(data) {
  const processedData = [data[0]];
  for(let i = 1; i < data.length; i++) {
    processedData.push((data[i] - data[i - 1]) * 1000);
  }
  return processedData;
}

export default {
  Rectification,
  Smoothing,
  Normalization,
  Filtering,
  Integral,
  Derivative
}