import React, { useEffect, useState } from "react";
import springapi from "../util/springapi";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Switch, FormControlLabel } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles"
import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  ListGroup,
} from "reactstrap";
import Spinner from "../uicomponent/Spinner";

const customBlue = "#4444ff";

const Notifications = ({patient}) => {
  const [notifications, setNotifications] = useState({
    pausedPrescriptions: [],
    notifications: []
  });
  const [isLoading, setIsLoading] = useState(false);

  async function fetchList() {
    setIsLoading(true);
    const res = await springapi.request("GET", `/dtx/get/notifications?patientId=${patient.id}`);
    if(res.response) {
      setNotifications(res.response);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if(patient.id) {
      fetchList();
    }
  }, [patient]);

  useEffect(() => {
    if(notifications.pausedPrescriptions.length === 0 && notifications.notifications.length === 0) {
      patient.abnormality = false;
    }
    else {
      patient.abnormality = true;
    }
  }, [notifications])

  async function deleteNotification(notificationId) {
    setIsLoading(true);
    const res = await springapi.request("DELETE", `/dtx/delete/notification?patientId=${patient.id}&notificationId=${notificationId}`);
    if(res.response) {
      setNotifications({ ...notifications, notifications: res.response });
    }
    setIsLoading(false);
  }

  async function unpausePrescription(prescriptionId, lowerLevel) {
    setIsLoading(true);
    const res = await springapi.request("POST", `/dtx/web/unpausePrescription`, { patientId: patient.id, prescriptionId, lowerLevel });
    if(res.response) {
      setNotifications({ ...notifications, pausedPrescriptions: res.response });
    }
    setIsLoading(false);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: 240, padding: "10px 15px 15px 15px", borderRadius: 5, backgroundColor: "white", border: "1px solid #CED4DA" }}>
      <div style={{ display: "flex", height: 33.5, flexShrink: 0, alignItems: "center" }}><b>특이사항</b><Spinner visible={isLoading} style={{ marginLeft: 5 }}/></div>
      <ListGroup style={{ flex: "1 0 0", overflowY: "auto", borderRadius: 5, border: "1px solid" }}>
        {notifications.pausedPrescriptions.map((v, i) => {
          return (
            <li key={i} style={{ display: "flex", padding: 10, borderBottom: "1px solid black" }}>
              <div style={{ display: "flex", flex: "1 0 0", flexDirection: "column", gap: 4 }}>
                <div><b>중단된 처방</b></div>
                <div>{`처방 이름: ${v.name}`}</div>
                <div>{`처방 코드: ${v.code}`}</div>
                <div>{`운동 난이도: ${v.level}`}</div>
              </div>
              <div style={{ display: "flex", flexShrink: 0, justifyContent: "center", alignItems: "center", padding: 10, gap: 10 }}>
                <Button style={{ borderColor: "gray", backgroundColor: "gray" }} disabled={isLoading || v.level === 1}
                  onClick={async () => unpausePrescription(v.prescriptionId, true)}>난이도 내림</Button>
                <Button style={{ borderColor: customBlue, backgroundColor: customBlue }} disabled={isLoading}
                  onClick={async () => unpausePrescription(v.prescriptionId, false)}>난이도 유지</Button>
              </div>
            </li>
          );
        })}
        {notifications.notifications.map((v, i) => {
          return (
            <li key={i} style={{ display: "flex",  padding: 10, borderBottom: "1px solid black" }}>
              <div style={{ display: "flex", flex: "1 0 0", flexDirection: "column", gap: 4 }}>
                <div><b>알림</b></div>
                <div>{`발생일: ${v.date}`}</div>
                <div>{`내용: ${v.text}`}</div>
              </div>
              <div style={{ display: "flex", flexShrink: 0, justifyContent: "center", alignItems: "center", padding: 10 }}>
                <Button style={{ borderColor: customBlue, backgroundColor: customBlue }} disabled={isLoading}
                  onClick={async () => deleteNotification(v.notificationId)}>확인</Button>
              </div>
            </li>
          );
        })}
      </ListGroup>
    </div>
  );
}

export default Notifications;