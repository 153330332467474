import springapi from "../util/springapi";

var DtxUser = (() => {
    var getInfo = async() => {
        const id = localStorage.getItem("dtxId");
        const sessionId = localStorage.getItem("dtxSessionId");
        if(id === null) {
            return null;
        }
        try {
            const res = await springapi.request("GET", `/dtx/web/checkSession?id=${id}&sessionId=${sessionId}`);
            if(res) {
                if(res.response) {
                    return res.response;
                }
                localStorage.removeItem("dtxId");
                localStorage.removeItem("dtxSessionId");
            }
        }
        catch(e) {
            console.error(e);
        }
        return null;
    };

    var signIn = (id, sessionId) => {
        localStorage.setItem("dtxId", id);
        localStorage.setItem("dtxSessionId", sessionId);
    };

    async function signOut() {
        const id = localStorage.getItem("dtxId");
        const sessionId = localStorage.getItem("dtxSessionId");
        try {
            const res = await springapi.request("GET", `/dtx/web/expireSession?id=${id}&sessionId=${sessionId}`);
        }
        catch(e) {
            console.error(e);
        }
        localStorage.removeItem("dtxId");
        localStorage.removeItem("dtxSessionId");
    }

    return {
        getInfo,
        signIn,
        signOut
    };
})();

export default DtxUser;