import React, { Component } from "react";
import Userlist from "./Userlist3";
import UserDetail from "./UserDetail";
import {
  TrendRehab,
  TrendRom,
  TrendLastRomSingle,
  TrendLastDayRehabSingle,
  TrendRehabOneDay,
  TrendFatigue,
  TrendFesOneDay,
  TrendRehabAll
} from "../trend";
import ExoCalendar from "./ExoCalendar";
import Treatment from "./Treatment";
import { CardDeck, Button } from "reactstrap";
// import RmsView from "./GraphRms";
// import RawView from "./GraphRaw";
// import NormView from "./GraphNorm";
import EmgView from "./EmgGraph";
import auth from "../util/auth";
// import MqView from "./MqView";

import Drawer from "@material-ui/core/Drawer";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
// import Divider from "@material-ui/core/Divider";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import MailIcon from "@material-ui/icons/Mail";
// import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TopSpacer from "./TopSpacer";

const drawerWidth = 350;
const style = {
  LeftRight: { marginLeft: "20px", marginRight: "20px" }
};
class PrintBody extends Component {
  render = () => <div>{this.props.children}</div>;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default class V1Home extends Component {
  state = {
    loading: false,
    user_id: null,
    master: false,
    date: new Date(),
    behavior_id: null,
    selectedTab: 0,
    term: "d",
    offset: 0
  };

  componentDidMount() {
    this.setState({ loading: true }, async () => {
      const { master, user_id } = await auth.getUserInfo();
      const admin = await auth.isAdmin();

      this.setState({ master: master, admin: admin, loading: false }, () => {
        if (master === false) this.setState({ user_id: user_id });
      });
    });
  }

  handleUserChange = event => {
    const uid = Number(event);
    !Number.isNaN(uid) &&
      this.setState({ user_id: uid, date: new Date(), behavior_id: null });
  };

  handleDateChange = value => {
    this.setState({ date: value, behavior_id: null });
  };

  handleBehavior = behavior_id => {
    this.setState({ behavior_id });
  };

  handleSelectChange = (event, index) => {
    this.setState({ selectedTab: index });
  };

  handlePrev = () => {
    this.setState({ offset: this.state.offset + 5 });
  };

  handleNext = () => {
    this.setState({ offset: Math.max(this.state.offset - 5, 0) });
  };

  handleTerm = event => {
    this.setState({ term: event.target.dataset.term, offset: 0 });
  };

  a11yProps = index => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
  };

  render = () => {
    if (this.state.loading === true) return "loading...";

    const {
      user_id,
      master,
      date,
      admin,
      selectedTab,
      term,
      offset
    } = this.state;
    
    return (
      <div style={{ display: "flex" }}>
        <Drawer
          variant="permanent"
          style={{
            width: drawerWidth,
            flexShrink: 0
          }}
          PaperProps={{ style: { width: drawerWidth, top: 64, bottom: 0, height: "auto" } }}
          anchor="left"
        >
          {(master || admin) && (
            <Userlist admin={admin} onChangeUser={this.handleUserChange} />
          )}
          <UserDetail user_id={user_id}></UserDetail>
        </Drawer>
        <div style={{ flexGrow: 1, padding: 3 }}>
          <PrintBody ref={el => this.props.onRef(el)}>
            <TopSpacer />
            <Paper>
              <Tabs
                value={selectedTab}
                onChange={this.handleSelectChange}
                indicatorColor="primary"
                variant="fullWidth"
              >
                <Tab label="Detail Info" {...this.a11yProps(0)} />
                <Tab label="Trend Check" {...this.a11yProps(1)} />
              </Tabs>
            </Paper>
            <TabPanel value={selectedTab} index={0}>
              <CardDeck>
                <ExoCalendar
                  user_id={user_id}
                  onDateChange={this.handleDateChange}
                />
                <TrendRehabOneDay
                  user_id={user_id}
                  date={date}
                  onChangeBehavior={this.handleBehavior}
                />
              </CardDeck>
              
              {this.state.behavior_id && (
                <>
                  <EmgView behavior_id={this.state.behavior_id}></EmgView>
                  {/* <RawView behavior_id={this.state.behavior_id}></RawView> */}
                  {/* <RmsView behavior_id={this.state.behavior_id}></RmsView>
                  <NormView behavior_id={this.state.behavior_id}></NormView> */}
                </>
              )}
              {/* {this.state.user_id == 738 &&
              <MqView/>
              } */}
              <TrendFesOneDay
                user_id={user_id}
                date={date}
                // onChangeBehavior={this.handleBehavior}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Button style={style.LeftRight} onClick={this.handlePrev}>
                  {"<"}
                </Button>
                <Button data-term="s" onClick={this.handleTerm}>
                  single
                </Button>
                <Button data-term="d" onClick={this.handleTerm}>
                  day
                </Button>
                <Button data-term="w" onClick={this.handleTerm}>
                  week
                </Button>
                <Button data-term="m" onClick={this.handleTerm}>
                  month
                </Button>
                <Button style={style.LeftRight} onClick={this.handleNext}>
                  {">"}
                </Button>
              </div>
              {term !== "s" &&
                <TrendRom
                  user_id={user_id}
                  windowSize={5}
                  term={term}
                  offset={offset}
                />
              }
              <TrendRehabAll user_id={user_id} term={term} offset={offset} />
              <Treatment user_id={user_id} />
            </TabPanel>
          </PrintBody>
        </div>
      </div>
    );
  };
}
