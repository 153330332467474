import React, { useEffect, useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col,
  CardTitle,
  Input,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import util from "../util/util";
import GroupIcon from "@material-ui/icons/Group";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LocationIcon from "@material-ui/icons/LocationOn";
import MapIcon from "@material-ui/icons/Map";
import mobiscroll from "@mobiscroll/react-lite";
import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";
import springapi from "../util/springapi";
import Spinner from "../uicomponent/Spinner";
import { GlobalContext, useGlobal } from "./context/global";

const MASTER_GROUP_ID = 9998;

mobiscroll.settings = {
  theme: "ios"
}

function V2UserList(props) {
  const [id, setId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState("");
  const [groupUserFilter, setGroupUserFilter] = useState("");
  const [view, setView] = useState("individual");
  const { mapOpened, setMapOpened } = useGlobal();

  async function fetch() {
    setLoading(true);
    try {
      // const { user_id } = this.props.admin ? {} : await auth.getUserInfo();
      const res = await springapi.request("GET", "/v2/user/getUserList", null, { Authorization: `Bearer ${localStorage.getItem("sessionId")}` });
      if(res) {
        setUsers(res.response.users);
        setManagers(res.response.managers);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  async function getGroupUserList() {
    setLoading(true);
    try {
      // const { user_id } = this.props.admin ? {} : await auth.getUserInfo();
      const res = await springapi.request("GET", `/v2/user/getGroupUserList?groupId=${groupId}`, null, { Authorization: `Bearer ${localStorage.getItem("sessionId")}` });
      if(res) {
        setGroupUsers(res.response);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if(groupId) {
      getGroupUserList();
    }
  }, [groupId]);

  const handleClickUser = event => {
    const userId = Number(event.currentTarget.dataset.key);
    setId(userId);
    props.onChangeUser && props.onChangeUser(userId);
  };

  const handleChangeFilter = event => {
    setFilter(event.target.value);
  };

  const handleClear = () => {
    setFilter("");
  };

  const changeView = (event) => {
    setView(event.currentTarget.value);
  }

  const changeGroupFilter = (event) => {
    setGroupFilter(event.target.value);
  }

  const clearGroupFilter = () => {
    setGroupFilter("");
  }

  const changeGroupUserFilter = (event) => {
    setGroupUserFilter(event.target.value);
  }

  const clearGroupUserFilter = () => {
    setGroupUserFilter("");
  }

  // setMapOpened = (bool) => {
  //   this.setState({ mapOpened: bool });
  // }

    return (
      <div style={{ border: "1px solid gray", display: "flex", flexDirection:"column", margin: "3px 0 0 3px", width: 280, height: "calc(100% - 70px)" }}>
        <div style={{ height: 34, display: "flex", flexShrink: 0, alignItems: "center", padding: "2px 10px 2px 10px", marginBottom: -1 }}>
          <GroupIcon style={{ marginRight: 2 }} color="primary"/>
          <div style={{ marginRight: 5 }}>User List</div>
          <Spinner visible={loading}/>
          <div style={{ flexGrow: 1 }}/>
          <Button color="primary" style={{ width: 120, height: 24, padding: 0 }} onClick={() => setMapOpened(true)}><MapIcon style={{ width: 18, height: 18, marginTop: -3, marginRight: 3 }}/>User Location</Button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: 200, margin: "5px 0 10px" }}>
            <mobiscroll.Segmented name="view" value="individual" checked={view === 'individual'} onChange={changeView} color="primary">
                Individual
            </mobiscroll.Segmented>
            <mobiscroll.Segmented name="view" value="group" checked={view === 'group'} onChange={changeView} color="primary">
                Group
            </mobiscroll.Segmented>
          </div>
        </div>
        
        {view === "individual" &&
          <>
            <InputGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Input
                value={filter}
                placeholder="Search"
                onChange={handleChangeFilter}
                disabled={!users || users.length === 0}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  onClick={handleClear}
                  disabled={!filter}
                >
                  <HighlightOffIcon style={{ height: 18, marginTop: -4, width: 16 }} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <ListGroup style={{ flexGrow: 1, overflow: "auto", margin: 10, backgroundColor: "white", borderRadius: 5 }}>
              {users.filter(
                item =>
                  (item.name && item.name.includes(filter)) ||
                  (item.userKey && item.userKey.includes(filter))
                  // (item.family_name && item.family_name.includes(filter)) ||
                  // (item.given_name && item.given_name.includes(filter)) ||
                  // (item.middle_name && item.middle_name.includes(filter))
              )
              .map(item => (
                <ListGroupItem
                  key={item.id}
                  active={id === item.id}
                  tag="button"
                  data-key={item.id}
                  onClick={handleClickUser}
                  action
                >
                  <Container>
                    <Row>
                      <Col xs="12">{item.name}</Col>
                      {/* <Col xs="6">{util.convDt2SimpleDate(item.regDate)}</Col> */}
                    </Row>
                    <Row>
                      <Col xs="12">{item.userKey}</Col>
                      {/* <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col> */}
                      {/* <Col xs="2">{item.gender.toUpperCase()}</Col> */}
                      {/* <Col xs="4">{item.weight && item.weight + "kg"}</Col> */}
                    </Row>
                  </Container>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        }
        {view === "group" &&
          (groupId === null ?
            <>
              <InputGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Input
                  value={groupFilter}
                  placeholder="Search"
                  onChange={changeGroupFilter}
                  disabled={!managers || managers.length === 0}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    onClick={clearGroupFilter}
                    disabled={!groupFilter}
                  >
                    <HighlightOffIcon style={{ height: 18, marginTop: -4, width: 16 }} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <ListGroup style={{ flexGrow: 1, overflow: "auto", margin: 10, backgroundColor: "white", borderRadius: 5 }}>
                {!loading && managers.filter(
                  item =>
                    (item.name && item.name.includes(groupFilter))
                    // (item.family_name && item.family_name.includes(filter)) ||
                    // (item.given_name && item.given_name.includes(filter)) ||
                    // (item.middle_name && item.middle_name.includes(filter))
                )
                .map(item => (
                  <ListGroupItem
                    key={item.id}
                    active={id === item.id}
                    tag="button"
                    data-key={item.id}
                    onClick={() => setGroupId(item.id)}
                    action
                  >
                    <Container>
                      <Row>
                        <Col xs="12">{item.name} ({item.count})</Col>
                        {/* <Col xs="6">{util.convDt2SimpleDate(item.regDate)}</Col> */}
                      </Row>
                      <Row>
                        {/* <Col xs="12">{item.userKey}</Col> */}
                        {/* <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col> */}
                        {/* <Col xs="2">{item.gender.toUpperCase()}</Col> */}
                        {/* <Col xs="4">{item.weight && item.weight + "kg"}</Col> */}
                      </Row>
                    </Container>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          :
            <>
              <InputGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Input
                  value={groupUserFilter}
                  placeholder="Search"
                  onChange={changeGroupUserFilter}
                  disabled={!groupUsers || groupUsers.length === 0}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    onClick={clearGroupUserFilter}
                    disabled={!groupUserFilter}
                  >
                    <HighlightOffIcon style={{ height: 18, marginTop: -4, width: 16 }} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <ListGroup style={{ flexGrow: 1, overflow: "auto", margin: 10, backgroundColor: "white", borderRadius: 5 }}>
                <ListGroupItem
                  tag="button"
                  onClick={() => {
                    setGroupId(null);
                    setGroupUsers([]);
                    setGroupUserFilter("");
                  }}
                >
                  Back
                </ListGroupItem>
                {!loading && groupUsers.filter(
                  item =>
                    (item.memo && item.memo.includes(groupUserFilter))
                    // (item.family_name && item.family_name.includes(filter)) ||
                    // (item.given_name && item.given_name.includes(filter)) ||
                    // (item.middle_name && item.middle_name.includes(filter))
                )
                .map(item => (
                  <ListGroupItem
                    key={item.userId}
                    active={id === item.userId}
                    tag="button"
                    data-key={item.userId}
                    onClick={handleClickUser}
                    action
                  >
                    <Container>
                      <Row>
                        <Col xs="12">{item.memo}</Col>
                        {/* <Col xs="6">{util.convDt2SimpleDate(item.regDate)}</Col> */}
                      </Row>
                      <Row>
                        {/* <Col xs="12">{item.userKey}</Col> */}
                        {/* <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col> */}
                        {/* <Col xs="2">{item.gender.toUpperCase()}</Col> */}
                        {/* <Col xs="4">{item.weight && item.weight + "kg"}</Col> */}
                      </Row>
                    </Container>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          )
        }
      </div>
    );
  };

export default V2UserList;