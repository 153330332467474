import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Spinner from "../uicomponent/Spinner";

export default props => {
  return (
    <Card body style={props.style}>
      {/* {props.title && (
        <CardHeader> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", marginRight: 5 }}>{props.icon} <h5>{props.title}</h5></div>
          {props.loading && <Spinner visible={props.loading} color="primary"/>}
        </div>
        <hr style={{ marginLeft: -17.5, marginRight: -17.5 }}/>
        {/* </CardHeader>
      )} */}
      <div style={{ height: "100%" }}>{props.children}</div>
    </Card>
  );
};
