import React from "react";
import MidCard from "../component/MidCard";
import {
  CardText,
  Progress,
  Alert,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import GraphTime from "../graph/PieRehabTime";
import restapi from "../util/restapi";
import util from "../util/util";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import { Chip } from "@material-ui/core";
import { map } from "d3";

const mapRehabTypesub = new Map();
mapRehabTypesub.set("rehab_q_set", "Q-set");
mapRehabTypesub.set("rehab_q_set_fes", "Q-set (with Electrical Stimulation)");
mapRehabTypesub.set("rehab_terminal_ext", "Short Arc Quad");
mapRehabTypesub.set("rehab_slr_open", "SLR (With Straight Knee)");
mapRehabTypesub.set("rehab_slr_close", "SLR (With Bent Knee)");
mapRehabTypesub.set("rehab_knee_squat", "Partial Squat with Chair");
mapRehabTypesub.set("rehab_heel_lift", "Heel Raise");
mapRehabTypesub.set("rehab_ankle_pump", "Ankle Pump");
mapRehabTypesub.set("other", "Other");
// mapRehabTypesub.set("none", "Other");

const OTHER_COLOR = "lightpink";
const OTHER_TEXT = "Other";

export default class TrendRehabOneDay extends React.Component {
  _colors = new Map();

  state = {
    loading: false,
    behavior_id: null,
    data: []
  };

  constructor(props) {
    super(props);
    this._colors.set("rehab_q_set", "indianred");
    this._colors.set("rehab_q_set_fes", "orange");
    // this._colors.set("rehab_weight_shift", "secondary");
    this._colors.set("rehab_terminal_ext", "yellow");
    this._colors.set("rehab_slr_open", "lightgreen");
    this._colors.set("rehab_slr_close", "skyblue");
    this._colors.set("rehab_knee_squat", "royalblue");
    this._colors.set("rehab_heel_lift", "blueviolet");
    this._colors.set("rehab_ankle_pump", "violet");
    this._colors.set("other", "lightpink");
    // this._colors.set("none", "lightpink");
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.date !== this.props.date) &&
      this.fetch();
  };

  componentDidMount = () => {
    this.props.user_id && this.fetch();
  };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const dt = this.props.date;
      const body = {
        user_id: this.props.user_id,
        behavior: "rehab",
        term: "od",
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        date: dt.getDate(),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      let data = [];

      try {
        const res = await restapi.patchTrend(body);
        if (res && res.result) data = res.detail;
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false, data });
    });
  };

  handleClickRehab = event => {
    let behavior_id = event.currentTarget.dataset.key;
    behavior_id && (behavior_id = Number(behavior_id));
    if (this.state.behavior_id === behavior_id) behavior_id = null;
    this.setState({ behavior_id }, () => {
      this.props.onChangeBehavior && this.props.onChangeBehavior(behavior_id);
    });
  };

  render = () => {
    let body = null;
    const { data } = this.state;
    let totalTime = 0;
    let totalResult = 0;
    const groups = data.reduce((acc, item) => {
      !mapRehabTypesub.get(item.type_sub) && (item.type_sub = "other");
      totalTime += item.play_time;
      totalResult += item.results;
      let val = acc.get(item.type_sub);
      val == null && (val = 0);
      acc.set(item.type_sub, val + item.play_time);
      return acc;
    }, new Map());
    const dt = new Date(totalTime);
    const play_time = (
      <>
        <CardText>
          Play time{" "}
          {`${dt
            .getUTCHours()
            .toString()
            .padStart(2, "0")}:${dt
            .getUTCMinutes()
            .toString()
            .padStart(2, "0")}:${dt
            .getUTCSeconds()
            .toString()
            .padStart(2, "0")}`}
        </CardText>
        <Progress multi>
          {Array.from(groups, val => {
            return (
              <Progress
                bar
                key={val[0]}
                value={val[1]}
                max={totalTime}
                color={this._colors.get(val[0])}
              />
            );
          })}
        </Progress>
      </>
    );

    if (this.props.user_id == null) body = <CardText>select user</CardText>;
    else {
      if (!this.state.loading) {
        if (data == null || data.length === 0) {
          body = <Alert color="warning">no data</Alert>;
        } else {
          body = (
            <>
              <Row>
                <Col xs="6">
                  <GraphTime svgId="rehabTime" data={this.state.data}></GraphTime>
                </Col>
                <Col xs="6">
                  <div>
                    <CardText>
                      Result average {(totalResult / data.length).toFixed(1)}%
                    </CardText>
                    <Progress
                      animated
                      value={totalResult / data.length}
                    ></Progress>
                    <div style={{ height: 20 }}></div>
                    {play_time}
                    <div style={{ height: 20 }}></div>
                    {Array.from(mapRehabTypesub.keys()).map((item) => (
                      <Chip
                        key={item}
                        label={mapRehabTypesub.get(item)}
                        style={{ backgroundColor: this._colors.get(item), color: "black", height: 12}}
                      />
                    ))}
                    {/* <Chip
                      key="other"
                      label={OTHER_TEXT}
                      style={{ backgroundColor: OTHER_COLOR, color: "black", height: 12 }}
                    /> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <ListGroup className="rehablist">
                    {/* <ListGroupItem>{play_time}</ListGroupItem> */}
                    {data.map((item, idx) => {
                      const [dtSt, dtEd] = [
                        new Date(item.time_st),
                        new Date(item.time_ed)
                      ];
                      const dt = new Date(item.play_time);
                      return (
                        <ListGroupItem
                          key={idx}
                          active={this.state.behavior_id === item.behavior_id}
                          tag="button"
                          data-key={item.behavior_id}
                          onClick={this.handleClickRehab}
                          action
                        >
                          <Row>
                            <Col xs="2"></Col>
                            <Col xs="auto">
                              {mapRehabTypesub.get(item.type_sub)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="2">
                              <Progress
                                color={this._colors.get(item.type_sub) ? this._colors.get(item.type_sub) : OTHER_COLOR}
                                value="100"
                              ></Progress>
                            </Col>
                            <Col xs="5">{dtSt.toLocaleTimeString()}</Col>
                            <Col xs="5">{dtEd.toLocaleTimeString()}</Col>
                          </Row>
                          <Row>
                            <Col xs="2"></Col>
                            <Col xs="5">{util.convDt2SimpleTime(dt)}</Col>
                            <Col xs="5">{item.results.toFixed(1)}%</Col>
                          </Row>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>
            </>
          );
        }
      }
    }
    return (
      <MidCard
        icon={<AccessibilityNewIcon fontSize="large" color="primary" />}
        title="Rehab"
        loading={this.state.loading}
        style= {{ marginLeft: 0 }}
      >
        {body}
      </MidCard>
    );
  };
}
