import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useContextMenu } from "./ContextMenuContext";
import RightIcon from "@material-ui/icons/ArrowRight";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import DashIcon from "@material-ui/icons/Remove";
import _ from "lodash";

export default function ModuleData({ moduleDataList, moduleNumber, selection, setSelection, showModuleDataContext, openData, rerender }) {
  const inputFile = useRef(null);
  const { setPosition, setContent } = useContextMenu();

  /**
   * 
   * @param {EventTarget & HTMLInputElement} eventTarget
   */
  async function readFiles(eventTarget) {
    const files = eventTarget.files;
    if(files) {
      let text, name, lines;
      for(let i = 0; i < files.length; i++) {
        const x = [], y = [];
        text = await files[i].text();
        name = files[i].name;
        lines = text.split("\n");
        const [xUnit, yUnit] = lines[0].split(",");
        for(let j = 1; j < lines.length; j++) {
          const [xString, yString] = lines[j].split(",");
          x.push(parseFloat(xString));
          y.push(parseFloat(yString));
        }
        moduleDataList.push({ id: _.uniqueId(), moduleNumber: moduleNumber, name: name.substring(0, name.lastIndexOf(".")), x, y, xUnit, yUnit, expand: true, children: [] });
      }
      eventTarget.value = null;
      rerender();
    }
  }

  function TreeComponent({ data }) {
    return (
      <div style={{ width: "100%", zIndex: 5 }}
        onClick={(e) => {
          e.stopPropagation();
          if(e.detail === 1) {
            if(e.ctrlKey) {
              setSelection((s) => {
                if(s.includes(data)) {
                  s.splice(s.indexOf(data), 1);
                  return [...s];
                }
                return [...s, data];
              });
            }
            else {
              setSelection(() => [data]);
            }
          }
          else {
            openData(data);
          }
          setContent(null);
        }}
        onContextMenu={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if(!selection.includes(data)) {
            setSelection(() => [data]);
          }
          setPosition({ left: e.clientX, top: e.clientY });
          showModuleDataContext();
        }}
      >
        <div style={{ display: "flex", backgroundColor: selection.includes(data) ? "#77ccff" : undefined }}>
          {_.range(data.depth).map((v) => <div key={v} style={{ width: 10, flexShrink: 0, borderRight: "1px solid lightgray" }}/>)}
          <div style={{ display: "flex", flexShrink: 0, justifyContent: "center", alignItems: "center", width: 20, zIndex: 10 }} onClick={(e) => {
            if(data.children.length > 0) {
              data.expand = !data.expand;
              rerender();
            }
          }}>
            {data.children.length > 0 ?
              data.expand ?
                <DownIcon/>
              :
                <RightIcon/>
            :
              <DashIcon style={{ width: 16 }}/>
            }
          </div>
          <div className="unselectable" style={{ paddingRight: 10, }}>{data.name}</div>
        </div>
        {data.expand && data.children.length > 0 &&
          <div>
            {data.children.map((v, i) => {
              return (
                <TreeComponent key={i} data={v}/>
              );
            })}
          </div>
        }
      </div>
    );
  }

  return (
    <div key={moduleNumber} className="unselectable" style={{ height: `${100 / 4}%`, paddingTop: 5 }}>
      <div style={{ display: "flex" }}>
        <b>Module {moduleNumber}</b>
        <div style={{ flexGrow: 1 }}/>
        <div style={{ marginLeft: 10, cursor: "pointer", border: "1px solid black", paddingLeft: 5, paddingRight: 5, marginBottom: -1 }} onClick={(e) => inputFile.current.click()}>+ Add Data</div>
        <input type="file" accept=".csv" ref={inputFile} style={{ display: "none" }} multiple onChange={async (e) => await readFiles(e.target)}/>
      </div>
      <div style={{ width: "100%", height: "calc(100% - 21px)", border: "1px solid", overflow: "auto" }}>
        <div style={{ width: "fit-content", minWidth: 286 }}>
          {moduleDataList.map((data, j) => {
            return (
              <TreeComponent key={j} data={data}/>
            );
          })}
        </div>
      </div>
    </div>
  );
}