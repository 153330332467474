import React, { useEffect, useState } from "react";
import {
  CardText,
  Progress,
  Alert,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button
} from "reactstrap";
import springapi from "../util/springapi";
import MemoComponent from "./MemoComponent";

const mapRehabTypesub = new Map();
mapRehabTypesub.set("rehab_q_set", "Q-set");
mapRehabTypesub.set("rehab_q_set_fes", "Q-set (with Electrical Stimulation)");
mapRehabTypesub.set("rehab_terminal_ext", "Short Arc Quad");
mapRehabTypesub.set("rehab_slr_open", "SLR (With Straight Knee)");
mapRehabTypesub.set("rehab_slr_close", "SLR (With Bent Knee)");
mapRehabTypesub.set("rehab_knee_squat", "Partial Squat with Chair");
mapRehabTypesub.set("rehab_heel_lift", "Heel Raise");
mapRehabTypesub.set("rehab_ankle_pump", "Ankle Pump");
mapRehabTypesub.set("other", "Other");
// mapRehabTypesub.set("none", "Other");

const OTHER_COLOR = "lightpink";
const OTHER_TEXT = "Other";

export default class V2ExerciseList extends React.Component {
  _colors = new Map();

  state = {
    loading: false,
    behavior_id: null,
    data: [],
    memo: 0
  };

  constructor(props) {
    super(props);
    this._colors.set("rehab_q_set", "indianred");
    this._colors.set("rehab_q_set_fes", "orange");
    // this._colors.set("rehab_weight_shift", "secondary");
    this._colors.set("rehab_terminal_ext", "yellow");
    this._colors.set("rehab_slr_open", "lightgreen");
    this._colors.set("rehab_slr_close", "skyblue");
    this._colors.set("rehab_knee_squat", "royalblue");
    this._colors.set("rehab_heel_lift", "blueviolet");
    this._colors.set("rehab_ankle_pump", "violet");
    this._colors.set("other", "lightpink");
    // this._colors.set("none", "lightpink");
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.date !== this.props.date) &&
      this.fetch();
    if(this.state.data !== prevState.data) {
      this.setState({ memo: 0 });
    }
  };

  componentDidMount = () => {
    this.props.user_id && this.fetch();
    this.setState({ behavior_id: this.props.behavior_id });
  };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const dt = this.props.date;
      const body = {
        user_id: this.props.user_id,
        behavior: "rehab",
        term: "od",
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        date: dt.getDate(),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      let data = this.props.data;

      // try {
      //   const res = await restapi.patchTrend(body);
      //   if (res && res.result) data = res.detail;
      // } catch (error) {
      //   console.log(error);
      // }
      console.log(data);
      this.setState({ loading: false, data });
    });
  };

  handleClickRehab = event => {
    let behavior_id = event.currentTarget.dataset.key;
    behavior_id && (behavior_id = Number(behavior_id));
    if(this.props.currentType === "exercise" && this.state.behavior_id === behavior_id) behavior_id = null;
    this.setState({ behavior_id }, () => {
      this.props.onChangeBehavior && this.props.onChangeBehavior(behavior_id);
    });
  };

  // MemoComponent = ({ data }) => {
  //   const [isEditing, setIsEditing] = useState(false);
  //   const [editText, setEditText] = useState(data.memo ?? "");

  //   useEffect(() => {
  //     if(isEditing === true) {
  //       setEditText(data.memo ?? "");
  //     }
  //   }, [isEditing]);

  //   function onTextChange(e) {
  //     setEditText(e.target.value);
  //   }

  //   async function saveText() {
  //     try {
  //       const response = await springapi.request("POST", "/v2/log/update/exerciseMemo", { exerciseId: data.id, memo: editText });
  //       data.memo = editText;
  //       console.log(response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     setIsEditing(false);
  //   }

  //   return (
  //     <div style={{ padding: 10 }}>
  //       {isEditing ?
  //         <>
  //         <div style={{ display: "flex", alignItems: "center", marginLeft: 10, marginRight: 10 }}>
  //           <div>Memo</div>
  //           <div style={{ flexGrow: 1 }}/>
  //           <Button color="primary" style={{ height: 25, padding: "2px 5px 2px 5px", boxShadow: "none", marginRight: 10 }} onClick={saveText}>Save</Button>
  //           <Button color="white" style={{ height: 25, padding: "2px 5px 2px 5px", boxShadow: "none", borderColor: "black" }} onClick={() => setIsEditing(false)}>Cancel</Button>
  //         </div>
  //         <textarea style={{ width: "calc(100% - 20px) ", height: 100, resize: "none", marginTop: 5, marginLeft: 10, marginRight: 10 }} onChange={onTextChange} value={editText}/>
  //         </>
  //       :
  //         <>
  //           <div style={{ display: "flex", alignItems: "center", marginLeft: 10, marginRight: 10 }}>
  //             <div>Memo</div>
  //             <div style={{ flexGrow: 1 }}/>
  //             <Button color="primary" style={{ height: 25, padding: "2px 5px 2px 5px", boxShadow: "none" }} onClick={() => setIsEditing(true)}>Edit</Button>
  //           </div>
  //           <div style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }}>
  //             {data.memo}
  //           </div>
  //         </>
  //       }
  //     </div>
  //   );
  // }

  render = () => {
    let body = null;
    const data = this.props.data;
    if(this.props.isAdmin && this.props.user_id == null) {
      body = <Alert color="warning">select user</Alert>;
    }
    else if(data == null || data.length === 0) {
      body = <></>;
    } else {
      body = (
        // <ListGroup className="exerciselist">
        //   <ListGroupItem>
        //     <Row>
        //       <Col xs="4">Type</Col>
        //       <Col xs="2">Date</Col>
        //       <Col xs="2">Start</Col>
        //       <Col xs="2">End</Col>
        //       <Col xs="2">Duration</Col>
        //     </Row>
        //   </ListGroupItem>
          this.props.data.map((d) => {
            const date = d.data.dateTime.substring(0, 4) + "-" + d.data.dateTime.substring(4, 6) + "-" + d.data.dateTime.substring(6, 8);
            const time = d.data.dateTime.substring(8, 10) + ":" + d.data.dateTime.substring(10, 12) + ":" + d.data.dateTime.substring(12, 14);
            const progressTime = parseInt(d.data.progressTime / 60) + ":" + (d.data.progressTime % 60 >= 10 ? d.data.progressTime % 60 : ("0" + d.data.progressTime % 60));
            return (
              <React.Fragment key={"exercise_" + d.data.id}>
                <ListGroupItem active={this.props.currentType === "exercise" && this.state.behavior_id === d.data.id} tag="button" data-key={d.data.id} onClick={this.handleClickRehab} action
                  disabled={d.data.isInvalid} style={d.data.isInvalid ? { backgroundColor: "#CCCCCC" } : {}}>
                  <Row>
                    <Col xs="6">{d.data.exerciseName}</Col>
                    {/* <Col xs="4">{totalTime}</Col> */}
                    <Col xs="3">{date + " " + time}</Col>
                    <Col xs="2">{progressTime}</Col>
                    <Col xs="1"><Button color="primary" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 21, height: 21, padding: 0 }} onClick={(e) => {
                      e.stopPropagation();
                      if(d.data.id === this.state.memo) {
                        this.setState({ memo: 0 });
                      }
                      else {
                        this.setState({ memo: d.data.id });
                      }
                    }}>{d.data.id === this.state.memo ? <div style={{ marginTop: 4 }}>^</div> : <div style={{ marginTop: -3 }}>v</div>}</Button></Col>
                  </Row>
                </ListGroupItem>
                {d.data.id === this.state.memo && <MemoComponent data={d.data}/>}
              </React.Fragment>
            );
          })
        // </ListGroup>
      );
    }
    // const body = this.props.data.map((d) => {return <div key={d.data.id}>{d.data.id}</div>;})
    return (
      <>
        <ListGroupItem>
          <Row>
            <Col xs="6">Type</Col>
            <Col xs="3">Date Time</Col>
            <Col xs="2">Duration</Col>
            <Col xs="1">Memo</Col>
          </Row>
        </ListGroupItem>
        <ListGroup className="exerciselist">
          {body}
        </ListGroup>
      </>
    );
  };
}
