import React from "react";
import MidCard from "../component/MidCard";
import { Alert, CardText, Media, ListGroup, ListGroupItem } from "reactstrap";
import PostTreatment from "./PostTreatment";
import EditTreadment from "./EditTreatment";
import restapi from "../util/restapi";
import authUtil from "../util/auth";
import ForumIcon from "@material-ui/icons/Forum";

export default class Treatment extends React.Component {
  TAG = "Treatment";
  state = {
    loading: false,
    data: []
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.props.user_id != null &&
      prevProps.user_id !== this.props.user_id &&
      this.fetch();
  };

  componentDidMount = async () => {
    this.props.user_id && this.fetch();
    const { user_id, master } = await authUtil.getUserInfo();
    [this.user_id, this.master] = [Number(user_id), master];
  };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const data = await restapi.getTreatment(this.props.user_id);

      this.setState({
        loading: false,
        data: data && data.result === true ? data.detail : []
      });
    });
  };

  render = () => {
    let body = null;
    const { data } = this.state;
    if (this.props.user_id == null) body = <CardText>select user</CardText>;
    else {
      let content = null;

      if (data == null || data.length === 0) {
        content = <Alert color="warning">no data</Alert>;
      } else {
        content = data.map((item, idx) => (
          <ListGroupItem key={idx}>
            <Media>
              <Media body>
                <Media heading>
                  <small key={`${idx}-1`}>
                    {item.email}
                    {item.middle_name && `${item.middle_name}`}
                    {item.given_name && ` ${item.given_name}`}
                    {item.family_name && ` ${item.family_name}`}
                  </small>{" "}
                  <small key={`${idx}-2`}>
                    {new Date(item.time_insert).toLocaleString()}
                  </small>
                </Media>
                {item.content.split("\n").map((item, i) => (
                  <span key={`${idx}-${i}`}>
                    {item}
                    <br />
                  </span>
                ))}
              </Media>
              <Media left>
                {this.user_id && this.user_id === item.user_from && (
                  <EditTreadment
                    size="sm"
                    id={item.id}
                    content={item.content}
                    onFinished={this.fetch}
                  >
                    Edit
                  </EditTreadment>
                )}
              </Media>
            </Media>
          </ListGroupItem>
        ));
      }

      body = (
        <ListGroup>
          {this.master && (
            <ListGroupItem>
              <PostTreatment
                user_id={this.props.user_id}
                onPosted={this.fetch}
              ></PostTreatment>
            </ListGroupItem>
          )}
          {content}
        </ListGroup>
      );
    }

    return (
      <MidCard
        icon={<ForumIcon fontSize="large" color="primary" />}
        title="Feedback"
        loading={this.state.loading}
      >
        {body}
      </MidCard>
    );
  };
}
