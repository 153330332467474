import React, { useEffect, useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import util from "../util/util";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import mobiscroll from "@mobiscroll/react-lite";
// import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";
import springapi from "../util/springapi";
import Spinner from "../uicomponent/Spinner";
import _ from "lodash";

const customBlue = "#4444ff";

const UserList = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userAdd, setUserAdd] = useState(false);
  const [filter, setFilter] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("");
  // const [gradeEval, setGradeEval] = useState(false);
  // const [evalList, setEvalList] = useState(new Array(8).fill(0));
  // const [evalListTemp, setEvalListTemp] = useState(new Array(8).fill(0));
  const [groupId, setGroupId] = useState(null);
  const [weakSideId, setWeakSideId] = useState(null);
  const [surgicalSite, setSurgicalSite] = useState({
    ankle: false,
    knee: false,
    waist: false,
    shoulder: false,
    wrist: false
  });
  const [patientInfo, setPatientInfo] = useState({
    id: null,
    phone: "",
    name: "",
    birthdate: "",
    regDate: "",
    gender: "",
    groupId: null,
    weakSideId: null,
    surgicalSite: {
      ankle: false,
      knee: false,
      waist: false,
      shoulder: false,
      wrist: false
    }
  });
  const [infoEdit, setInfoEdit] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const groupList = ["G1", "G2", "G3", "G4"];

  const surgicalSiteList = [
    {kor: "발목", eng: "ankle"},
    {kor: "무릎", eng: "knee"},
    {kor: "허리", eng: "waist"},
    {kor: "어깨", eng: "shoulder"},
    {kor: "손목", eng: "wrist"}
  ];

  // const evalItemList = [
  //   "걷기 - 5m의 거리를 걷기",
  //   "계단 - 10개의 계단을 쉬지 않고 오르기",
  //   "물건나르기 - 5kg의 물체를 들어서 나르기",
  //   "한발서기 - 한발로 서서 10초 이상 버티기",
  //   "빠르게 이동하기 - 5초 이내에 3m 이동하기",
  //   "일어서기 - 앉은 자세에서 양손을 가슴에 모으고 일어나는 동작을 5회 반복",
  //   "오래 걷기 - 20분 이상 걷기",
  //   "일상생활 - 옷 입기, 밥 먹기, 화장실 가기"
  // ];

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if(patientInfo.id) {
      const info = list.find((v) => v.id === patientInfo.id);
      if(info) {
        const { id, phone, name, birthdate, regDate, gender, groupId, weakSideId, surgicalSite: siteList } = info;
        const surgicalSite = {
          ankle: false,
          knee: false,
          waist: false,
          shoulder: false,
          wrist: false
        };
        surgicalSiteList.forEach((site) => {
          surgicalSite[site.eng] = siteList.includes(site.eng);
        });
        setPatientInfo({ id, phone, name, birthdate, regDate, gender, groupId, weakSideId, surgicalSite });
        props.onChangeUser && props.onChangeUser({ id, groupId, weakSideId, surgicalSite });
      }
      else {
        setPatientInfo({
          id: null,
          phone: "",
          name: "",
          birthdate: "",
          regDate: "",
          gender: "",
          groupId: null,
          weakSideId: null,
          surgicalSite: {
            ankle: false,
            knee: false,
            waist: false,
            shoulder: false,
            wrist: false
          }
        });
        props.onChangeUser && props.onChangeUser({
          id: null,
          groupId: null,
          weakSideId: null,
          surgicalSite: {
            ankle: false,
            knee: false,
            waist: false,
            shoulder: false,
            wrist: false
          }
        });
      }
    }
  }, [list]);

  async function fetchList() {
    setIsLoading(true);
    const res = await springapi.request("GET", `/dtx/web/getPatientList?doctorId=${props.doctorId}`);
    if(res.response) {
      setList(res.response);
    }
    setIsLoading(false);
  }

  function changeUser(user) {
    const { id, phone, name, birthdate, regDate, gender, groupId, weakSideId, surgicalSite: siteList } = user;
    if(id === patientInfo.id) {
      return;
    }
    const surgicalSite = {
      ankle: false,
      knee: false,
      waist: false,
      shoulder: false,
      wrist: false
    };
    surgicalSiteList.forEach((site) => {
      surgicalSite[site.eng] = siteList.includes(site.eng);
    });
    setPatientInfo({ id, phone, name, birthdate, regDate, gender, groupId, weakSideId, surgicalSite });
    props.onChangeUser && props.onChangeUser(user);
  };

  function changeFilter(event) {
    setFilter(event.target.value);
  }

  function changePhone(event) {
    setPhone(event.target.value.replace(/\D/g, ""));
  }

  function changeName(event) {
    setName(event.target.value);
  }

  function changeBirthdate(event) {
    setBirthdate(event.target.value.replace(/\D/g, ""));
  }

  function checkValidBirthdate() {
    if(birthdate.length < 8) {
      return false;
    }
    const year = birthdate.slice(0, 4);
    const month = birthdate.slice(4, 6);
    const date = birthdate.slice(6, 8);
    const convertedDate = new Date(year + "-" + month + "-" + date);
    return parseInt(year) === convertedDate.getFullYear() && parseInt(month) === convertedDate.getMonth() + 1 && parseInt(date) === convertedDate.getDate();
  }

  function changeGender(event) {
    setGender(event.target.value);
  }

  function changeGroupId(event) {
    setGroupId(Number(event.target.value));
  }

  function changeWeakSideId(event) {
    setWeakSideId(Number(event.target.value));
  }

  function check(site) {
    setSurgicalSite({
      ...surgicalSite,
      [site]: !surgicalSite[site]
    });
  }

  async function addUser() {
    setIsAdding(true);
    const siteList = [];
    Object.entries(surgicalSite).map(([key, value]) => {
      if(value) {
        siteList.push(key);
      }
    });
    const res = await springapi.request("POST", "/dtx/web/addPatient", {
      doctorId: props.doctorId,
      phone,
      name,
      birthdate,
      gender,
      groupId,
      weakSideId: ![1, 3].includes(groupId) ? null : weakSideId,
      surgicalSite: siteList
    });
    if(res.status === 409) {
      alert("이미 등록된 전화번호입니다");
    }
    else {
      resetInfo();
      setUserAdd(false);
      await fetchList();
    }
    setIsAdding(false);
  }

  async function updateInfo() {
    setIsAdding(true);
    const siteList = [];
    Object.entries(surgicalSite).map(([key, value]) => {
      if(value) {
        siteList.push(key);
      }
    });
    await springapi.request("POST", "/dtx/web/updatePatientInfo", {
      id: patientInfo.id,
      surgicalSite: siteList,
      groupId,
      weakSideId: ![1, 3].includes(groupId) ? null : weakSideId
    })
    await fetchList();
    setIsAdding(false);
    setInfoEdit(false);
  }

  // const EvalItemRow = ({ item, index }) => {
  //   return (
  //     <tr>
  //       <td style={{ paddingLeft: 3, paddingRight: 3 }}>{item}</td>
  //       {_.range(1, 7).map((v) => <td key={v} align="center">
  //         <input type="radio" name={index} value={v} checked={evalListTemp[index] === v} onChange={(event) => {
  //           evalListTemp[index] = v;
  //           setEvalListTemp(evalListTemp.slice());
  //         }}/>
  //       </td>)}
  //     </tr>
  //   );
  // };

  // const EvalModal = () => {
  //   return (
  //     <div>
  //       <div className="modal-background" onClick={() => {
  //         setEvalListTemp(evalList.slice());
  //         setGradeEval(false);
  //       }}/>
  //       <div className="modal-main" style={{ width: 700, height: 500, transform: "translate(-350px, -250px)", padding: 20 }}>
  //         <div style={{ marginBottom: 10 }}><b>신체등급 진단</b></div>
  //         <div>{"<진단 기준>"}</div>
  //         <div style={{ marginLeft: 10, marginBottom: 10 }}>
  //           <div>1점: 아예 불가능</div>
  //           <div>2점: 보호자에게 거의 대부분을 의지하는 경우, 또는 누군가 곁에 없을 때 안전에 문제가 있는 경우</div>
  //           <div>3점: 보호자에게 중등도로 의지하는 경우, 일을 수행하기 위해서 보호자의 감시나 관리가 필요한 경우</div>
  //           <div>4점: 보호자의 도움이 최소로 필요한 경우</div>
  //           <div>5점: 스스로 할 수 있지만 힘이 들거나 어렵다고 느낌</div>
  //           <div>6점: 정상</div>
  //         </div>
  //         <table style={{ width: "100%" }}>
  //           <thead>
  //             <tr>
  //               <th style={{ textAlign: "center" }}>진단 항목</th>
  //               {_.range(1, 7).map((v) => <th key={v} style={{ width: 30, textAlign: "center" }}>{v}점</th>)}
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {evalItemList.map((v, i) => <EvalItemRow key={i} item={v} index={i}/>)}
  //           </tbody>
  //         </table>
  //         {/* <div style={{ marginLeft: 600, marginTop: 5 }}><b>총점: {_.sum(evalListTemp)}</b></div> */}
  //         <div style={{ display: "flex", marginTop: 15, justifyContent: "center" }}>
  //           <Button style={{ backgroundColor: "white", color: "black" }} onClick={() => {
  //             setEvalListTemp(evalList.slice());
  //             setGradeEval(false);
  //           }}>취소</Button>
  //           <Button style={{ borderColor: customBlue, backgroundColor: customBlue, marginLeft: 10 }} disabled={evalListTemp.includes(0)} onClick={() => {
  //             setEvalList(evalListTemp.slice());
  //             setGradeEval(false);
  //             const evalSum = _.sum(evalListTemp);
  //             setGroupId(evalSum === 8 ? 1 : Math.floor((evalSum - 1) / 8));
  //           }}>확인</Button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  function resetInfo() {
    setPhone("");
    setName("");
    setBirthdate("");
    setGender("");
    setGroupId(null);
    setWeakSideId(null);
    setSurgicalSite({
      ankle: false,
      knee: false,
      waist: false,
      shoulder: false,
      wrist: false
    });
  }

  return (
    <>
      <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
        <span style={{ display: "flex", alignItems: "center", height: 26, color: "white", marginLeft: 10 }}>{userAdd ? "환자 등록" : "환자 목록"}</span>
        <Spinner visible={isLoading} style={{ marginLeft: 5 }} color="white"/>
        <div style={{ flexGrow: 1 }}/>
        {!userAdd && <Button style={{ borderColor: customBlue, backgroundColor: customBlue, padding: "2px 5px 1px 3px", marginRight: 10 }} onClick={() => {
          resetInfo();
          setUserAdd(true);
        }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AddIcon style={{ color: "white" }}/>
            환자 등록
          </div>
        </Button>}
      </div>
      {/* {gradeEval && <EvalModal/>} */}
      {userAdd ?
        <div style={{ padding: 20, marginTop: -5, color: "white" }}>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, marginRight: 20 }}><b>이름</b></div>
            <Input
              value={name}
              placeholder="홍길동"
              onChange={changeName}
              style={{ boxShadow: "none", width: 150, height: 30 }}
            />
          </div>
          <div style={{ display: "flex", marginTop: 5 }}>
            <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, marginRight: 20 }}><b>전화번호</b></div>
            <Input
              value={phone}
              placeholder="01000000000"
              onChange={changePhone}
              style={{ boxShadow: "none", width: 150, height: 30 }}
            />
          </div>
          <div style={{ display: "flex", marginTop: 5 }}>
            <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, marginRight: 20 }}><b>생년월일</b></div>
            <Input
              value={birthdate}
              placeholder="19700101"
              onChange={changeBirthdate}
              maxLength={8}
              style={{ boxShadow: "none", width: 150, height: 30 }}
            />
          </div>
          <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
            <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>성별</b></div>
            <input type="radio" name="gender" value="M" onChange={changeGender} checked={gender === "M"}/>
            <div style={{ marginLeft: 5 }}>남성</div>
            <input type="radio" name="gender" value="F" onChange={changeGender} checked={gender === "F"} style={{ marginLeft: 25 }}/>
            <div style={{ marginLeft: 5 }}>여성</div>
          </div>
          <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
            <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>그룹</b></div>
            <input type="radio" name="groupId" value={0} onChange={changeGroupId} checked={groupId === 0}/>
            <div style={{ marginLeft: 5 }}>G1</div>
            <input type="radio" name="groupId" value={1} onChange={changeGroupId} checked={groupId === 1} style={{ marginLeft: 5 }}/>
            <div style={{ marginLeft: 5 }}>G2</div>
            <input type="radio" name="groupId" value={2} onChange={changeGroupId} checked={groupId === 2} style={{ marginLeft: 5 }}/>
            <div style={{ marginLeft: 5 }}>G3</div>
            <input type="radio" name="groupId" value={3} onChange={changeGroupId} checked={groupId === 3} style={{ marginLeft: 5 }}/>
            <div style={{ marginLeft: 5 }}>G4</div>
          </div>
          {[1, 3].includes(groupId) &&
            <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
              <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>마비측</b></div>
              <input type="radio" name="weakSideId" value={0} onChange={changeWeakSideId} checked={weakSideId === 0}/>
              <div style={{ marginLeft: 5 }}>좌측</div>
              <input type="radio" name="weakSideId" value={1} onChange={changeWeakSideId} checked={weakSideId === 1} style={{ marginLeft: 5 }}/>
              <div style={{ marginLeft: 5 }}>우측</div>
            </div>
          }
          {/* <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
            <div style={{ display: "flex", flexShrink: 0, alignItems: "center", height: 30, width: 60, marginRight: 20 }}><b>신체등급</b></div>
            <div style={{ width: 60 }}>{groupId ? groupId + "등급" : "진단 필요"}</div>
            <Button style={{ borderColor: customBlue, backgroundColor: customBlue, padding: "2px 5px 1px 5px", marginLeft: 50 }} onClick={() => setGradeEval(true)}>진단</Button>
          </div> */}
          <div style={{ display: "flex", marginTop: 5, alignItems: "center", height: 30 }}><b>수술부위</b></div>
          <div style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
            {surgicalSiteList.map((site) => {
              return (
                <div key={site.eng} style={{ display: "table-cell" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" checked={surgicalSite[site.eng]} onChange={() => check(site.eng)}/>
                    <div style={{ marginLeft: 2 }}>{site.kor}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
            <Button style={{ backgroundColor: "white", color: "black" }} onClick={() => {
              resetInfo();
              setUserAdd(false);
            }}>취소</Button>
            <Button style={{ borderColor: customBlue, backgroundColor: customBlue, marginLeft: 30 }}
              disabled={phone === "" || name === "" || !checkValidBirthdate() || gender === "" || groupId === null || [1, 3].includes(groupId) && weakSideId === null || isAdding}
              onClick={addUser}>추가</Button>
          </div>
        </div>
      : 
        <>
          <InputGroup style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
            <Input
              value={filter}
              placeholder="이름 또는 전화번호로 검색"
              onChange={changeFilter}
              style={{ borderColor: "white", boxShadow: "none" }}
            />
            <InputGroupAddon addonType="append">
              <Button
                style={{ border: "1px solid", backgroundColor: "black", height: "auto", padding: 0 }}
                onClick={() => setFilter("")}
                disabled={filter === ""}
              >
                <CloseIcon style={{ marginTop: -4, width: 32 }}/>
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <ListGroup style={{ flexGrow: 1, overflow: "auto", margin: 10, backgroundColor: "white", borderRadius: 5 }}>
            {list.length === 0 &&
              <div style={{ position: "fixed", width: "160px", textAlign: "center", transform: "translate(50px, 20px)" }}>등록된 환자가 없습니다</div>
            }
            {list.filter(
              item =>
                ((item.phone && item.phone.includes(filter)) || (item.name && item.name.includes(filter)))
              )
              .map(item => (
                <ListGroupItem
                  key={item.id}
                  active={item.id === patientInfo.id}
                  tag="button"
                  data-key={item.id}
                  onClick={() => {
                    setInfoEdit(false);
                    changeUser(item);
                  }}
                  action
                  style={{ borderRadius: 0, backgroundColor: item.abnormality && item.id !== patientInfo.id ? "#FF000022" : undefined }}
                >
                  <Row>
                    <Col xs="6">{item.name}</Col>
                    <Col xs="6">{item.phone}</Col>
                  </Row>
                  <Row>
                    <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col>
                    <Col xs="6">{item.gender.toUpperCase()}</Col>
                  </Row>
                </ListGroupItem>
              )
            )}
          </ListGroup>
          {patientInfo.id &&
            <>
              <hr className="sidebar-line"/>
              <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
                <span style={{ color: "white", marginLeft: 10 }}>환자 정보</span>
                <div style={{ flexGrow: 1 }}/>
                {infoEdit ?
                  <>
                    <Button style={{ backgroundColor: "white", color: "black", padding: "2px 5px 1px 5px" }} disabled={isAdding} onClick={() => setInfoEdit(false)}>취소</Button>
                    <Button style={{ borderColor: customBlue, backgroundColor: customBlue, padding: "2px 5px 1px 5px", marginLeft: 10, marginRight: 10 }} disabled={isAdding || [1, 3].includes(groupId) && weakSideId === null} onClick={updateInfo}>확인</Button>
                  </>
                :
                  <Button style={{ borderColor: customBlue, backgroundColor: customBlue, padding: "2px 5px 1px 5px", marginRight: 10 }} onClick={() => {
                    setGroupId(patientInfo.groupId);
                    setWeakSideId(patientInfo.weakSideId);
                    // setEvalList(patientInfo.gradeEval.slice());
                    setSurgicalSite({...patientInfo.surgicalSite});
                    setInfoEdit(true);
                  }}>수정</Button>
                }
              </div>
              <div style={{ padding: 20, marginTop: -5, color: "white" }}>
                <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                  <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>이름</b></div>
                  <div>{patientInfo.name}</div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>전화번호</b></div>
                  <div>{patientInfo.phone}</div>
                </div>
                <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                  <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>생년월일</b></div>
                  <div>{patientInfo.birthdate}</div>
                </div>
                <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                  <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>등록일</b></div>
                  <div>{util.convDt2SimpleDate(patientInfo.regDate)}</div>
                </div>
                <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                  <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>성별</b></div>
                  <div>{patientInfo.gender ? patientInfo.gender === "M" ? "남성" : "여성" : ""}</div>
                </div>
                <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                  <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>그룹</b></div>
                  {infoEdit ?
                    <>
                      <input type="radio" name="groupId" value={0} onChange={changeGroupId} checked={groupId === 0}/>
                      <div style={{ marginLeft: 5 }}>G1</div>
                      <input type="radio" name="groupId" value={1} onChange={changeGroupId} checked={groupId === 1} style={{ marginLeft: 5 }}/>
                      <div style={{ marginLeft: 5 }}>G2</div>
                      <input type="radio" name="groupId" value={2} onChange={changeGroupId} checked={groupId === 2} style={{ marginLeft: 5 }}/>
                      <div style={{ marginLeft: 5 }}>G3</div>
                      <input type="radio" name="groupId" value={3} onChange={changeGroupId} checked={groupId === 3} style={{ marginLeft: 5 }}/>
                      <div style={{ marginLeft: 5 }}>G4</div>
                    </>
                  :
                    <div>{patientInfo.groupId !== null ? groupList[patientInfo.groupId] : ""}</div>
                  }
                </div>
                {(!infoEdit && [1, 3].includes(patientInfo.groupId) || infoEdit && [1, 3].includes(groupId)) &&
                  <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                    <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>마비측</b></div>
                    {infoEdit ?
                      <>
                        <input type="radio" name="weakSideId" value={0} onChange={changeWeakSideId} checked={weakSideId === 0}/>
                        <div style={{ marginLeft: 5 }}>좌측</div>
                        <input type="radio" name="weakSideId" value={1} onChange={changeWeakSideId} checked={weakSideId === 1} style={{ marginLeft: 5 }}/>
                        <div style={{ marginLeft: 5 }}>우측</div>
                      </>
                    :
                      <div>{patientInfo.weakSideId !== null ? ["좌측", "우측"][patientInfo.weakSideId] : ""}</div>
                    }
                  </div>
                }
                {/* <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                  <div style={{ display: "flex", flexShrink: 0, alignItems: "center", width: 60, height: 30, marginRight: 20 }}><b>신체등급</b></div>
                  <div style={{ width: 60 }}>{infoEdit ? groupId : patientInfo.groupId}등급</div>
                  {infoEdit && <Button style={{ borderColor: customBlue, backgroundColor: customBlue, padding: "2px 5px 1px 5px", marginLeft: 50 }} onClick={() => {
                    setEvalListTemp(evalList.slice());
                    setGradeEval(true);
                  }}>진단</Button>}
                </div> */}
                <div style={{ display: "flex", marginTop: 5, alignItems: "center", height: 30 }}><b>수술부위</b></div>
                <div style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                  {surgicalSiteList.map((site) => {
                    return (
                      <div key={site.eng} style={{ display: "table-cell" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input type="checkbox" checked={infoEdit ? surgicalSite[site.eng] : patientInfo.surgicalSite[site.eng]} style={{ zIndex: infoEdit ? 0 : -100 }} onChange={() => check(site.eng)}/>
                          <div style={{ marginLeft: 2 }}>{site.kor}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          }
        </>
      }
    </>
  );
}

export default UserList;