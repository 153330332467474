import React, { useEffect, useState } from "react";
import springapi from "../util/springapi";
import User from "./User";

const KakaoLogin = (props) => {
    const [exists, setExists] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if(params.get("code")) {
            login(params.get("code"));
        }
        return () => console.log("unmount");
    }, []);

    async function login(code) {
        const id = await springapi.getKakaoUserId(`${window.location.origin}/kakao`, code);
        const exists = await springapi.loginBySNS({method: "kakao", key: id});
        console.log(id);
        if(exists) {
            User.signIn(id);
        }
        setExists(exists);
    }

    if(!exists) {
        return (
            <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, 0)" }}>Sign up via ExoPill app</div>
        )
    }

    return (
        <></>
    );
}

export default KakaoLogin;