import React from "react";
import * as d3 from "d3";
import { arc as d3Arc, pie as d3Pie } from "d3-shape";
import util from "../util/util";

const mapRehabTypesub = new Map();
mapRehabTypesub.set("rehab_q_set", "Q-set");
mapRehabTypesub.set("rehab_q_set_fes", "Q-set (with Electrical Stimulation)");
mapRehabTypesub.set("rehab_terminal_ext", "Short Arc Quad");
mapRehabTypesub.set("rehab_slr_open", "SLR (With Straight Knee)");
mapRehabTypesub.set("rehab_slr_close", "SLR (With Bent Knee)");
mapRehabTypesub.set("rehab_knee_squat", "Partial Squat with Chair");
mapRehabTypesub.set("rehab_heel_lift", "Heel Raise");
mapRehabTypesub.set("rehab_ankle_pump", "Ankle Pump");
mapRehabTypesub.set("other", "Other");
// mapRehabTypesub.set("none", "Other");

const mapColors = new Map();
mapColors.set("rehab_q_set", "indianred");
mapColors.set("rehab_q_set_fes", "orange");
mapColors.set("rehab_terminal_ext", "yellow");
mapColors.set("rehab_slr_open", "lightgreen");
mapColors.set("rehab_slr_close", "skyblue");
mapColors.set("rehab_knee_squat", "royalblue");
mapColors.set("rehab_heel_lift", "blueviolet");
mapColors.set("rehab_ankle_pump", "violet");
mapColors.set("other", "lightpink");

export default class PieRehabTime extends React.Component {

  updateDimensions = () => this.draw();

  midAngle = d => d.startAngle + (d.endAngle - d.startAngle) / 2;

  draw = () => {
    const transitionTime = 1000;
    const data = [];
    this.props.data
      .reduce((acc, item) => {
        !mapRehabTypesub.get(item.type_sub) && (item.type_sub = "other");
        const val =  acc.get(item.type_sub);

        if (val) {
          val.play_time += item.play_time;
          acc.set(item.type_sub, val);
        } else {
          acc.set(item.type_sub, JSON.parse(JSON.stringify(item)));
        }
        return acc;
      }, new Map())
      .forEach(val => data.push(val));
    // console.log(data)
    const svg = d3.select(`svg#${this.props.svgId}`);
    const { width, height } = svg.node().getBoundingClientRect();
    const radius = Math.min(width, height) / 2;
    const arc = d3Arc()
      .outerRadius(radius * 0.8)
      .innerRadius(radius * 0.4);
    const outerArc = d3Arc()
      .outerRadius(radius * 0.9)
      .innerRadius(radius * 0.9);
    const pie = d3Pie()
      .sort(null)
      .value(d => d.play_time)
      .padAngle(Math.PI / 180);
    const key = d => d.data.type_sub;

    svg
      .select("g#group")
      .attr("transform", `translate(${width / 2},${height / 2 - 20})`);

    const group = svg.select("g#group");

    const slices = group
      .select("g.slices")
      .selectAll("path.slice")
      .data(pie(data), key);

    const slice = slices
      .enter()
      .insert("path")
      .attr("class", "slice")
      .style("fill", d => mapColors.get(key(d)))
      .attr("d", arc)
    slice
      .transition()
      .duration(transitionTime)
      .attrTween("d", d => {
        this._current = this._current || d;
        const interpolate = d3.interpolate(this._current, d);
        return t => arc(interpolate(t));
      });
    const type = svg.append("text")
      .attr("x", "5px")
      .attr("y", "270px")
      .attr("fill", "black")
      .text("Type:")
      // .style("display", "none");
    const time = svg.append("text")
      .attr("x", "5px")
      .attr("y", "285px")
      .attr("fill", "black")
      .text("Play time:")
      // .style("display", "none");
    // console.log(data);
    slice
      .on("mouseover", (d) => {
        type.text("Type: " + (mapRehabTypesub.get(key(d)) ? mapRehabTypesub.get(key(d)) : "Other"));
        // type.style("display", "block");
        time.text("Play time: " + util.convDt2SimpleTime(data.find(item => item.type_sub === key(d)).play_time));
        // time.style("display", "block");
      })
      .on("mouseout", () => {
        type.text("Type:");
        time.text("Play time:");
        // type.style("display", "none");
        // time.style("display", "none");
      })

    slices.exit().remove();
  };

  componentDidUpdate = () => {
    // console.log(this.props.data)
    this.draw();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions);
    this.draw();
  };

  render = () => {
    return (<>
      <svg className="graph" id={this.props.svgId} width="100%" height="300px">
        <g id="group">
          <g className="slices"></g>
          {/* <g className="labels"></g>
          <g className="lines"></g> */}
        </g>
      </svg>
      </>
    );
  };
}
