import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Auth, Hub } from "aws-amplify";
import awsmobile from "./aws-exports";
import UserInfoSimple from "./component/UserInfoSimple";

import V2SignIn from "./v2/V2SignIn copy";
import LicMan from "./component/LicenseMan";
import NotFound from "./component/NotFound";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import V1Home from "./component/V1Home";
import V2HomeTemp from "./v2/V2HomeTemp";
import V2Home from "./v2/V2Home";
import TopSpacer from "./component/TopSpacer";

import { Authenticator } from "aws-amplify-react";

import "./App.css";
import "./SignIn.css";
import { CustomForgotPassword, CustomSignIn, CustomSignUp } from "./auth";
import RouteUser from "./router/User";
import User from "./v2/User";
import KakaoLogin from "./v2/KakaoLogin";

import DtxSignIn from "./dtx/DtxSignIn";
import DtxHome from "./dtx/DtxHome";
import DtxUser from "./dtx/DtxUser";

import { GlobalDebug } from "./util/remove-consoles";
import Admin from "./auth/Admin";
import SignUpTemp from "./v2/SignUpTemp";
import { GlobalContext, useGlobal } from "./v2/context/global";
import PrintModal from "./component/PrintModal";
import MapModal from "./v2/MapModal";
import AnalysisPage from "./analysis/AnalysisPage";

Auth.configure(awsmobile);

const left = "16.7%", center = "50%", right = "83.3%", outside = "200%";

export default class App extends Component {

  state = {
    v1User: null,
    v2User: null,
    dtxUser: null,
    isV2Admin: false,
    expand: "",
    signInLoading: false,
    new: false,
    exoRehab: {left: left, animationName: ""},
    exoFit: {left: center, animationName: ""},
    dtx: {left: right, animationName: ""},
    temp: {component: "", left: outside, animationName: ""},
    print: null,
    mapOpened: false,
    pyodide: null,
    paramsTemp: {}
  };

  constructor(props) {
    super(props);
    // this.fetch();
    Hub.listen("auth", data => {
      this.setState({
        v1User: data.payload.event === "signIn" ? data.payload.data : null
      });
    });
  }

  fetch = async () => {
    let v1User, v2User, dtxUser;
    let isV2Admin = false;
    try {
      v1User = await Auth.currentAuthenticatedUser();
    } catch (error) {
      // console.error(error);
    }
    try {
      v2User = await User.getId();
      dtxUser = await DtxUser.getInfo();
    } catch (error) {
      console.error(error);
    }

    this.setState({ v1User, v2User, dtxUser });
  };

  signout = async () => {
    await Auth.signOut();
    window.location = "/";
    this.setState({ v1User: null });
  };

  loadPyodide = async () => {
    const py = await window.loadPyodide({
      indexURL: 'https://cdn.jsdelivr.net/pyodide/dev/full/'
    });
    await py.loadPackage(["numpy", "matplotlib"]);
    this.setState({ pyodide: py });
  }

  componentDidMount = () => {
    this.fetch();
    // this.loadPyodide();
    if(localStorage.getItem("new") === null) {
      localStorage.setItem("new", false);
    }
    else {
      this.setState({ new: localStorage.getItem("new") === "true" });
    }
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  };

  slide = (clicked) => {
    switch (clicked) {
      case "exoRehab":
        if(this.state.exoRehab.left === left || this.state.exoRehab.left !== right && this.state.temp.left === left) {
          this.setState({
            temp: {component: "dtx", left: left, animationName: "outsideToLeft"},
            exoRehab: {left: center, animationName: "leftToCenter"},
            exoFit: {left: right, animationName: "centerToRight"},
            dtx: {left: outside, animationName: "rightToOutside"}
          });
        }
        else if(this.state.exoRehab.left === right || this.state.exoRehab.left !== left && this.state.temp.left === right) {
          this.setState({
            temp: {component: "exoFit", left: right, animationName: "outsideToRight"},
            exoRehab: {left: center, animationName: "rightToCenter"},
            exoFit: {left: outside, animationName: "leftToOutside"},
            dtx: {left: left, animationName: "centerToLeft"}
          });
        }
        break;
      case "exoFit":
        if(this.state.exoFit.left === left || this.state.exoFit.left !== right && this.state.temp.left === left) {
          this.setState({
            temp: {component: "exoRehab", left: left, animationName: "outsideToLeft"},
            exoRehab: {left: outside, animationName: "rightToOutside"},
            exoFit: {left: center, animationName: "leftToCenter"},
            dtx: {left: right, animationName: "centerToRight"}
          });
        }
        else if(this.state.exoFit.left === right || this.state.exoFit.left !== left && this.state.temp.left === right) {
          this.setState({
            temp: {component: "dtx", left: right, animationName: "outsideToRight"},
            exoRehab: {left: left, animationName: "centerToLeft"},
            exoFit: {left: center, animationName: "rightToCenter"},
            dtx: {left: outside, animationName: "leftToOutside"}
          });
        }
        break;
      case "dtx":
        if(this.state.dtx.left === left || this.state.dtx.left !== right && this.state.temp.left === left) {
          this.setState({
            temp: {component: "exoFit", left: left, animationName: "outsideToLeft"},
            exoRehab: {left: right, animationName: "centerToRight"},
            exoFit: {left: outside, animationName: "rightToOutside"},
            dtx: {left: center, animationName: "leftToCenter"}
          });
        }
        else if(this.state.dtx.left === right || this.state.dtx.left !== left && this.state.temp.left === right) {
          this.setState({
            temp: {component: "exoRehab", left: right, animationName: "outsideToRight"},
            exoRehab: {left: outside, animationName: "leftToOutside"},
            exoFit: {left: left, animationName: "centerToLeft"},
            dtx: {left: center, animationName: "rightToCenter"}
          });
        }
        break;
      default:
        break;
    }
  }

  checkV1User = async () => {
    if(this.state.v1User) {
      window.location = "/v1";
    }
    else {
      try {
        await Auth.currentAuthenticatedUser().then((v1User) => {
            if(v1User) {
              window.location = "/v1";
            }
          }
        );
      }
      catch(e) {
        console.log(e);
      }
    }
  }

  render = () => {
    return <Router>
      <GlobalContext.Provider value={{ print: this.state.print, setPrint: (component) => {this.setState({ print: component })},
        mapOpened: this.state.mapOpened, setMapOpened: (bool) => {this.setState({ mapOpened: bool })},
        pyodide: this.state.pyodide, setPyodide: (pyodide) => {this.setState({ pyodide })},
        paramsTemp: this.state.paramsTemp, setParamsTemp: (paramsTemp) => this.setState({ paramsTemp }) }}>
      <PrintModal/>
      <MapModal/>
      {!(this.state.new && window.location.pathname === "/v2" || window.location.pathname === "/dtx" || window.location.pathname === "/admin" || window.location.pathname === "/analysis") && (
        <AppBar position="fixed" style={{ zIndex: 1201 }}>
          <Toolbar>
            <>
              {window.location.pathname === "/v1" && this.state.v1User && (
                <>
                  <Button href="/" color="inherit">
                    Home
                  </Button>
                  <UserInfoSimple
                    user_id={this.state.v1User.attributes["custom:uid"]}
                  />
                    <Button color="inherit" onClick={this.signout}>
                    Sign out
                  </Button>
                  <ReactToPrint
                    trigger={() => <Button color="inherit">Print</Button>}
                    content={() => this.ref}
                  />
                </>
              )}
              {window.location.pathname === "/v2" && this.state.v2User && (
                <>
                  <Button href="/" color="inherit">
                    Home
                  </Button>
                  <Button color="inherit" onClick={async() => {await User.signOut(); window.location = "/";}}>
                    Sign out
                  </Button>
                  <ReactToPrint
                    trigger={() => <Button color="inherit">Print</Button>}
                    content={() => this.ref}
                  />
                </>
              )}
              {/* {this.state.v1User && (
                <UserInfoSimple
                  user_id={this.state.v1User.attributes["custom:uid"]}
                />
              )}
              {this.state.v1User && (
                <Button color="inherit" onClick={this.signout}>
                  Sign out
                </Button>
              )}
              {this.state.v2User && (
                <Button color="inherit" onClick={() => {User.signOut(); window.location = "/";}}>
                  Sign out
                </Button>
              )}
              <ReactToPrint
                trigger={() => <Button color="inherit">Print</Button>}
                content={() => this.ref}
              /> */}
            </>
            <Button color="inherit" href="https://exosystems.io">
              EXOSYSTEMS
            </Button>
            <div style={{ flexGrow: 1 }}></div>
            <Button color="inherit" href="/admin">
              ADMIN
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <Switch>
        <Route exact path="/" render={() => {
          // if(this.state.v1User && !this.state.signInLoading) {
          //   return <Redirect to="/v1" />
          // }
          // if(this.state.v2User) {
          //   return <Redirect to="/v2" />
          // }
          return <div>
            <TopSpacer height="40vh" />
            <div>
              <div className="exoRehab" style={{ left: this.state.exoRehab.left, animationName: this.state.exoRehab.animationName, animationDuration: "1s" }}>
                <img width="100%" src="/resource/exorehab_logo.png" onClick={() => {this.checkV1User(); this.setState({ expand: "exoRehab" }); this.slide("exoRehab");}}
                  className={["img", this.state.expand !== "exoRehab" && "border"].join(" ")}/>
                <Authenticator hideDefault={true} theme={{toast: {display: "none"}}}>
                  <CustomSignIn setState={p=>{this.setState(p)}} expand={this.state.expand} slide={() => this.slide("exoRehab")} user={this.state.v1User}/>
                  <CustomSignUp expand={this.state.expand}/>
                  <CustomForgotPassword/>
                </Authenticator>
              </div>
              <V2SignIn setState={p=>{this.setState(p)}} expand={this.state.expand} slide={() => this.slide("exoFit")} user={this.state.v2User}
                style={{ left: this.state.exoFit.left, animationName: this.state.exoFit.animationName, animationDuration: "1s" }}/>
              <DtxSignIn setState={p=>{this.setState(p)}} expand={this.state.expand} slide={() => this.slide("dtx")} user={this.state.dtxUser}
                style={{ left: this.state.dtx.left, animationName: this.state.dtx.animationName, animationDuration: "1s" }}/>
              {this.state.temp.component === "exoRehab" &&
              <div className="exoRehab" style={{ left: this.state.temp.left, animationName: this.state.temp.animationName, animationDuration: "1s" }}>
                <img width="100%" src="/resource/exorehab_logo.png" onClick={() => {this.checkV1User(); this.setState({ expand: "exoRehab" }); this.slide("exoRehab");}}
                  className={["img", this.state.expand !== "exoRehab" && "border"].join(" ")}/>
                <Authenticator hideDefault={true} theme={{toast: {display: "none"}}}>
                  <CustomSignIn setState={p=>{this.setState(p)}} expand={this.state.expand} slide={() => this.slide("exoRehab")} user={this.state.v1User}/>
                  <CustomSignUp expand={this.state.expand}/>
                  <CustomForgotPassword/>
                </Authenticator>
              </div>}
              {this.state.temp.component === "exoFit" &&
              <V2SignIn setState={p=>{this.setState(p)}} expand={this.state.expand} slide={() => this.slide("exoFit")} user={this.state.v2User}
                style={{ left: this.state.temp.left, animationName: this.state.temp.animationName, animationDuration: "1s" }}/>}
              {this.state.temp.component === "dtx" &&
              <DtxSignIn setState={p=>{this.setState(p)}} expand={this.state.expand} slide={() => this.slide("dtx")} user={this.state.dtxUser}
                style={{ left: this.state.temp.left, animationName: this.state.temp.animationName, animationDuration: "1s" }}/>}
            </div>
          </div>
        }} />
        {/* <Route path="/v1/forgot" component={Forgot} />
        <Route path="/v1/signup" component={SignUp} /> */}
        <Route exact path="/admin" component={ Admin }/>
        <Route path="/v1/lic" component={LicMan} />
        <Route path="/v1/my" component={RouteUser} />
        <Route
          path="/v1"
          render={props => {
            // if(!this.state.v1User) {
            //   window.location = "/";
            //   return;
            // }
            return (
              <V1Home onRef={ref => (this.ref = ref)} {...props} />
            );
          }}
        />
        <Route
          exact
          path="/v2"
          render={props => {
            // if(!this.state.v2User) {
            //   window.location = "/";
            //   return;
            // }
            if(localStorage.getItem("new") === "true") {
              console.log(this.state.new);
              return <V2Home onRef={ref => (this.ref = ref)} {...props} />;
            }
            return (
              <V2HomeTemp onRef={ref => (this.ref = ref)} {...props} />
            );
          }}
        />
        <Route
          exact
          path="/dtx"
          render={props => {
              return <DtxHome onRef={ref => (this.ref = ref)} {...props} />;
          }}
        />
        {/* <Route
          exact
          path="/test"
          render={props => {
            // if(!this.state.v2User) {
            //   window.location = "/";
            //   return;
            // }
            return (
              <V2Home onRef={ref => (this.ref = ref)} {...props} />
            );
          }}
        /> */}
        <Route exact path="/kakao" component={KakaoLogin}/>
        <Route exact path="/hello" render={() => {return <Redirect to="/"/>}}/>
        {/* <Route exact path="/temp" component={SignUpTemp}/> */}
        <Route exact path="/analysis" component={AnalysisPage}/>
        <Route component={NotFound} />
      </Switch>
      </GlobalContext.Provider>
    </Router>
  }
}
