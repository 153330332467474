import React, { Component } from "react";
import {
  Container,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  CardTitle,
  CardText
} from "reactstrap";
import { Link } from "@material-ui/core";

export default class Terms extends Component {
  state = {
    termAll: false,
    term1: false
  };

  handleCheckAll = e => {
    const checked = e.target.checked;
    this.setState({ termAll: checked, term1: checked });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleAccept = () => {
    this.props.onAccept && this.props.onAccept();
  };

  handleReject = () => {
    this.props.onReject && this.props.onReject();
  };
  render = () => {
    return (
      <>
        {this.props.title && <CardTitle>{this.props.title}</CardTitle>}
        <Container>
          <Label check>
            <Input
              name="termAll"
              type="checkbox"
              onChange={this.handleCheckAll}
              checked={this.state.termAll}
            />
            {this.props.term}
          </Label>
          <hr />
          <Label check>
            <Input
              name="term1"
              type="checkbox"
              onChange={this.handleCheck}
              checked={this.state.term1}
            />
            <Link href={this.props.url} target="_blank" rel="noopener">
              회원 가입 약관
            </Link>
            (필수)
          </Label>
        </Container>
        <Container style={{ justifyContent: "flex-end", display: "flex" }}>
          <Button
            color="primary"
            onClick={this.handleAccept}
            disabled={!this.state.term1}
          >
            확인
          </Button>
          <Button onClick={this.handleReject}>취소</Button>
        </Container>
      </>
    );
  };
}
