import React, { Component } from "react";
import Calendar from "react-calendar";
import IconMq from "@material-ui/icons/AirlineSeatLegroomExtraRounded";
import IconExercise from "@material-ui/icons/FitnessCenterRounded";
import IconFes from "@material-ui/icons/FlashOnRounded";
import Chip from "@material-ui/core/Chip";
import EventIcon from "@material-ui/icons/Event";
import springapi from "../util/springapi";
import Spinner from "../uicomponent/Spinner";
import TabSelector from "../uicomponent/TabSelector";

export default class V2Calendar extends Component {
  state = {
    loading: false,
    date: new Date(),
    data: null,
    modal: false,
    opened: false,
    tab: 0,
    monthDate: new Date(),
    activeStartDate: new Date(),
    view: "month"
  };

  componentDidMount() {
    this.props.user_id && this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {
    const dtPrev = prevState.monthDate;
    const dt = this.state.monthDate;
    this.props.user_id &&
      (this.props.user_id !== prevProps.user_id ||
        dtPrev.getFullYear() !== dt.getFullYear() ||
        dtPrev.getMonth() !== dt.getMonth()) &&
      this.fetch();
  }

  fetch = () => {
    this.setState({ loading: true, data: null }, async () => {
      const dt = this.state.monthDate;
      // console.log(dt);
      // const body = {
      //   user_id: this.props.user_id,
      //   year: dt.getFullYear(),
      //   month: dt.getMonth() + 1,
      //   time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      // };

      let data = null;
      try {
        const res = await springapi.request("GET", `/v2/log/get/countList?id=${this.props.user_id}&year=${dt.getFullYear()}&month=${dt.getMonth() + 1}`);
        
        if(res && res.response) {
          // const dateSet = new Set();
          // res.response.es.forEach(item => {
          //   dateSet.add(item.date);
          // });
          // res.response.exercise.forEach(item => {
          //   dateSet.add(item.date);
          // });
          // res.response.mq.forEach(item => {
          //   dateSet.add(item.date);
          // });
          data = {
            es: res.response.es.map(d => d.date),
            exercise: res.response.exercise.map(d => d.date),
            mq: res.response.mq.map(d => d.date)
          }
        }
        // const res = await restapi.patchCalendar(body);

        // if (res && res.result) {
        //   res.detail.forEach(item => {
        //     const dt = new Date(item.dt);
        //     item.dt = dt.toLocaleDateString();
        //   });

        //   const dts = res.detail.reduce(
        //     (acc, item) => acc.set(item.dt, new Set()),
        //     new Map()
        //   );

        //   while (res.detail.length > 0) {
        //     const row = res.detail.shift();
        //     dts.set(row.dt, dts.get(row.dt).add(row.type));
        //   }
        //   data = dts;
        // }
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false, data });
    });
  };

  dayTile = ({ date, view }) => {
    if(view !== "month" || !this.state.data) {
      return null;
    }
    const [clrOn, clrOff] = ["primary", "disabled"];
    const find = this.state.data;
    const clrMq = find.mq.includes(date.getDate()) && date.getMonth() === this.state.monthDate.getMonth() ? clrOn : clrOff;
    const clrExercise = find.exercise.includes(date.getDate()) && date.getMonth() === this.state.monthDate.getMonth() ? clrOn : clrOff;
    const clrFes = find.es.includes(date.getDate()) && date.getMonth() === this.state.monthDate.getMonth() ? clrOn : clrOff;

    // return (
    //   <div style={{ color: "blue", opacity }}>
    //     &#9679;
    //   </div>
    // );
    return (
      <div>
        <IconExercise key="exercise" color={clrExercise} fontSize="small" />
        <IconMq key="mq" color={clrMq} fontSize="small" />
        <IconFes key="bioelectric" color={clrFes} fontSize="small" />
      </div>
    );
  };

  disableTile = () => {
    return this.state.loading;
  };

  onClickDay = value => {
    if(this.state.date.toDateString() !== value.toDateString()) {
      this.setState({ date: value });
      this.props.onDateChange && this.props.onDateChange(value);
    }
  };

  onActiveDateChange = ({ activeStartDate, view }) => {
    if(view === "month") {
      this.setState({ monthDate: activeStartDate });
    // this.props.onDateChange && this.props.onDateChange(activeStartDate);
    }
    this.setState({ activeStartDate });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleOpen = () => this.setState({ opened: !this.state.opened });

  handleTabChange = (tab) => {
    if(tab === 0) {
      this.props.onModeChange && this.props.onModeChange("daily");
    }
    else {
      this.props.onModeChange && this.props.onModeChange("monthly");
    }
  }

  onViewChange = ({ view }) => {
    this.setState({ view });
  }

  render() {
    const chipDate = [
      { icon: <IconExercise />, text: "Exercise" },
      { icon: <IconMq />, text: "MQ Measurement" },
      { icon: <IconFes />, text: "Bioelectric" },
      // { icon: <IconRehab />, text: "Rehabilitation" }
    ];
    return (
      // <MidCard
      //   icon={<EventIcon fontSize="large" color="primary" />}
      //   title="Calendar"
      //   loading={this.state.loading}
      //   style= {{ width: "40%", marginRight: 0 }}
      //   center
      // >
      <div style= {{ width: "40%", marginRight: 3, border: "1px solid gray", height: 550, overflow: "hidden" }}>
        <div style={{ height: 34, display: "flex", alignItems: "center", padding: "2px 6px 2px 6px", marginBottom: -1 }}>
          <div/><EventIcon style={{ marginRight: 2 }} color="primary"/><div style={{ marginRight: 5 }}>Calendar</div><Spinner visible={this.state.loading}/>
        </div>
        <TabSelector preLoad fitWidth style={{ height: 516, marginLeft: -1, marginRight: -1 }} onTabChange={this.handleTabChange} tabs={[
          {
            name: "일별",
            component: <>
              <div className="calendar">
                <Calendar
                  key={new Date().getTime()}
                  tileClassName="tile"
                  value={this.state.date}
                  tileContent={this.dayTile}
                  tileDisabled={this.disableTile}
                  formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})}
                  onClickDay={this.onClickDay}
                  onActiveStartDateChange={this.onActiveDateChange}
                  activeStartDate={this.state.activeStartDate}
                  calendarType="US"
                  onViewChange={this.onViewChange}
                  view={this.state.view}
                />
              </div>
              <div className="chipgroup">
                {chipDate.map((item, i) => (
                  <Chip
                    key={i}
                    icon={item.icon}
                    label={item.text}
                    variant="outlined"
                    style={{ margin: 0.5 }}
                  />
                ))}
              </div>
            </>
          },
          {
            name: "월별",
            component: <>
              <div className="calendar">
                <Calendar
                  key={new Date().getTime()}
                  tileClassName="tile"
                  value={this.state.monthDate}
                  tileContent={this.dayTile}
                  formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})}
                  onClickMonth={(date) => {this.setState({ monthDate: date, activeStartDate: date }); this.props.onMonthChange && this.props.onMonthChange(date);}}
                  // onActiveStartDateChange={this.onActiveDateChange}
                  calendarType="US"
                  maxDetail="year"
                />
              </div>
            </>
          }
        ]}
        
        />
        
      </div>
    );
  }
}
