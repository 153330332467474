import React, { Component, useState } from "react";
import springapi from "../util/springapi";
import * as d3 from "d3";
import ExerciseView from "./ExerciseView";
import TabSelector from "../uicomponent/TabSelector";
import SignalProcessingView from "./SignalProcessingView";
import AnalysisView from "./AnalysisView";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { Link } from "react-router-dom";
// import dsp from "../util/dsp";
const fft = require("fft-js").fft;
const FFTUtil = require("../util/FFTUtil").default;

const threshold = 120;

export default class V2EmgView extends Component {
  svgId = new Date().getTime();
  state = {
    loading: false,
    data: [],
    firstData: {},
    secondData: {},
    emg: [],
    balance: [],
    isFatigue: false,
    isBalance: false,
    fatigueMin: 1000,
    fatigueMax: 0,
    rmsMax: 0,
    fullData: {},
    csvComponent: <></>
  };

  componentDidUpdate(prevProps, prevState) {
    this.props.behavior_id !== null && prevProps.behavior_id !== this.props.behavior_id &&
      this.fetch();
  }

  componentDidMount() {
    this.props.behavior_id && this.fetch();
  }

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const newState = { loading: false, data: [], firstData: {}, secondData: {}, balance: [], isFatigue: false, isBalance: false, fatigueMin: 1000, fatigueMax: 0, rmsMax: 0, csvComponent: <></>, fullData: {} };

      try {
        // const res = await restapi.getRawData(this.props.behavior_id);
        const emgList = await springapi.getEmgList(this.props.behavior_id);
        console.log(emgList)
        const exercise = this.props.data.find(d => d.data.id === this.props.behavior_id);
        newState.fullData = exercise.data;
        if (emgList && emgList.response) {
          // remove FES signal(data=-1)
          for(let i = 0; i < emgList.response.length; i++) {
            const filteredData = filter(emgList.response[i].emg.filter(data => data >= 0));
            // console.log(filteredData[2])
            const mean = _.mean(filteredData[0]);
            const emg = filteredData[0].map((element) => (element - 123) / 255 * 3300);
            let fatigue = [];
            if(exercise.data.isBoth) {
              fatigue = exercise.fatigue.find(element => element.moduleNum === emgList.response[i].moduleNum).value;
              newState.fatigueMin = d3.min(fatigue.concat(newState.fatigueMin));
              newState.fatigueMax = d3.max(fatigue.concat(newState.fatigueMax));
            }
            // newState.fatigue = await springapi.fatigue(filteredData[1].map((element) => {return filteredData[0].slice(element[0], element[1]);}));
            const rms = await springapi.rms(filteredData[0]);
            newState.rmsMax = d3.max(rms.concat(newState.rmsMax));
            newState.data.push({ moduleNum: emgList.response[i].moduleNum, emg, fatigue, rms, emgSlice: filteredData[1], area: emgList.response[i].area, side: emgList.response[i].side });
          }
          if(exercise.data.isBalance) {
            newState.isBalance = true;
            const balanceMax = d3.max(exercise.balance.map((v) => v.value));
            exercise.balance.forEach(element => {
              newState.balance.push({moduleNum: element.moduleNum, value: parseFloat((element.value / balanceMax * 100).toFixed(2))});
              // newState.balanceX.push(element.moduleNum);
            });
          }
          if(exercise.data.isBoth) {
            newState.isFatigue = true;
          }
          newState.data.sort((a, b) => a.moduleNum - b.moduleNum);
          if(newState.data.length >= 2) {
            newState.firstData = newState.data[0];
            newState.secondData = newState.data[1];
          }

          if(emgList.response[0]) {
            const data = emgList.response;
            let csvComponent;
            if(data[0].exerciseDirection === "") {
              const csv = this.emg2csv(data.map((v) => v.moduleNum), data.map((v) => v.emg));
              csvComponent = <div style={{ height: 30, marginBottom: -30 }}>
                <CSVLink data={csv} title="Download CSV file" filename={`${exercise.data.dateTime}_${exercise.data.exerciseName}`} enclosingCharacter="">
                  <button style={{ width: 80 }}>CSV</button>
                </CSVLink>
              </div>;
            }
            else {
              const left = [], right = [];
              data.forEach((v, i) => {
                if(v.exerciseDirection === "left") {
                  left.push(i);
                }
                else {
                  right.push(i);
                }
              });
              const leftCsv = this.emg2csv(left.map((i) => data[i].moduleNum), left.map((i) => data[i].emg));
              const rightCsv = this.emg2csv(right.map((i) => data[i].moduleNum), right.map((i) => data[i].emg));
              csvComponent = <div style={{ height: 60, marginBottom: -60 }}>
                {left.length > 0 &&
                  <CSVLink data={leftCsv} title="Download CSV file" filename={`${exercise.data.dateTime}_${exercise.data.exerciseName}_left`} enclosingCharacter="">
                    <button style={{ width: 80, marginBottom: 5 }}>CSV Left</button>
                  </CSVLink>
                }
                {right.length > 0 &&
                  <CSVLink data={rightCsv} title="Download CSV file" filename={`${exercise.data.dateTime}_${exercise.data.exerciseName}_right`} enclosingCharacter="">
                    <button style={{ width: 80 }}>CSV Right</button>
                  </CSVLink>
                }
              </div>;
            }
            newState.csvComponent = csvComponent;
          }
        }
      } catch (error) {
        console.log(error);
      }

      this.setState(newState);
    });
  };

  emg2csv(moduleNum, emg) {
    const csvData = [["Time(s)", ...moduleNum.map((v) => `Module ${v}`)]];
    const maxLength = _.max(emg.map((v) => v.length));
    for(let i = 0; i < maxLength; i++) {
      const tick = [(i / 1000).toFixed(3), ...emg.map((v) => v.length <= i ? null : v[i])];
      csvData.push(tick);
    }
    return csvData;
  }

  render() {
    const { firstData, secondData, data, csvComponent } = this.state;
    return (
      <div style={{ paddingBottom: 3 }}>
        <TabSelector style={{ marginTop: 3 }} fitWidth isLoading={this.state.loading} tabs={[
          {
            name: "Overview",
            component: <div style={{ display: "flex" }}>
              <div style={{ marginLeft: 10, width: 70 }}>
                {!this.state.loading && csvComponent}
                {/* {!this.state.loading && <div style={{ marginTop: 40, width: 300 }}><button onClick={() => {
                  localStorage.setItem("exerciseId", this.props.behavior_id);
                  window.open("/analysis", "_blank");
                }}>Open exoAnalysis</button></div>} */}
                <div style={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", marginTop: 69.4, marginBottom: 0, height: 393 }}>
                  <h5>EMG</h5>
                  <h6 style={{ marginTop: -10 }}>[uV]</h6>
                </div>
                <div style={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", marginBottom: 0, height: 250 }}>
                  <h5>RMS</h5>
                  <h6 style={{ marginTop: -10 }}>[uV]</h6>
                </div>
                {this.state.isFatigue && (
                  <div style={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", marginBottom: 0, height: 250 }}>
                    <h5>MDF</h5>
                    <h6 style={{ marginTop: -10 }}>[Hz]</h6>
                  </div>
                )}
                {this.state.balance.length >= 2 && (
                  <h5 style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 0, height: 346 }}>Balance</h5>
                )}
              </div>
              <div style={{ flexGrow: 1 }}>
                <div style={{ display: "flex" }}>
                  {this.state.data.length >= 2 && (
                    <>
                      <ExerciseView moduleNum={firstData.moduleNum} emgData={firstData.emg}
                        rmsData={firstData.rms} rmsMax={this.state.rmsMax}
                        fatigueData={firstData.fatigue} fatigueMin={this.state.fatigueMin} fatigueMax={this.state.fatigueMax} emgSlice={firstData.emgSlice}
                        graphBool={{ rms: true, fatigue: this.state.isFatigue }}
                        style={{ width: "50%", paddingBottom: 40 }}/>
                      <ExerciseView moduleNum={secondData.moduleNum} emgData={secondData.emg}
                        rmsData={secondData.rms} rmsMax={this.state.rmsMax}
                        fatigueData={secondData.fatigue} fatigueMin={this.state.fatigueMin} fatigueMax={this.state.fatigueMax} emgSlice={secondData.emgSlice}
                        graphBool={{ rms: true, fatigue: this.state.isFatigue }}
                        style={{ width: "50%", paddingBottom: 40 }}/>
                    </>
                  )}
                  {this.state.data.length === 1 && (
                    <ExerciseView moduleNum={data[0].moduleNum} emgData={data[0].emg}
                      rmsData={data[0].rms} rmsMax={this.state.rmsMax}
                      fatigueData={data[0].fatigue} fatigueMin={this.state.fatigueMin} fatigueMax={this.state.fatigueMax} emgSlice={data[0].emgSlice}
                      graphBool={{ rms: true, fatigue: this.state.isFatigue }}
                      style={{ display: "inline-block", width: "100%" }}/>
                  )}
                </div>
                {this.state.balance.length >= 2 && (
                  <div style={{ position: "relative", height: 306, marginTop: 20, marginBottom: 20 }}>
                    {/* {`${this.state.balance[0].value}`}{`${this.state.balance[1].value}`} */}
                    <h4 style={{ position: "absolute", top: this.state.balance[0].value >= 96 ? 3 : 303 - this.state.balance[0].value * 3 , right: "50%", transform: this.state.balance[0].value >= 96 ? "translate(-50px, 0)" : "translate(-50px, -50%)" }}>
                      {this.state.balance[0].value === 100 ? "왼쪽의 힘이 100이라면" : `왼쪽의 힘은 ${this.state.balance[0].value}입니다`}
                    </h4>
                    <img src="/resource/body_fill.png" height="100%" style={{ position: "absolute", left: "50%", transform: "translate(-50%, 0)", zIndex: 1 }}/>
                    <div style={{ position: "absolute", right: "50%", height: 303 - this.state.balance[0].value * 3, width: 50, backgroundColor: "white", zIndex: 2 }}/>
                    <div style={{ position: "absolute", left: "50%", height: 303 - this.state.balance[1].value * 3, width: 50, backgroundColor: "white", zIndex: 2 }}/>
                    <img src="/resource/body_frame.png" height="100%" style={{ position: "absolute", left: "50%", transform: "translate(-50%, 0)", zIndex: 3 }}/>
                    <h4 style={{ position: "absolute", top: this.state.balance[1].value >= 96 ? 3 : 303 - this.state.balance[1].value * 3 , left: "50%", transform: this.state.balance[1].value >= 96 ? "translate(50px, 0)" : "translate(50px, -50%)" }}>
                      {this.state.balance[0].value !== 100 && this.state.balance[1].value === 100 ? "오른쪽의 힘이 100이라면" : `오른쪽의 힘은 ${this.state.balance[1].value}입니다`}
                    </h4>
                    {/* <h4 style={{ display: "flex", alignItems: "center", position: "absolute", height: 306, left: "75%", transform: "translate(-50%, 0)" }}>{`${this.state.balance[1].value}%`}</h4> */}
                  </div>
                )}
              </div>
            </div>
          },
          {
            name: "EMG Signal Processing",
            component: <SignalProcessingView dataList={data}/>
          },
          {
            name: "Analysis",
            component: <AnalysisView dataList={data} userId={this.props.userId} exerciseId={this.props.behavior_id} data={this.state.fullData} today={this.props.today}/>
          }
        ]}/>
      </div>
    );
  }
}

function filter(emgData) {
  const count = emgData.length;
  const frameSize = 64;
  const kPoint = parseInt(frameSize / 2);

  var I = [];
  const arrLength = parseInt(count / parseInt(frameSize / 2));
  var G = [];
  var psi = [];
  var tau = new Array(arrLength).fill(0);
  var dec = [];
  
  // GET I
  for(let i = 0; i < parseInt(count - frameSize); i += parseInt(frameSize / 2)) {
      const T1 = getFFT(emgData, i, frameSize);
      // T1.forEach((element, index) => {
      //     T1[index] = Math.pow(element, 2);
      // });
      I.push(T1.map((element) => {return Math.pow(element, 2)}));
  }
  for(let i = I.length; i < arrLength; i++) {
    I.push(new Array(parseInt(frameSize / 2)).fill(0));
  }

  // GET G
  for(let i = 0; i < arrLength; i++) {
      var tempGProduct = 1;
      for(let j = 0; j < kPoint; j++) {
          tempGProduct *= I[i][j] + 1;
      }
      G.push(Math.pow(tempGProduct, 1 / kPoint));
  }

  // GET psi
  for(let i = 0; i < arrLength; i++) {
      psi.push(Math.log(G[i]));
  }

  // GET tau
  const iMovingWindow = 2;
  for(let i = iMovingWindow; i < arrLength - 1; i++) {
      tau[i + 1] = -Math.log(Math.exp(-tau[i] + (Math.exp(-psi[i]) - Math.exp(-psi[i - iMovingWindow])) / iMovingWindow));
      if(tau[i + 1] < 0) {
          tau[i + 1] = 0;
      }
  }

  const dePsi = psi.slice().sort();
  const LdePsireSize = parseInt(dePsi.length / 100) < 1 ? 1 : parseInt(dePsi.length / 100);

  var tempMax = 0;
  var tempMin = 0;
  for(let i = 0; i < LdePsireSize; i++) {
      tempMin += dePsi[i];
      tempMax += dePsi[dePsi.length - i - 1];
  }
  tempMax /= LdePsireSize;
  tempMin /= LdePsireSize;

  for(let i = 0; i < arrLength; i++) {
      tau[i] = (tempMax + tempMin) / 2 - tau[i];
  }
  
  // GET dec
  for(let i = 0; i < parseInt(arrLength * frameSize / 2); i++) {
      const temp = parseInt(i / (frameSize / 2));
      dec.push(psi[temp] > tau[temp] ? 1 : 0);
  }
  
  var min = 0;
  var sum = 0;
  var avg = 0;
  for(let i = 0; i < dec.length; i++) {
    if(dec[i] === 0) {
      min = i;
      break;
    }
  }
  for(let i = min; i < dec.length; i++) {
    sum += emgData[i];
    if(dec[i] === 1) {
      avg = sum / (i - min);
      break;
    }
  }

  var emgSlice = [];
  var flag = false;
  var index = 0;
  for(let i = 0; i < dec.length; i++) {
      if(dec[i] === 1 && !flag) {
          flag = true;
          index = i;
      }
      else if(dec[i] === 0 && flag) {
          flag = false;
          // console.log(index, i);
          emgSlice.push([index, i]);
      }
  }
  if(flag) {
      emgSlice.push([index, dec.length]);
  }

  for(let i = 0; i < emgSlice.length - 1; i++) {
    if(emgSlice[i + 1][0] - emgSlice[i][1] < 256) {
      emgSlice[i][1] = emgSlice[i + 1][1];
      emgSlice.splice(i + 1, 1);
      i--;
    }
  }
  emgSlice = emgSlice.filter((slice) => slice[1] - slice[0] >= 128);

  return [emgData, emgSlice, avg];
}

function getFFT(array, start, FFTSize) {
  const cutArray = array.slice(start, start + FFTSize);
  const cutLength = cutArray.length;
  var yfft = fft(cutArray);
  yfft = yfft.slice(0, parseInt(cutLength / 2));
  yfft[0] = 0
  for(let i = 1; i < yfft.length; i++) {
      yfft[i] = Math.sqrt(Math.pow(yfft[i][0] / cutLength, 2) + Math.pow(yfft[i][1] / cutLength, 2)) * 2;
  }
  return yfft;
}

