import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";
// import Plot from "react-plotly.js";
// import { Chart as ChartJs } from "chart.js/auto";
// import { Chart, Line } from "react-chartjs-2";
import _ from "lodash";
import SignalProcessing from "../util/SignalProcessing";
import { useD3 } from "../util/useD3";
import Plot from "react-plotly.js";
import GraphColors from "../colors/graph";
import ModuleText from "../uicomponent/ModuleText";

const SignalProcessingView = (props) => {
  const { dataList } = props;
  const [moduleNums, setModuleNums] = useState(dataList.map((data) => data.moduleNum))
  const [processList, setProcessList] = useState([{
    name: "Raw EMG",
    type: null,
    dataList: dataList.map(({ emg: data }) => {
      let min = _.min(data) * 1.05;
      let max = _.max(data) * 1.05;
      const absMax = Math.max(Math.abs(min), Math.abs(max));
      min = -absMax;
      max = absMax;
      return { min, max, data };
    }),
    unit: {
      numerator: ["uV"],
      denominator: []
    }
  }]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    resetProcess();
    setModuleNums(dataList.map((data) => data.moduleNum));
  }, [dataList]);

  const processingTypes = [
    {
      name: "Rectification",
      ref: "Rectification"
    },
    {
      name: "Smoothing",
      sub: [
        {
          name: "Mean",
          ref: "SmoothingMean"
        },
        {
          name: "Mean Absolute",
          ref: "SmoothingMeanAbsolute"
        },
        {
          name: "RMS",
          ref: "SmoothingRms"
        }
      ]
    },
    {
      name: "Normalization",
      sub: [
        {
          name: "Manual Value",
          ref: "NormalizationManualValue"
        },
        {
          name: "Mean Value",
          ref: "NormalizationMeanValue"
        },
        {
          name: "Peak Value",
          ref: "NormalizationPeakValue"
        }
      ]
    },
    {
      name: "Filtering",
      sub: [
        {
          name: "Median",
          ref: "FilteringMedian"
        }
      ]
    },
    {
      name: "Integral",
      ref: "Integral"
    },
    {
      name: "Derivative",
      ref: "Derivative"
    }
  ]

  const processingFunctions = {
    Rectification: {
      name: "Rectification",
      function: SignalProcessing.Rectification
    },
    SmoothingMean: {
      name: "Smoothing(Mean)",
      inputs: [
        {
          name: "Window Size",
          value: 50,
          unit: "points"
        }
      ],
      function: SignalProcessing.Smoothing.Mean
    },
    SmoothingMeanAbsolute: {
      name: "Smoothing(Mean Absolute)",
      inputs: [
        {
          name: "Window Size",
          value: 50,
          unit: "points"
        }
      ],
      function: SignalProcessing.Smoothing.MeanAbsolute
    },
    SmoothingRms: {
      name: "Smoothing(RMS)",
      inputs: [
        {
          name: "Window Size",
          value: 50,
          unit: "points"
        }
      ],
      function: SignalProcessing.Smoothing.RMS
    },
    NormalizationManualValue: {
      name: "Normalization(Manual Value)",
      inputs: [
        {
          name: "Value",
          value: 1000
        }
      ],
      function: SignalProcessing.Normalization.ManualValue,
      fixedUnit: {
        numerator: ["%"],
        denominator: []
      }
    },
    NormalizationMeanValue: {
      name: "Normalization(Mean Value)",
      function: SignalProcessing.Normalization.MeanValue,
      fixedUnit: {
        numerator: ["%"],
        denominator: []
      }
    },
    NormalizationPeakValue: {
      name: "Normalization(Peak Value)",
      inputs: [
        {
          name: "Window Size",
          value: 1000,
          unit: "ms"
        }
      ],
      function: SignalProcessing.Normalization.PeakValue,
      fixedUnit: {
        numerator: ["%"],
        denominator: []
      }
    },
    FilteringMedian: {
      name: "Filtering(Median)",
      inputs: [
        {
          name: "Window Size",
          value: 3,
          unit: "points"
        }
      ],
      function: SignalProcessing.Filtering.Median
    },
    Integral: {
      name: "Integral",
      function: SignalProcessing.Integral,
      numerator: ["s"]
    },
    Derivative: {
      name: "Derivative",
      function: SignalProcessing.Derivative,
      denominator: ["s"]
    }
  }

  function resetProcess() {
    setIndex(0);
    setProcessList([{
      name: "Raw EMG",
      type: null,
      dataList: dataList.map(({ emg: data }) => {
        let min = _.min(data) * 1.05;
        let max = _.max(data) * 1.05;
        const absMax = Math.max(Math.abs(min), Math.abs(max));
        min = -absMax;
        max = absMax;
        return { min, max, data };
      }),
      unit: {
        numerator: ["uV"],
        denominator: []
      }
    }]);
  }

  const TypeSelector = () => {
    const [currentType, setCurrentType] = useState(null);
    const [inputValues, setInputValues] = useState([]);

    const Input = ({i, input}) => {
      const [inputValue, setInputValue] = useState(inputValues[i]);
      return (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
          {input.name}
          <div style={{ flexGrow: 1 }}/>
          <div style={{ display: "flex", width: 100 }}>
            <input value={inputValue} onChange={(event) => {
              const value = Number(event.target.value.replace(/\D/g, ""));
              setInputValue(value);
              inputValues[i] = value;
            }} style={{ width: "100%" }}/>
            {input.unit && 
              <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>{input.unit}</div>
            }
          </div>
        </div>
      );
    }

    useEffect(() => {
      if(currentType) {
        const list = [];
        if(processingFunctions[currentType].inputs) {
          processingFunctions[currentType].inputs.map((input) => {
            list.push(input.value);
          })
        }
        setInputValues(list);
      }
    }, [currentType]);

    function setUnit(prevUnit, { fixedUnit, numerator, denominator }) {
      if(fixedUnit) {
        return fixedUnit;
      }
      let i;
      if(numerator) {
        numerator.forEach((n) => {
          i = prevUnit.denominator.findIndex((v) => v === n);
          if(i === -1) {
            prevUnit.numerator.push(n);
          }
          else {
            prevUnit.denominator.splice(i, 1);
          }
        });
      }
      if(denominator) {
        denominator.forEach((d) => {
          i = prevUnit.numerator.findIndex((v) => v === d);
          if(i === -1) {
            prevUnit.denominator.push(d);
          }
          else {
            prevUnit.numerator.splice(i, 1);
          }
        });
      }
      return prevUnit;
    }

    return (
      <div style={{ height: 640, width: 250, borderRight: "1px solid", flexShrink: 0 }}>
        {processingTypes.map((type, i) => {
          if(type.sub) {
            return (
              <div key={i} style={{ borderBottom: "1px solid" }}>
                <div style={{ padding: 10, backgroundColor: "#e0e0e0" }}>
                  {type.name}
                </div>
                {type.sub.map((sub, i) => {
                  let style = {};
                  if(currentType === sub.ref) {
                    style = { backgroundColor: "#007bff", color: "white" }
                  }
                  return (
                    <div key={i} className="functionButton" style={{ ...style, padding: 10, paddingLeft: 30 }}
                      onClick={() => {setCurrentType(sub.ref)}}>
                      {sub.name}
                    </div>
                  );
                })}
              </div>
            );
          }
          else {
            let style = {};
            if(currentType === type.ref) {
              style = { backgroundColor: "#007bff", color: "white" }
            }
            return (
              <div key={i} className="functionButton" style={{ ...style, borderBottom: "1px solid black", padding: 10 }}
                onClick={() => {setCurrentType(type.ref)}}>
                {type.name}
              </div>
            );
          }
        })}
        {currentType &&
          <div style={{ padding: 10 }}>
            {processingFunctions[currentType].inputs && processingFunctions[currentType].inputs.map((input, i) => {
              return (
                <Input key={i} i={i} input={input}/>
              );
            })}
            <button style={{ float: "right" }} onClick={() => {
              const unit = setUnit(processList[index].unit, processingFunctions[currentType]);
              setProcessList([...processList, {
                name: processingFunctions[currentType].name,
                type: currentType,
                inputs: inputValues,
                dataList: processList[index].dataList.map(({data}) => {
                  const newData = processingFunctions[currentType].function(data, ...inputValues);
                  let min = _.min(newData) * 1.05;
                  let max = _.max(newData) * 1.05;
                  if(min >= 0 && max >= 0) {
                    min = 0;
                  }
                  else if(min <= 0 && max <= 0) {
                    max = 0;
                  }
                  else {
                    const absMax = Math.max(Math.abs(min), Math.abs(max));
                    min = -absMax;
                    max = absMax;
                  }
                  return { min, max, data: newData };
                }),
                unit
              }]);
              setIndex(index + 1);
            }}>Process</button>
          </div>
        }
      </div>
    );
  }

  const Graph = ({ moduleNum, min, max, data }) => {
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);

    var margin = {top: 15, right: 0, bottom: 15, left: 30},
    width = 500,
    height = 160;

    function responsivefy(svg) {
      var container = d3.select(svg.node().parentNode),
        width = parseInt(svg.style("width"))
        // height = parseInt(svg.style("height")),
        // aspect = width / height;
      console.log(container);
      svg.attr("viewBox", `0 0 ${width} 160`)
        .attr("preserveAspectRatio", "xMinYMid")
        .call(resize);

        function resize() {
          var targetWidth = parseInt(container.style("width"));
          console.log(targetWidth);
          svg.attr("width", targetWidth);
        }
    }

    const svgRef = useD3((svg) => {
      const x = d3.scaleLinear()
        .domain([0, data.length - 1])
        .range([margin.left, width - margin.right]);
      const y = d3.scaleLinear()
        .domain([min, max])
        .range([height - margin.bottom, margin.top]);

      const xAxis = d3.axisTop(x).tickValues([]);
      const yAxis = d3.axisRight(y);
      const topBorder = d3.axisBottom(x).tickValues([]);

      svg.append("g")
        .attr("transform", `translate(0, ${y(0)})`)
        .call(xAxis);
      svg.append("g")
        .attr("transform", `translate(${width - margin.right}, 0)`)
        .call(yAxis);

      svg.append("g")
      .attr("transform", `translate(0, ${margin.top})`)
        .call(topBorder);
      svg.append("g")
        .attr("transform", `translate(0, ${height - margin.bottom})`)
        .call(xAxis);
      svg.append("g")
        .attr("transform", `translate(${margin.left}, 0)`)
        .call(yAxis.tickValues([]));

      svg
        .append("path")
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", "blue")
          .attr("stroke-width", 1)
          .attr("class", "line")
          .attr("d", d3.line()
            .x((d, i) => x(i))
            .y((d) => y(d))
          );

      // svg.call(responsivefy);
    }, [data.length]);

    return (
      <div>
        <svg ref={svgRef} style={{ width: "100%", height: 160 }}/>
      </div>
    );
  }

  const PlotlyGraph = ({ moduleNum, min, max, data, unit: { numerator, denominator } }) => {
    let unitString = numerator.length === 0 && denominator.length !== 0 ? "1" : "";
    numerator.forEach((n, i) => {
      if(i) {
        unitString += "*"
      }
      unitString += n;
    });
    denominator.forEach((d) => {
      unitString += "/" + d;
    });

    return (
      <Plot
        data={[
          {
            x: _.range(data.length).map((v) => v / 1000),
            y: data,
            showlegend: false,
            hoverinfo: "none",
            line: {
              color: GraphColors.line[moduleNum],
              width: 0.5
            }
          }
        ]}
        layout={{
          xaxis: {
            fixedrange: true,
            range: [0, data.length / 1000],
            title: "Time [s]"
          },
          yaxis: {
            fixedrange: true,
            range: [min, max],
            title: `[${unitString}]`
          },
          margin: {
            t: 10,
            l: 60,
            r: 10,
            b: 40
          }
          
        }}
        useResizeHandler={true}
        onError={(err) => console.log(err)}
        config={{ responsive: true, displayModeBar: false }}
        style={{ width: "100%", height: 140 }}
      />
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <TypeSelector/>
      <div style={{ width: "100%" }}>
        {processList[index].dataList.map(({ min, max, data }, i) => {
          return (
            <div key={i} style={{ padding: 10, height: 160 }}>
              <ModuleText moduleNum={moduleNums[i]}/>
              {/* <Graph moduleNum={moduleNums[i]} min={min} max={max} data={data}/> */}
              <PlotlyGraph moduleNum={moduleNums[i]} min={min} max={max} data={data} unit={processList[index].unit}/>
            </div>
          );
        })}
        {/* <button style={{ marginLeft: 30 }} onClick={resetProcess}>Reset</button> */}
      </div>
      <div style={{ width: 250, height: 640, borderLeft: "1px solid", flexShrink: 0 }}>
        <div style={{ display: "flex", alignItems: "center", padding: 10, borderBottom: "1px solid" }}>
          <b>Process Steps</b>
          <div style={{ flexGrow: 1 }}/>
          <button onClick={resetProcess}>Reset</button>
        </div>
        <div style={{ height: 592, overflowX: "hidden", overflowY: "auto" }}>
          {processList.map(({ name, type, inputs }, i) => {
            return (
              <div key={i}>
                {i !== 0 &&
                  <div style={{ height: 40, marginTop: -20, marginBottom: -20, padding: 0, fontSize: 25, transform: "translate(120px, 0)" }}>&#8595;</div>
                }
                <div style={{ padding: 10, borderBottom: "1px solid", backgroundColor: i === index ? "#88bbff" : "white" }}>
                  <div>{name}</div>
                  {inputs && processingFunctions[type].inputs && processingFunctions[type].inputs.map(({ name, value, unit }, j) => {
                    return (
                      <div key={j}>
                        - {name}: {inputs[j]} {unit}
                      </div>
                    );
                  })
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SignalProcessingView;