import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import Analysis from "../util/Analysis";
import { GlobalContext, useGlobal } from "./context/global";
import Plot from "react-plotly.js";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, Col, ListGroupItem, Row } from "reactstrap";
import GraphColors from "../colors/graph";
import Calendar from "react-calendar";
import springapi from "../util/springapi";

/**
 * 
 * @param {{ dataList: [], dayList: [] }} param0 
 * @returns 
 */
const AnalysisView = ({ dataList, userId, exerciseId, data, today }) => {
  const [currentType, setCurrentType] = useState("");
  const [currentComponent, setCurrentComponent] = useState(<></>);
  const { print, setPrint, paramsTemp, setParamsTemp } = useGlobal();

  useEffect(() => {
    if(dataList) {
      if(paramsTemp[exerciseId] === undefined) {
        const params = {};
        dataList.forEach(({ moduleNum }) => {
          params[moduleNum] = [];
        });
        setParamsTemp({ ...paramsTemp, [exerciseId]: params });
      }
    }
  }, [dataList]);

  function analyze(dataList, params) {
    setPrint(<Analysis type={currentType} dataList={dataList} params={params} data={data}/>);
  }

  const AmplitudeProbability = () => {
    return (
      <div>
        <button onClick={() => analyze(dataList)}>Analyze</button>
      </div>
    );
  };

  const AverageActivation = () => {
    const [params, setParams] = useState({});
    const [mode, setMode] = useState("everyOther");

    useEffect(() => {
      dataList.forEach(({ moduleNum }) => {
        params[moduleNum] = [];
      });
      setParams({...params});
    }, [dataList]);

    function changeMode(event) {
      setMode(event.target.value);
    }

    function getSlices() {
      const keys = Object.keys(params);
      const map = {};
      for(let i = 0; i < keys.length; i++) {
        const sliceList = [];
        if(mode === "every") {
          params[keys[i]].forEach((v, j, arr) => {
            if(j > 0 && j % 2 === 0) {
              sliceList.push([arr[j - 2], arr[j - 1], arr[j]]);
            }
          });
        }
        else {
          params[keys[i]].forEach((v, j, arr) => {
            if(j % 3 === 2) {
              sliceList.push([arr[j - 2], arr[j - 1], arr[j]]);
            }
          });
        }
        map[keys[i]] = sliceList;
      }
      console.log(map)
      return map;
    }

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", marginLeft: 10 }} onChange={changeMode}>
            <label style={{ display: "flex", margin: 0 }}>
              <input type="radio" name="mode" value="every" checked={mode === "every"} onChange={() => setMode("every")}/>
              <div style={{ marginLeft: 5, display: "flex", alignItems: "center", cursor: "default" }}>Every Section</div>
            </label>
            <label style={{ display: "flex", margin: 0 }}>
              <input type="radio" name="mode" value="everyOther" checked={mode === "everyOther"} onChange={() => setMode("everyOther")} style={{ marginLeft: 10 }}/>
              <div style={{ marginLeft: 5, display: "flex", alignItems: "center", cursor: "default" }}>Every Other Section</div>
            </label>
          </div>
          <button style={{ marginLeft: 10 }} onClick={() => analyze(dataList, getSlices())}>Analyze</button>
        </div>
        {dataList.map(({ moduleNum, emg }, i) => {
          let shapes = [];
          if(params[moduleNum]) {
            if(mode === "every") {
              params[moduleNum].forEach((v, j, arr) => {
                if(j > 0 && parseInt((j + 1) / 2) <= parseInt((arr.length - 1) / 2)) {
                  shapes.push({
                    type: "rect",
                    x0: arr[j], x1: arr[j - 1], y0: -2000, y1: 2000,
                    fillcolor: GraphColors.section[(j + 1) % 2],
                    opacity: 0.5,
                    layer: "above"
                  });
                }
              });
              shapes = shapes.concat(params[moduleNum].map((v, j) => {
                return {
                  type: "line",
                  x0: v, x1: v, y0: -2000, y1: 2000,
                  line: {
                    width: 1,
                    dash: "solid"
                  },
                  layer: "above"
                }
              }));
            }
            else {
              params[moduleNum].forEach((v, j, arr) => {
                if(parseInt((j + 2) / 3) <= parseInt((arr.length) / 3)) {
                  if(j % 3 === 1) {
                    shapes.push({
                      type: "rect",
                      x0: arr[j], x1: arr[j - 1], y0: -2000, y1: 2000,
                      fillcolor: GraphColors.section[0],
                      opacity: 0.5,
                      layer: "above"
                    });
                  }
                  else if(j % 3 === 2) {
                    shapes.push({
                      type: "rect",
                      x0: arr[j], x1: arr[j - 1], y0: -2000, y1: 2000,
                      fillcolor: GraphColors.section[1],
                      opacity: 0.5,
                      layer: "above"
                    });
                  }
                }
              });
              shapes = shapes.concat(params[moduleNum].map((v, j) => {
                return {
                  type: "line",
                  x0: v, x1: v, y0: -2000, y1: 2000,
                  line: {
                    width: 1,
                    dash: "solid"
                  },
                  layer: "above"
                }
              }));
            }
          }

          return (
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Plot
                data={[
                  {
                    x: _.range(emg.length).map((v) => v / 1000),
                    y: emg,
                    showlegend: false,
                    hoverinfo: "x",
                    line: {
                      color: GraphColors.line[moduleNum],
                      width: 1
                    }
                  }
                ]}
                layout={{
                  hovermode: "x",
                  xaxis: {
                    fixedrange: true,
                    range: [0, emg.length / 1000],
                    spikemode: "across",
                    spikecolor: "black",
                    spikedash: "solid",
                    spikesnap: "cursor",
                    spikethickness: 1
                  },
                  yaxis: {
                    fixedrange: true,
                    range: [_.min(emg), _.max(emg)]
                  },
                  margin: {
                    t: 20,
                    l: 40,
                    r: 30,
                    b: 30
                  },
                  shapes
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                onClick={(event) => {
                  if(params[moduleNum].find((v) => v === event.points[0].x) === undefined) {
                    params[moduleNum].push(event.points[0].x);
                    params[moduleNum].sort((a, b) => {
                      if(a < b) return -1;
                      if(a === b) return 0;
                      return 1;
                    });
                    setParams({...params});
                  }
                }}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
              <Button onClick={() => {params[moduleNum] = []; setParams({...params});}} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 30, marginRight: 30, padding: 5 }}><RefreshIcon/>Reset</Button>
            </div>
          );
        })}
      </div>
    );
  };

  const FrequencyFatigue = () => {
    return (
      <div>
        <button onClick={() => analyze(dataList)}>Analyze</button>
      </div>
    );
  };

  const IsometricContractionAnalysis = () => {
    const [params, setParams] = useState({ mvic: [] });
    const [mvicData, setMvicData] = useState([]);
    const [mvicDate, setMvicDate] = useState(today);
    const [mvicList, setMvicList] = useState([]);
    const [mvicId, setMvicId] = useState(null);
    const [mvicDataList, setMvicDataList] = useState([]);
    const [mvicModuleNum, setMvicModuleNum] = useState(null);

    useEffect(() => {
      getMvicList(mvicDate);
    }, []);

    useEffect(() => {
      dataList.forEach(({ moduleNum }) => {
        params[moduleNum] = [];
      });
      setParams({ ...params });
    }, [dataList]);
    
    // useEffect(() => {
    //   setParamsTemp({ ...paramsTemp, [exerciseId]: { ...params } });
    // }, [params]);

    useEffect(() => {
      const data = mvicDataList.find((d) => d.moduleNum === mvicModuleNum);
      if(data) {
        const mean = _.mean(data.emg);
        const emg = data.emg.map((element) => (element - mean) / 255 * 3300);
        setMvicData(emg);
      }
      setParams({ ...params, mvic: [] });
    }, [mvicId, mvicModuleNum]);

    function getSlices() {
      const keys = Object.keys(params);
      const map = {};
      for(let i = 0; i < keys.length; i++) {
        const sliceList = [];
        params[keys[i]].forEach((v, j, arr) => {
          if(j % 2 === 1) {
            sliceList.push([arr[j - 1], arr[j]]);
          }
        });
        map[keys[i]] = sliceList;
      }
      map["mvicData"] = mvicData;
      return map;
    }

    // function changeMode(event) {
    //   setMode(event.target.value);
    // }
    async function getMvicList(date) {
      const dateString = "" + date.getFullYear() + (date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + (date.getDate() >= 10 ? date.getDate() : ("0" + date.getDate()));
      const exercise = await springapi.getExercise(userId, dateString);
      if(exercise.response) {
        setMvicList(exercise.response);
      }
    }

    let shapes = [];
    if(params["mvic"]) {
      params["mvic"].forEach((v, j, arr) => {
        if(j % 2 === 1) {
          shapes.push({
            type: "rect",
            x0: arr[j], x1: arr[j - 1], y0: -2000, y1: 2000,
            fillcolor: "#ffff0088",
            layer: "above"
          });
        }
      });
      shapes = shapes.concat(params["mvic"].map((v, j) => {
        return {
          type: "line",
          x0: v, x1: v, y0: -2000, y1: 2000,
          line: {
            width: 1,
            dash: "solid"
          },
          layer: "above"
        }
      }));
    }
    
    return (
      <div>
        <div style={{ display: "flex", height: 220 }}>
          <Calendar
            tileClassName="tile"
            value={mvicDate}
            formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})}
            onClickDay={async(date) => {
              setMvicDate(date);
              await getMvicList(date);
            }}
            // onActiveStartDateChange={this.onActiveDateChange}
            // activeStartDate={this.state.activeStartDate}
            calendarType="US"
          />
          <div style={{ overflowY: "scroll", border: "1px solid black", minWidth: 380 }}>
            {mvicList.map((d, i) => {
              const time = d.data.dateTime.substring(8, 10) + ":" + d.data.dateTime.substring(10, 12) + ":" + d.data.dateTime.substring(12, 14);
              return (
                <ListGroupItem active={mvicId === d.data.id} tag="button" data-key={d.data.id} onClick={async() => {
                  setMvicId(d.data.id);
                  const data = await springapi.getEmgList(d.data.id);
                  if(data.response.length > 0) {
                    setMvicDataList(data.response);
                    setMvicModuleNum(data.response[0].moduleNum);
                  }
                }}
                action disabled={d.data.isInvalid} style={d.data.isInvalid ? { backgroundColor: "#CCCCCC" } : {}} key={i}>
                  <Row style={{ width: 360 }}>
                    <Col xs="9">{d.data.exerciseName}</Col>
                    {/* <Col xs="4">{totalTime}</Col> */}
                    <Col xs="3">{time}</Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </div>
          <div style={{ marginLeft: 10, marginTop: 10 }}>
            {mvicDataList.map((d, i) => {
              return (
                <label key={i} style={{ display: "flex" }}>
                  <input type="radio" name="moduleNum" value={d.moduleNum} checked={mvicModuleNum === d.moduleNum} onChange={() => setMvicModuleNum(d.moduleNum)}/>
                  <div style={{ marginLeft: 5 }}>{`Module ${d.moduleNum}`}</div>
                </label>
              );
            })}
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <b>MVIC Data</b>
          {mvicData.length > 0 ? <div style={{ display: "flex", alignItems: "center" }}>
            <Plot
              data={[
                {
                  x: _.range(mvicData.length).map((v) => v / 1000),
                  y: mvicData,
                  showlegend: false,
                  hoverinfo: "x",
                  line: {
                    color: "#078150",
                    width: 1
                  }
                }
              ]}
              layout={{
                hovermode: "x",
                xaxis: {
                  fixedrange: true,
                  range: [0, mvicData.length / 1000],
                  spikemode: "across",
                  spikecolor: "black",
                  spikedash: "solid",
                  spikesnap: "cursor",
                  spikethickness: 1
                },
                yaxis: {
                  fixedrange: true,
                  range: [_.min(mvicData), _.max(mvicData)]
                },
                margin: {
                  t: 20,
                  l: 40,
                  r: 30,
                  b: 30
                },
                shapes
              }}
              useResizeHandler={true}
              onError={(err) => console.log(err)}
              onClick={(event) => {
                if(params["mvic"].find((v) => v === event.points[0].x) === undefined) {
                  params["mvic"].push(event.points[0].x);
                  params["mvic"].sort((a, b) => {
                    if(a < b) return -1;
                    if(a === b) return 0;
                    return 1;
                  });
                  setParams({...params});
                }
              }}
              config={{ responsive: true, displayModeBar: false }}
              style={{ width: "100%", height: 250 }}
            />
            <Button onClick={() => {params["mvic"] = []; setParams({...params});}} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 30, marginRight: 30, padding: 5 }}><RefreshIcon/>Reset</Button>
          </div> : <div>Select MVIC Data</div>}
        </div>
        <div style={{ display: "flex" }}>
          {/* <div style={{ display: "flex", marginLeft: 10 }} onChange={changeMode}>
            <input type="radio" name="mode" value="every" checked={mode === "every"}/>
            <div style={{ marginLeft: 5, display: "flex", alignItems: "center", cursor: "default" }} onClick={() => setMode("every")}>Every Section</div>
            <input type="radio" name="mode" value="everyOther" checked={mode === "everyOther"} style={{ marginLeft: 10 }}/>
            <div style={{ marginLeft: 5, display: "flex", alignItems: "center", cursor: "default" }} onClick={() => setMode("everyOther")}>Every Other Section</div>
          </div> */}
          <button style={{ marginLeft: 10 }} disabled={params.mvic.length <= 1} onClick={() => analyze(dataList, getSlices())}>Analyze</button>
        </div>
        {dataList.map(({ moduleNum, emg }, i) => {
          let shapes = [];
          if(params[moduleNum]) {
            params[moduleNum].forEach((v, j, arr) => {
              if(j % 2 === 1) {
                shapes.push({
                  type: "rect",
                  x0: arr[j], x1: arr[j - 1], y0: -2000, y1: 2000,
                  fillcolor: "#ffff0088",
                  layer: "above"
                });
              }
            });
            shapes = shapes.concat(params[moduleNum].map((v, j) => {
              return {
                type: "line",
                x0: v, x1: v, y0: -2000, y1: 2000,
                line: {
                  width: 1,
                  dash: "solid"
                },
                layer: "above"
              }
            }));
          }

          return (
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Plot
                data={[
                  {
                    x: _.range(emg.length).map((v) => v / 1000),
                    y: emg,
                    showlegend: false,
                    hoverinfo: "x",
                    line: {
                      color: GraphColors.line[moduleNum],
                      width: 1
                    }
                  }
                ]}
                layout={{
                  hovermode: "x",
                  xaxis: {
                    fixedrange: true,
                    range: [0, emg.length / 1000],
                    spikemode: "across",
                    spikecolor: "black",
                    spikedash: "solid",
                    spikesnap: "cursor",
                    spikethickness: 1
                  },
                  yaxis: {
                    fixedrange: true,
                    range: [_.min(emg), _.max(emg)]
                  },
                  margin: {
                    t: 20,
                    l: 40,
                    r: 30,
                    b: 30
                  },
                  shapes
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                onClick={(event) => {
                  if(params[moduleNum].find((v) => v === event.points[0].x) === undefined) {
                    params[moduleNum].push(event.points[0].x);
                    params[moduleNum].sort((a, b) => {
                      if(a < b) return -1;
                      if(a === b) return 0;
                      return 1;
                    });
                    setParams({...params});
                  }
                }}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
              <Button onClick={() => {params[moduleNum] = []; setParams({...params});}} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 30, marginRight: 30, padding: 5 }}><RefreshIcon/>Reset</Button>
            </div>
          );
        })}
      </div>
    );
  };

  const PowerSpectrum = () => {
    return (
      <div>
        <button onClick={() => analyze(dataList)}>Analyze</button>
      </div>
    )
  };

  const StandardEMGAnalysis = () => {
    const [params, setParams] = useState({});
    const [mode, setMode] = useState("everyOther");
    const [referenceModuleNum, setReferenceModuleNum] = useState(dataList[0].moduleNum);

    // useEffect(() => {
    //   dataList.forEach(({ moduleNum }) => {
    //     params[moduleNum] = [];
    //   });
    //   setParams({...params});
    // }, [exerciseId]);

    useEffect(() => {
      console.log(exerciseId);
      if(dataList) {
        if(paramsTemp[exerciseId] === undefined) {
          const params = {};
          dataList.forEach(({ moduleNum }) => {
            params[moduleNum] = [];
          });
          setParamsTemp({ ...paramsTemp, [exerciseId]: params });
          setParams({ ...params });
        }
        else {
          setParams({ ...paramsTemp[exerciseId] });
        }
      }
    }, [dataList]);

    function getSlices() {
      console.log(exerciseId, params)
      const keys = Object.keys(params);
      const map = {};
      for(let i = 0; i < keys.length; i++) {
        const sliceList = [];
        if(mode === "every") {
          params[keys[i]].forEach((v, j, arr) => {
            if(j > 0) {
              sliceList.push([arr[j - 1], arr[j]]);
            }
          });
        }
        else {
          params[keys[i]].forEach((v, j, arr) => {
            if(j % 2 === 1) {
              sliceList.push([arr[j - 1], arr[j]]);
            }
          });
        }
        map[keys[i]] = sliceList;
      }
      console.log(map);
      return map;
    }

    function changeMode(event) {
      setMode(event.target.value);
    }

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", marginLeft: 10 }} onChange={changeMode}>
            <label style={{ display: "flex", margin: 0 }}>
              <input type="radio" name="mode" value="every" checked={mode === "every"} onChange={() => setMode("every")}/>
              <div style={{ marginLeft: 5, display: "flex", alignItems: "center", cursor: "default" }}>Every Section</div>
            </label>
            <label style={{ display: "flex", margin: 0 }}>
              <input type="radio" name="mode" value="everyOther" checked={mode === "everyOther"} onChange={() => setMode("everyOther")} style={{ marginLeft: 10 }}/>
              <div style={{ marginLeft: 5, display: "flex", alignItems: "center", cursor: "default" }}>Every Other Section</div>
            </label>
          </div>
          <button style={{ marginLeft: 10 }} onClick={() => analyze(dataList, { slices: getSlices(), referenceModuleNum })}>Analyze</button>
        </div>
        <div style={{ marginLeft: 10, marginTop: 10 }}>
          <b>Reference Module</b>
          {dataList.map((d, i) => {
            return (
              <label key={i} style={{ display: "flex" }}>
                <input type="radio" name="moduleNum" value={d.moduleNum} checked={referenceModuleNum === d.moduleNum} onChange={() => setReferenceModuleNum(d.moduleNum)}/>
                <div style={{ marginLeft: 5 }}>{`Module ${d.moduleNum}`}</div>
              </label>
            );
          })}
        </div>
        {dataList.map(({ moduleNum, emg }, i) => {
          let shapes = [];
          if(params[moduleNum]) {
            if(mode === "every") {
              params[moduleNum].forEach((v, j, arr) => {
                if(j > 0) {
                  shapes.push({
                    type: "rect",
                    x0: arr[j], x1: arr[j - 1], y0: -2000, y1: 2000,
                    fillcolor: "#ffff0088",
                    layer: "above"
                  });
                }
              });
              shapes = shapes.concat(params[moduleNum].map((v, j) => {
                return {
                  type: "line",
                  x0: v, x1: v, y0: -2000, y1: 2000,
                  line: {
                    width: 1,
                    dash: "solid"
                  },
                  layer: "above"
                }
              }));
            }
            else {
              params[moduleNum].forEach((v, j, arr) => {
                if(j % 2 === 1) {
                  shapes.push({
                    type: "rect",
                    x0: arr[j], x1: arr[j - 1], y0: -2000, y1: 2000,
                    fillcolor: "#ffff0088",
                    layer: "above"
                  });
                }
              });
              shapes = shapes.concat(params[moduleNum].map((v, j) => {
                return {
                  type: "line",
                  x0: v, x1: v, y0: -2000, y1: 2000,
                  line: {
                    width: 1,
                    dash: "solid"
                  },
                  layer: "above"
                }
              }));
            }
          }

          return (
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Plot
                data={[
                  {
                    x: _.range(emg.length).map((v) => v / 1000),
                    y: emg,
                    showlegend: false,
                    hoverinfo: "x",
                    line: {
                      color: GraphColors.line[moduleNum],
                      width: 1
                    }
                  }
                ]}
                layout={{
                  hovermode: "x",
                  xaxis: {
                    fixedrange: true,
                    range: [0, emg.length / 1000],
                    spikemode: "across",
                    spikecolor: "black",
                    spikedash: "solid",
                    spikesnap: "cursor",
                    spikethickness: 1
                  },
                  yaxis: {
                    fixedrange: true,
                    range: [_.min(emg), _.max(emg)]
                  },
                  margin: {
                    t: 20,
                    l: 40,
                    r: 30,
                    b: 30
                  },
                  shapes
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                onClick={(event) => {
                  if(params[moduleNum].find((v) => v === event.points[0].x) === undefined) {
                    params[moduleNum].push(event.points[0].x);
                    params[moduleNum].sort((a, b) => {
                      if(a < b) return -1;
                      if(a === b) return 0;
                      return 1;
                    });
                    setParams({...params});
                  }
                }}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
              <Button onClick={() => {params[moduleNum] = []; setParams({...params});}} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 30, marginRight: 30, padding: 5 }}><RefreshIcon/>Reset</Button>
            </div>
          );
        })}
      </div>
    );
  };

  const StandardTimingAnalysis = () => {
    const [start, setStart] = useState(null);
    const [referenceModuleNum, setReferenceModuleNum] = useState(dataList[0].moduleNum);

    useEffect(() => {
      setStart(null);
    }, [dataList]);

    return (
      <div>
        <div style={{ display: "flex" }}>
          <button onClick={() => analyze(dataList, { start, referenceModuleNum })}>Analyze</button>
          <button onClick={() => setStart(null)}>Reset</button>
        </div>
        <div style={{ marginLeft: 10, marginTop: 10 }}>
          <b>Reference Module</b>
          {dataList.map((d, i) => {
            return (
              <label key={i} style={{ display: "flex" }}>
                <input type="radio" name="moduleNum" value={d.moduleNum} checked={referenceModuleNum === d.moduleNum} onChange={() => setReferenceModuleNum(d.moduleNum)}/>
                <div style={{ marginLeft: 5 }}>{`Module ${d.moduleNum}`}</div>
              </label>
            );
          })}
        </div>
        {dataList.map(({ moduleNum, emg }, i) => {

          return (
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Plot
                data={[
                  {
                    x: _.range(emg.length).map((v) => v / 1000),
                    y: emg,
                    showlegend: false,
                    hoverinfo: "x",
                    line: {
                      color: GraphColors.line[moduleNum],
                      width: 1
                    }
                  }
                ]}
                layout={{
                  hovermode: "x",
                  xaxis: {
                    fixedrange: true,
                    range: [0, emg.length / 1000],
                    spikemode: "across",
                    spikecolor: "black",
                    spikedash: "solid",
                    spikesnap: "cursor",
                    spikethickness: 1
                  },
                  yaxis: {
                    fixedrange: true,
                    range: [_.min(emg), _.max(emg)]
                  },
                  margin: {
                    t: 20,
                    l: 40,
                    r: 30,
                    b: 30
                  },
                  shapes: start === null ? [] : [
                    {
                      type: "line",
                      x0: start, x1: start, y0: -2000, y1: 2000,
                      line: {
                        width: 1,
                        dash: "solid"
                      },
                      layer: "above"
                    }
                  ]
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                onClick={(event) => {
                  setStart(event.points[0].x);
                }}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const analysisList = [
    "Amplitude Probability",
    "Average Activation",
    "Frequency Fatigue",
    "Isometric Contraction Analysis",
    "Power Spectrum",
    "Standard EMG Analysis",
    "Standard Timing Analysis"
  ];

  let componentMap = {
    "Amplitude Probability": <AmplitudeProbability/>,
    "Average Activation": <AverageActivation/>,
    "Frequency Fatigue": <FrequencyFatigue/>,
    "Isometric Contraction Analysis": <IsometricContractionAnalysis/>,
    "Power Spectrum": <PowerSpectrum/>,
    "Standard EMG Analysis": <StandardEMGAnalysis/>,
    "Standard Timing Analysis": <StandardTimingAnalysis/>
  }

  useEffect(() => {
    if(currentType) {
      setCurrentComponent(componentMap[currentType]);
    }
  }, [currentType, dataList]);

  // useEffect(() => {
  //   setCurrentComponent(componentMap[currentType]);
  // }, [dataList]);

  return (
    <>
      <div style={{ display: "flex", minHeight: 500 }}>
        <div style={{ width: 250, borderRight: "1px solid", flexShrink: 0 }}>
          {analysisList.map((type, i) => {
            let style = {};
            if(type === currentType) {
              style = { backgroundColor: "#007bff", color: "white" }
            }
            return (
              <div className="functionButton" key={i} style={{ ...style, borderBottom: "1px solid", padding: 10 }} onClick={() => setCurrentType(type)}>
                {type}
              </div>
            );
          })}
        </div>
        <div style={{ flexGrow: 1 }}>
          {currentComponent}
        </div>
      </div>
    </>
  );
};

export default AnalysisView;