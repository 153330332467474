import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import _ from "lodash";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button } from "reactstrap";
import GraphColors from "../colors/graph";

const WINDOW_SIZE = 256;

const ExerciseView = (props) => {
  const { moduleNum, emgData, rmsData, rmsMax, fatigueData, fatigueMin, fatigueMax, emgSlice, graphBool, style } = props;
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(emgData.length - 1);
  const [sliderStart, setSliderStart] = useState(0);
  const [sliderEnd, setSliderEnd] = useState(emgData.length - 1);

  const Refresh = {
    width: 20,
    height: 20,
    path: "M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z",
    transform: "translate(-2, -2)"
  }

  useEffect(() => {
    setStart(0);
    setEnd(emgData.length - 1);
    setSliderStart(0);
    setSliderEnd(emgData.length - 1);
  }, [emgData]);

  function resetZoom() {
    setStart(sliderStart);
    setEnd(sliderEnd);
  }

  function setRange(s, e) {
    setStart(s * 1000);
    setEnd(e * 1000);
  }

  function fatigue() {
    let i, min, max;
    const sliceMid = emgSlice.map((slice) => (slice[0] + slice[1]) / 2);
    for(i = 0; i < emgSlice.length; i++) {
      if(sliceMid[i] >= start) {
        min = i;
        break;
      }
    }
    for(; i < emgSlice.length; i++) {
      if(sliceMid[i] > end) {
        break;
      }
    }
    max = i;
    return {
      index: _.range(min + 1, max + 1),
      x: sliceMid.slice(min, max),
      y: fatigueData.slice(min, max),
      width: emgSlice.slice(min, max).map((slice) => (slice[1] - slice[0]))
    }
  }

  const fatigueSlice = fatigue();

  return (
    <div style={style}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 20 }}><h3>{`Module ${moduleNum}`}</h3></div>
      <Button onClick={resetZoom} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 40, padding: 5 }}><RefreshIcon/>Reset Zoom</Button>
      <Plot
        data={[
          {
            x: _.range(emgData.length).map((v) => v / 1000),
            y: emgData,
            showlegend: false,
            hoverinfo: "x",
            line: {
              color: GraphColors.line[moduleNum],
              width: 1
            }
          }
        ]}
        layout={{
          hovermode: "x",
          xaxis: {
            range: [start / 1000, end / 1000],
            rangeslider: {
              range: [sliderStart / 1000, sliderEnd / 1000]
            },
            zeroline: false,
            spikemode: "across",
            spikecolor: "black",
            spikedash: "solid",
            spikesnap: "cursor",
            spikethickness: 1,
            title: "Time [s]"
          },
          yaxis: {
            fixedrange: true,
            // title: "[uV]"
          },
          margin: {
            t: 20,
            l: 40,
            r: 40,
            b: 40
          },
          // title: "EMG(Module " + moduleNum + ")" 
        }}
        useResizeHandler={true}
        onError={(err) => console.log(err)}
        config={{ responsive: true, doubleClick: false,
          // modeBarButtons: [[{
          //   name: "Reset Zoom",
          //   icon: Refresh,
          //   click: resetZoom
          // }]],
          displayModeBar: false
        }}
        style={{ width: "100%", height: 360 }}
        onDoubleClick={() => {
          resetZoom();
        }}
        // onHover={(event) => {
        //   console.log(event);
        // }}
        onRelayout={(event) => {
          if(event["xaxis.range[0]"]) {
            setRange(event["xaxis.range[0]"], event["xaxis.range[1]"]);
          }
          else if(event["xaxis.range"]) {
            setRange(event["xaxis.range"][0], event["xaxis.range"][1]);
          }
        }}
      />
      {graphBool.rms &&
        <Plot
          data={[
            {
              x: _.range(rmsData.length).map((v) => v / 1000 * WINDOW_SIZE),
              y: rmsData,
              showlegend: false,
              hoverinfo: "y",
              line: {
                color: GraphColors.line[moduleNum]
              }
            }
          ]}
          layout={{
            xaxis: {
              range: [start / 1000, end / 1000],
              zeroline: false,
              fixedrange: true,
              title: "Time [s]"
            },
            yaxis: {
              fixedrange: true,
              range: [0, rmsMax],
              // title: "[uV]"
            },
            margin: {
              t: 20,
              l: 40,
              r: 40,
              b: 40
            },
            // title: "RMS(Module " + moduleNum + ")"
          }}
          useResizeHandler={true}
          onError={(err) => console.log(err)}
          config={{ responsive: true, displayModeBar: false }}
          style={{ width: "100%", height: 250 }}
        />
      }
      {graphBool.fatigue &&
        <Plot
          data={[
            {
              x: fatigueSlice.x,
              y: fatigueSlice.y,
              showlegend: false,
              hoverinfo: "none",
              mode: "lines",
              line: {
                color: GraphColors.line[moduleNum]
              }
            },
            {
              ...fatigueSlice,
              showlegend: false,
              hoverinfo: "y",
              type: "bar",
              marker: {
                color: GraphColors.bar[moduleNum]
              }
            }
          ]}
          layout={{
            xaxis: {
              range: [start, end],
              zeroline: false,
              fixedrange: true,
              tickvals: fatigueSlice.x,
              ticktext: fatigueSlice.index,
              tickangle: 0,
              title: "Index No."
            },
            yaxis: {
              fixedrange: true,
              range: [fatigueMin - 3, fatigueMax + 3],
              // title: "[Hz]"
            },
            margin: {
              t: 20,
              l: 40,
              r: 40,
              b: 40
            },
            // title: "MDF(Module " + moduleNum + ")"
          }}
          useResizeHandler={true}
          onError={(err) => console.log(err)}
          config={{ responsive: true, displayModeBar: false }}
          style={{ width: "100%", height: 250 }}
        />
      }
    </div>
  );
};

export default ExerciseView;