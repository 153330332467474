import React, { useEffect, useState } from "react";
import { CardDeck } from "reactstrap";
import Mesh from "./Mesh";
import NormalDistribution from "./NormalDistribution";
import ScatterPlot from "./ScatterPlot";
import webdata from "../web-data.json";
import _ from "lodash";

const MqView = (props) => {
    // const muscleStrengthData = {
    //     scatteredDots: [[6.5, 4.8], [9.4, 8.5], [12.5, 14.7], [16.1, 16,2], [18, 11.4], [19.6, 14], [20.7, 16.8], [24.4, 29.9], [26.5, 26.4]],
    //     userDot: [22.8, 22.8]
    // }
    // const muscleEnduranceData = {
    //     scatteredDots: [[0.88, 0.93], [0.98, 1.02], [1.13, 1.2], [1.33, 1.29], [1.38, 1.29], [1.75, 1.59], [1.83, 1.59], [1.92, 2.2], [1.95, 1.78]],
    //     userDot: [2.1, 2.1]
    // }

    const [user, setUser] = useState({ strengthBW: 0, strength: 0, endurance: 0, area: "" });

    useEffect(() => {
        console.log(props.data[0])
        for(let i = 0; i < props.data.length; i++) {
            if(props.behavior_id === props.data[i].id) {
                let strengthBW, strength, endurance;
                if(!props.data[i].strength) {
                    // || props.data[i].strength < webdata.pdf.strength.x[0]
                    strength = webdata.pdf.strength.x[0];
                }
                else if(props.data[i].strength > webdata.pdf.strength.x.at(-1)) {
                    strength = webdata.pdf.strength.x.at(-1);
                }
                else {
                    strength = props.data[i].strength;
                }
                if(!props.data[i].endurance || props.data[i].endurance < webdata.pdf.endurance.x[0]) {
                    endurance = webdata.pdf.endurance.x[0];
                }
                else if(props.data[i].endurance > webdata.pdf.endurance.x.at(-1)) {
                    endurance = webdata.pdf.endurance.x.at(-1);
                }
                else {
                    endurance = props.data[i].endurance;
                }
                strengthBW = strength / props.data[i].weight * 100;
                strength = props.data[i].area === "forearm" ? strength / 70 : strength / 100;
                // console.log(strength, endurance)
                setUser({ strengthBW, strength, endurance, area: props.data[i].area });
                break;
            }
        }
    }, [props.behavior_id]);

    if(user.strength !== 0) {return (
        <>
            <CardDeck>
                <div style={{ margin: "auto" }}>
                    <div style={{ display: "flex", marginTop: 20 }}>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: 24 }}><b>Muscle Functionality</b></div>
                            <div style={{ display: "flex" }}>
                                <ScatterPlot data={{ x: webdata.scatter.strengthBW.x, y: webdata.scatter.strengthBW.y, userX: user.strengthBW,
                                    userRank: (webdata.scatter.strengthBW.y.filter(d => d > user.strengthBW).length) / (webdata.scatter.strengthBW.y.length) * 100, lines: webdata.scatter.strengthBW.y.slice().sort().filter((v, i) => i % 25 === 0 && i !== 0) }}
                                    title={ "MQ" } range={{ min: 15, max: 75, tick: 10 }} offset={ 8 }/>
                                <ScatterPlot data={{ x: webdata.scatter.strength.x, y: webdata.scatter.strength.y, userX: user.strength,
                                    userRank: (webdata.scatter.strength.y.filter(d => d > user.strength).length) / (webdata.scatter.strength.y.length) * 100, lines: webdata.scatter.strength.y.slice().sort().filter((v, i) => i % 25 === 0 && i !== 0).map((v) => user.area === "forearm" ? v / 70 : v / 100) }}
                                    title={ "근력" } range={user.area === "forearm" ? { min: 15 / 70, max: 45/ 70, tick: 0.1 } : { min: 0.15, max: 0.45, tick: 0.05 }} offset={ user.area === "forearm" ? 3.3 / 70 : 3.3 / 100 }/>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: 24 }}><b>Muscle Endurance</b></div>
                            <ScatterPlot data={{ x: webdata.scatter.endurance.x, y: webdata.scatter.endurance.y, userX: user.endurance,
                                userRank: (webdata.scatter.endurance.y.filter(d => d > user.endurance).length) / (webdata.scatter.endurance.y.length) * 100, lines: webdata.scatter.endurance.y.slice().sort().filter((v, i) => i % 44 === 0 && i !== 0) }}
                                title={ "근지구력" } range={{ min: 0.01, max: 0.27, tick: 0.05 }} offset={ 0.03 }/>
                        </div>
                    </div>
                </div>
            </CardDeck>
            <CardDeck>
                <div style={{ margin: "auto" }}>
                    <NormalDistribution data={{ userX: user.strengthBW, x: webdata.pdf.strengthBW.x, y: webdata.pdf.strengthBW.y, lines: webdata.scatter.strengthBW.y.slice().sort().filter((v, i) => i % 25 === 0 && i !== 0) }}
                        title={ "MQ(%BW)" } range={{ min: 15, max: 75, tick: 10 }}/>
                    <NormalDistribution data={{ userX: user.strength, x: webdata.pdf.strength.x.map((v) => user.area === "forearm" ? v / 70 : v / 100), y: webdata.pdf.strength.y, lines: webdata.scatter.strength.y.slice().sort().filter((v, i) => i % 25 === 0 && i !== 0).map((v) => user.area === "forearm" ? v / 70 : v / 100) }}
                        title={ "근력(kgf)" } range={user.area === "forearm" ? { min: 15 / 70, max: 45/ 70, tick: 0.1 } : { min: 0.15, max: 0.45, tick: 0.05 }}/>
                    <NormalDistribution data={{ userX: user.endurance, x: webdata.pdf.endurance.x, y: webdata.pdf.endurance.y, lines: webdata.scatter.endurance.y.slice().sort().filter((v, i) => i % 44 === 0 && i !== 0) }}
                        title={ "근지구력" } range={{ min: 0.01, max: 0.27, tick: 0.05 }}/>
                </div>
            </CardDeck>
            {/* <CardDeck>
                <div style={{ margin: "auto" }}>
                    <Mesh data={{ userStrengthBW: user.strengthBW, userStrength: user.strength, x: webdata.pdf.strengthBW.x, y: webdata.pdf.strength.x.map((v) => user.area === "forearm" ? v / 70 : v / 100), z: webdata.pdf.z,
                        lines: { strengthBW: webdata.scatter.strengthBW.y.slice().sort().filter((v, i) => i % 24 === 0 && i !== 0), strength: webdata.scatter.strength.y.slice().sort().filter((v, i) => i % 25 === 0 && i !== 0).map((v) => user.area === "forearm" ? v / 70 : v / 100) } }} range={user.area === "forearm" ? { min: 40 / 70, max: 180 / 70, tick: 0.5 } : { min: 0.4, max: 1.8, tick: 0.2 }}/>
                </div>
            </CardDeck> */}
        </>
    );
    }
    return <></>
};

export default MqView;