import { sum } from "d3";
import React, { useEffect, useState } from "react";
import MonthlyView from "./MonthlyView";

const MqOverview = (props) => {
    const [today, setToday] = useState(0);
    const [dateList, setDateList] = useState([]);
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        const date = [], data = [];
        console.log(props);
        if(props.data) {
            props.data.forEach((d) => {
                date.push(d.date);
                data.push(sum(d.values) / d.values.length);
            });
            setDateList(date);
            setDataList(data);
        }
    }, [props.data]);

    return (
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ width: "30%" }}>asdf</div>
            <MonthlyView date={dateList} data={dataList} style={{ width: "70%", height: "100%" }}/>
        </div>
    );
}

export default MqOverview;