import React from "react";
import { Jumbotron, Button, Container, Row, Col } from "reactstrap";

export default class NotFound extends React.Component {
  render = () => {
    const { location, history } = this.props;
    return (
      <Container>
        <Row>
          <Col sm="12" md={{ size: 10, offset: 1 }}>
            <Jumbotron>
              <h1 className="display-3">Page does not exist!</h1>
              <hr className="my-2" />
              <p className="lead">{location.pathname}</p>
              <p className="lead">
                <Button color="primary" onClick={history.goBack}>
                  Back
                </Button>
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    );
  };
}
