import React from "react";
import { NavLink } from "reactstrap";
import restapi from "../util/restapi";

export default class UserInfoSimple extends React.Component {
  state = {
    loading: false,
    user: null
  };

  fetch = () => {
    let user = null;

    this.setState({ loading: true }, async () => {
      try {
        const res = await restapi.getUserDetail(this.props.user_id);

        res && res.result && (user = res.detail);
      } catch (error) {
        console.error(error);
      }

      this.setState({ loading: false, user: user });
    });
  };

  componentDidMount = () => {
    this.fetch();
  };

  render = () => {
    return (
      this.state.user && (
        <NavLink href="/v1/my">{this.state.user.email}</NavLink>
      )
    );
  };
}
